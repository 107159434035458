import React, {useState, useEffect, useCallback, useRef} from "react"
import CS from "../../services/CommonService"
import getCaretCoordinates from 'textarea-caret'
import ReactDOM from 'react-dom'
import {Locales} from "../../constants/DataSourceList"
import {useSearch} from "../../hooks/useSearch"
import LocaleService from "../../services/LocaleService"

let caretPos = null
let locale = null
let textAreaRef = null
const InputTranslate = (props) =>{ 

	const {localeId, localeSid, inputFieldId, isRichText, insertRichText, editorSaveRange, adjustTranslatePosition, onInsertTextArea} = props
	const [loading, setLoading] = useState(false)
	const [translatedTexts, setTranslatedTexts] = useState([])
    const {onSearchCallback, searchKeyword} = useSearch(text=>getTranslatedText(text))

	//const textAreaRef = useRef(null)
	const searchInputRef = useRef(null)
  	const [position, setPosition] = useState({
  		padding:'6px',
		position:'absolute',
		background:'antiquewhite',
    	zIndex:100,
		display:'none',
		height: '300px',
    	overflow: 'auto'
	})

  	useEffect(()=>{
  		caretPos = null
  		if(localeId){
  		  locale = Locales.find(l => localeId == l.value)
  		}else if(localeSid){
  		  locale = Locales.find(l => localeSid == l.sid)
  		}

  		if(isRichText && inputFieldId){
  		  const parentDiv = document.getElementById(inputFieldId);		
  		  textAreaRef = {current:parentDiv.querySelector('div[contenteditable]')}
  		}else if(inputFieldId){
  		  textAreaRef = {current:document.getElementById(inputFieldId)}	
  		}
  		activateOnKeyPress()
  		return ()=>{
  		   deactivateOnKeyPress()
  		}
  	}, [])

  	function getCaretIndex(element) {
	  let position = 0
	  const isSupported = typeof window.getSelection !== "undefined";
	  if (isSupported) {
	    const selection = window.getSelection()
	    if (selection.rangeCount !== 0) {
	      const range = window.getSelection().getRangeAt(0)
	      const preCaretRange = range.cloneRange()
	      preCaretRange.selectNodeContents(element)
	      preCaretRange.setEnd(range.endContainer, range.endOffset)
	      position = preCaretRange.toString().length
	    }
	  }
	  return position
	}

  	function getContentEditableCaretCoordinates() {
		let top = 0, left = 0, height = 0
		const isSupported = typeof window.getSelection !== "undefined";
		if (isSupported) {
		    const selection = window.getSelection()
		    if (selection.rangeCount !== 0) {
			  const range = selection.getRangeAt(0).cloneRange()
			  range.collapse(true)
			  const rect = range.getClientRects()[0]
			  if (rect) {
			    left = rect.left;
			    top = rect.top;
			    height = rect.height
			  }
		    }
		}
		return { top, left, height }
	}

  	const onKeyPress = (event) => {
	    let charCode = event.keyCode
        if (!(event.ctrlKey || event.altKey || event.shiftKey) && (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)){
        	if(isRichText){
        		const clientWidth = event.currentTarget.clientWidth -150 
        		caretPos =  getContentEditableCaretCoordinates()
        		editorSaveRange()
        		setPosition({
        			...position, 
        			display:'block', 
        			top:'80px', 
        			left:caretPos.left<clientWidth?`${caretPos.left}px`:`${clientWidth}px`  
        		})
        	}else{
        		const clientWidth = event.currentTarget.clientWidth -150 
            	caretPos = getCaretCoordinates(event.target, event.target.selectionEnd)
            	if(adjustTranslatePosition){
            		setPosition({
  						...position, 
  						display:'block', 
  						top:`${(caretPos.top + caretPos.height)}px`, 
  						left:caretPos.left<clientWidth?`${caretPos.left}px`:`${clientWidth}px`
  					})
            	}else{
            		setPosition({...position, display:'block'})
            	}
        	}
            searchInputRef.current.focus()
        }else{
        	searchInputRef.current.value = ''
        	setPosition({...position, top:0, left:0, display:'none'})
        }
	}

  	const activateOnKeyPress = () =>{
  		textAreaRef.current.addEventListener("keydown", onKeyPress)
  	}

  	const deactivateOnKeyPress = () =>{
  		textAreaRef.current.removeEventListener("keydown", onKeyPress)
  	}

  	const getTranslatedText = (text) =>{
  		setLoading(true)
  		LocaleService.googleInputTools({text: text, itc: `${locale.iso_code}-t-i0-und`}).then((data)=>{
  			setTranslatedTexts(data[0][1])
  			setLoading(false)
  		})
  	}

  	const insertText = (text) =>{
  		text = text + ' '
  		if(isRichText){
  			insertRichText(text)
  			setPosition({...position, top:0, left:0, display:'none'})
    		searchInputRef.current.value = ''
  		}
  		else if (textAreaRef.current.setRangeText) {
    		//if setRangeText function is supported by current browser
    		textAreaRef.current.setRangeText(text)
    		setPosition({...position, top:0, left:0, display:'none'})
    		searchInputRef.current.value = ''
    		textAreaRef.current.focus()
    		textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = (textAreaRef.current.selectionStart + text.length)
			onInsertTextArea({'target':textAreaRef.current})
		}
  	}

  	if(localeId || localeSid){
		return (
			<div style={position}>
		    	<input ref={searchInputRef} type="text" 
		    		className="form-control input-sm" 
		    		onChange={e=>onSearchCallback(e)}
		    	/>
		    	{loading && <div className="font-16 p-5">Loading...</div>}
			    {translatedTexts?.map((t, i)=>
			       <div key={i} className="font-16 p-5" onClick={()=>insertText(t)}>{t}</div>
			    )}
			</div>
		)
	}

	return null		
}

export {InputTranslate}