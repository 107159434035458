import env from "../../../env"
import axios from "axios"
import BaseService from "../../../services/BaseService"

class WellnessService extends BaseService {

  async report(req) {
    const res = await axios.post(`${env.health_backend}health/symptoms/reports`, req);
    return res.data;
  }

}

export default new WellnessService('health_backend', 'health/symptoms')