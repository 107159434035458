import env from '../env';
import axios from 'axios';

class FormTemplateService {
    static getFormTemplateById(id, req){
        return new Promise((resolve, reject) => {
            const u = env.health_backend+'health'+(req?.is_public?'/public':'')+'/form_templates/'+(id)
  
            axios.get(u, {params: req, cache: true}).then(function(response){
                resolve( response.data );
            }).catch(function(response){
                reject(response)
            })  
      })
      }

      static getFormTemplates(req){
        return new Promise((resolve, reject) => {
          var u = env.health_backend+'health/form_templates'
    
          axios.get(u, {params:req}).then(function(response){
            resolve( response );
          })
        })
      }

      static getFormFieldsById(req){
        return new Promise((resolve, reject) => {
          var u = env.health_backend+'health/form_template/form_fields'
    
          axios.get(u, {params:req}).then(function(response){
           resolve( response );
          })
        })
      }

      static getFormWizardFields(req){
        return new Promise((resolve, reject) => {
          axios.post(env.health_backend+'form_wizard_fields/form_templates', req).then((res) => {
            // handle success
              resolve(res);
          }).catch((e) => {
            // handle error
              reject(e);
          })
        })
      }

      static getFormWizardSchedules(form_wizard_schedule_id){
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+`form_wizard_schedulers/${form_wizard_schedule_id}`).then((res)=>{
            resolve( res );
          }).catch(function(res){
            reject(res)
          })
        })
      }

      static getFormTemplateRules(req){
        return new Promise((resolve, reject) => {
          var u = env.health_backend+'health/get_form_template_rules'
    
          axios.get(u, {params:req}).then(function(response){
            resolve( response );
          })
        })
      }

      static syncFormTemplateRules(){
        return new Promise((resolve, reject) => {
          var u = env.health_backend+'health/sync/template_rules'
    
          axios.get(u).then(function(response){
            resolve( response );
          })
        })
      }

      static updateFormFieldLocale(req){
        return new Promise((resolve, reject) => {
          axios.put(env.health_backend+'health/form_template/update_locale', req).then((res) => {
            // handle success
            resolve(res);
          })
        })  
      }

      static getFormFieldFilters(req){
        return new Promise((resolve, reject) => { 
          const u = env.health_backend+'health/form_field_filters'
          axios.post(u, req).then((res)=>{
            if(res.status === 200){
              resolve( res.data );
            }
          })
        })
      }

      static async getFormFields(req){
        const u = env.health_backend+'health/form_template/get_form_fields'
        return await axios.post(u, req)
      }

}
export default FormTemplateService;

