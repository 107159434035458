import React, {useState, Fragment, useContext, useEffect} from 'react';
import FilterSlider from "../../Common/FilterSlider";
import RoleAndUserFilter from '../../NotifiableRoles/RoleAndUserFilter';
import {CheckBox} from "../../Common/FormInput";
import useModal from '../../../hooks/useModal';
import SortDirection from '../../../components/Common/SortDirection';
import useSort from '../../../hooks/useSorting';
import RoleService from '../../Roles/RoleService';
import FacilityService from '../../HealthJourney/MedicalCareFacility/FacilityService';
import {FontAwesomeBtn} from "../../Common/Button";
import FormFieldFilter from "../../Common/FormFieldFilter";
import FormHelper from '../../FormBuilder/FormHelpers';
import {NavIcon} from '../../Common/MenuItem';
import {useSearch} from '../../../hooks/useSearch';
import Cs from '../../../services/CommonService';
import {DateTimePicker} from '../../Common/FormInput';
import {useMedicalFacilityAutofillHook} from '../MedicalCareFacility/useMedicalFacilityApi';

const Filters = ({organizationId, filter, setFilter, currentUser, search, setSearch}) =>{
  
  const {onSearchCallback, searchKeyword, clearSearch} = useSearch((s) => setSearch(s), search);
  const {onSearchCallback:onSearchFilter} = useSearch((value, name) => setFilter({...filter, [name]:value}));
  const {
    getPhcList,
    facilities, 
    setFacilities
  } = useMedicalFacilityAutofillHook({
    organizationId: currentUser.current_organization_id,
    filter: filter,
    setLoading: ()=>{}
  });

  useEffect(() => {
    const org = currentUser.organizations.find(o => o.id === currentUser.current_organization_id);
    if(org.medical_facilities?.length > 0){
      setFacilities(org.medical_facilities);  
    }else{
      getPhcList();
    }
  }, [])

  /*const [talukas, setTalukas] = useState([])

  useEffect(() => {
    getTalukas()
  }, [])

  const getTalukas = () =>{
    FacilityService.getLocations({
      'organization_id':organizationId,
      'fields':['taluka'],
      'is_distinct':true,
    }).then(({data, status})=>{
      setTalukas(data.list)  
    }) 
  }*/

  const Filters = {
    'is_active':[
      {'label':'Deleted Cases', 'value':'false'},
    ],
    'search_by':[
      {'label':'User Name', 'sid':'user_name'},
      {'label':'Child ID', 'sid':'child_id'},
    ],
    'assignment':[
      {'label':'My Assignment', 'value':'true'},
    ],
    'weight_zscore_label':[
      {'label':'SUW'}, 
      {'label':'MUW'}, 
      {'label':'Mild'},
      {'label':'Normal'}
    ],
    'wfh_zscore_label':[
      {'label':'SAM'}, 
      {'label':'MAM'}, 
      {'label':'Normal'}
    ],
    'draft':[
      {'label':'Draft', 'value':'true'},
    ],
    'faltering':[
      {'label':'Faltering', 'value':'low'},
    ],
    'case_reports':[
      {'label':'Measurements uploaded', 'value':'measurements_uploaded'},
      {'label':'Measurements not uploaded', 'value':'measurements_not_uploaded'}
    ]
  }

  const toggleFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      setFilter({...filter, [name]:value});
    }else{
      delete filter[name];
      setFilter({...filter});
    }
  }

  const filterExists = (value, name) => {
    return filter[name] == value;
  }
  
  const toggleListFilter = (value, name) => {
    const setter = (list) => {
      if(list?.length === 0){
        delete filter[name];
        setFilter({...filter});
      }else{
        setFilter({...filter, [name]:list});
      }
    }
    FormHelper.toggleListString(value, filter[name], setter);
  }

  const filterListExists = (value, name) => FormHelper.existsListString(value, filter[name]);

  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">
        <CheckBox list={Filters.is_active} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="is_active"/> 
        <CheckBox list={Filters.assignment} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="my_assignment"/> 
        <CheckBox list={Filters.draft} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="is_draft"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Date
        </h5>
        <DateTimePicker name="last_activity_start_date" 
          className="form-control m-b-10" 
          placeholder="Start Date"
          defaultValue={filter.last_activity_start_date}
          maxDate={new Date()}
          onChange={(name, date, dateObj) => toggleFilter(date, name)}/>
        <DateTimePicker name="last_activity_end_date" 
          className="form-control m-b-10" 
          placeholder="End Date"
          defaultValue={filter.last_activity_end_date}
          minDate={Cs.stringToDate(filter.last_activity_start_date)}
          maxDate={new Date()}
          onChange={(name, date, dateObj) => toggleFilter(date, name)}/>  
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Search By
        </h5>
        
        <div className="flex aligncenter">
          <input className={"form-control m-b-5 f17"+(searchKeyword?.length>0?' br-green2':'')} 
            placeholder="Search"
            onChange={e=>onSearchCallback(e)} 
            value={searchKeyword}/>
          <i className="fas fa-times search-close" onClick={()=>clearSearch()}/>
        </div>

        <CheckBox list={Filters.search_by} 
          labelKey="label" valueKey="sid" 
          toggle={toggleFilter} exists={filterExists} 
          name="search_by"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          No Activity Days
        </h5>

        <div className="flex aligncenter">
          <input onChange={e=> onSearchFilter(e)} 
            name="no_activity_days"
            value={filter.no_activity_days || ""}
            className="form-control m-b-5 f15"
            placeholder="No Activity N days"/>
          <i className="fas fa-times search-close" 
            onClick={() => toggleFilter(null, 'no_activity_days')}/>
        </div>
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Z-score(Weight)
        </h5>
        
        <CheckBox list={Filters.weight_zscore_label} 
          labelKey="label" valueKey="label" 
          toggle={toggleListFilter} exists={filterListExists} 
          name="weight_zscore_label_in"/> 

        <CheckBox list={Filters.faltering} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="weight_zscore_trend"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Z-score(WFH)
        </h5>
        
        <CheckBox list={Filters.wfh_zscore_label} 
          labelKey="label" valueKey="label" 
          toggle={toggleListFilter} exists={filterListExists} 
          name="wfh_zscore_label_in"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center sub-title">
          Case Reports
        </h5>
        
        <CheckBox list={Filters.case_reports} 
          labelKey="label" valueKey="value" 
          toggle={toggleListFilter} exists={filterListExists} 
          name="case_report"/> 
      </div>

      {facilities?.length > 0 ?
        <div className="bg-white">
          <h5 className="font-16 text-center sub-title">
            Medical Facilities
          </h5>
          
          <div className="mxh-250 scroll-auto">
            <CheckBox list={facilities} 
              labelKey="name" valueKey="id" 
              toggle={toggleListFilter} exists={filterListExists} 
              name="medical_facility_ids"/>
          </div> 
        </div>
        :
        null
      }

      <FormFieldFilter 
        formId={currentUser.current_subscription?.journeys[0]?.child_form}
        filter={filter}
        setFilter={setFilter}/>  
      
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  const filterCount = Object.keys(props.filter).length + (props.requestParams?.search ? 1 : 0);

  return(
    <>
      <NavIcon
        id="filter" 
        dataTip={props.dataTip} 
        onSelect={()=>toggleFilterModal()}
        className="bg-highlight round-btn float-right"
        icon="fas fa-sliders-h">
          {filterCount > 0 ? <span className="badge-2 pos-abs bg-black-light-1 up">{filterCount}</span>:''}
      </NavIcon>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          {...props}/>
      }
    </>
  )
}

export {Filters, FilterBtn};