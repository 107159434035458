import React, {useContext, useState} from "react";
import {usePushNotifications, checkServiceWorker} from "./usePushNotifications";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import useModal from "../../hooks/useModal";
import GenericModal from "../Modals/GenericModal";

const Loading = ({ loading }) => (loading ? <div className="app-loader">Please wait, we are loading something...</div> : null);
const Error = ({ error }) =>
  error ? (typeof error === 'string'?error:(
    <section className="app-error lred">
      <h2>{error.name}</h2>
      <p>Error message : {error.message}</p>
      {/*<p>Error code : {error.code}</p>*/}
    </section>
  )) : null;

export function PushNotification({isPopupMode=true}) {
  const { state: { screen, user: currentUser }} = useContext(AuthContext);
  const {
    isServiceWorkerReady,
    isPushNotificationSupported,
    askUserPermission,
    susbribeToPushNotification,
    sendSubscriptionToApi,
    pushServerSubscriptionId,
    sendNotification,
    urlBase64ToUint8Array,
    userConsent,
    userSubscription,
    error,
    loading,
    labelLocale
  } = usePushNotifications(currentUser);

  const isConsentGranted = userConsent === "granted";

  const CheckPushSteps = () =>{
    if(loading)
      return <span className="spinner"/>

    if(!isPushNotificationSupported){
      return(
        <button className="btn btn-md bg-lred white">
          <i className="far fa-bell-slash"/>
          <span className="m-l-18" label="Notification Not Supported">{labelLocale(2)}</span>
        </button>
      )
    }

    if(!isConsentGranted){
      return(
        <button className="btn btn-md bg-lred white" onClick={askUserPermission}>
          <i className="far fa-bell-slash"/>
          <span className="m-l-18" label="Notification Blocked. Click here to enable.">{labelLocale(3)}</span>
        </button>
      )
    }

    if(!userSubscription){
      return(
        <button className="btn btn-md bg-lgreen white" onClick={susbribeToPushNotification}>
          <i className="far fa-bell-slash"/>
          <span className="m-l-18" label="Get Notification">{labelLocale(1)}</span>
        </button>
      )
    }else{
      return(
        <button className="btn btn-md bg-lgreen white">
          <i className="far fa-bell"/>
          <span className="m-l-18" label="Push Subscribed">{labelLocale(4)}</span>
        </button>
      )
    }
  }

  if(!isServiceWorkerReady)
    return null

  if(isPopupMode)
    return(
      <div id="ct" className="push-menu text-center">
        <Error error={error} />
        <CheckPushSteps/>
      </div>
    )

  return (
    <main className="font-16">
      <Loading loading={loading} />

      <p>Push notification are {!isPushNotificationSupported && "NOT"} supported by your device.</p>

      <p>
        User consent to recevie push notificaitons is <strong>{userConsent}</strong>.
      </p>

      <Error error={error} />

      <button className="dropbtn bg-submit" disabled={!isPushNotificationSupported || isConsentGranted} onClick={askUserPermission}>
        {isConsentGranted ? "Consent granted" : " Ask user permission"}
      </button>

      <button className="dropbtn bg-submit" disabled={!isPushNotificationSupported || !isConsentGranted || userSubscription} onClick={susbribeToPushNotification}>
        {userSubscription ? "Push subscription created" : "Create Notification subscription"}
      </button>

      <button className="dropbtn bg-submit" disabled={!userSubscription || pushServerSubscriptionId} onClick={sendSubscriptionToApi}>
        {pushServerSubscriptionId ? "Subscrption sent to the server" : "Send subscription to push server"}
      </button>

      {pushServerSubscriptionId && (
        <div>
          <p>The server accepted the push subscrption!</p>
          <button className="dropbtn bg-submit" onClick={sendNotification}>Send a notification</button>
        </div>
      )}

      <section>
        <h4>Your notification subscription details</h4>
        <pre><code>{JSON.stringify(userSubscription, null, " ")}</code></pre>
      </section>
    </main>
  );
}

export function PushNotificationPopup({currentUser}){
  const [isServiceWorkerReady, setServiceWorkerStatus] = useState(false);
  const { isOpen: isOpen, toggleModal: toggleOpen} = useModal(
    (currentUser?.setting?.push_notification_apn || localStorage.ct_push_alert_dismissed) ? false : true
  );
  const isServiceEnabled = checkServiceWorker(setServiceWorkerStatus);

  const onClose = () =>{
    localStorage.ct_push_alert_dismissed = true;
    toggleOpen();
  }

  if(isServiceWorkerReady && isOpen){
    return <GenericModal component={PushNotification} 
      isOpen={isOpen} toggleModal={onClose}
      isPopupMode={true} title="Push Setting"/>;
  }

  return null;
}