import React, { useEffect, useState, useContext } from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import ProfileService from "../../../services/ProfileService";
import FormWizard from '../../../components/FormBuilder/FormWizard';
import FormContainer from '../../../components/FormBuilder/FormContainer';

let formMode = null; 
let formId = null; 
let profile = {}; 
let data = {}; 
let formFn = {};  
let domain_info_id = {};

function ProfileForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let currentUser = authState.user;
  let queryParam = querystringify.parse(props.location.search);

  useEffect(() => { 
    profile = authState.user.profile; 
    data = profile.data || {}; 
    formFn = {'form_type':'family'}; 
    formMode = 'edit-form-submissions';
    setFormRendered(true);
  }, [authState.user, params.id]);

  const update = (profile) => {
    profile.updated_by = authState.user.id

    ProfileService.update(profile).then((res) => {
      if(res.status==204){
        currentUser.profile = profile;
        dispatch({
          type: "UPDATE",
          payload: {'user':currentUser},
        }) 
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {   
    props.history.push(`/health/table`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.HealthProfileForm} 
        form={profile} data={data} formFn={formFn} onUpdate={update} 
        post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    )  
  }else{
    return null;
  }
  
}

export default ProfileForm;