import React,{Fragment, lazy, Suspense} from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
const ForumList = lazy(() => import('../../components/Forums/List/ForumList'));
const ForumForm = lazy(() => import('./Form/ForumForm'));
const ForumReplyForm = lazy(() => import('../../components/Forums/Form/ForumReplyForm'));

const ForumRouter =  (props) => (
  <Suspense fallback={<div className="spinner"/>}>
    <PrivateRoute path="/forums" component={ForumList} exact />
    <PrivateRoute path="/forums/create/:id?" component={ForumForm} exact/>
    <PrivateRoute path="/forums/reply/:id?" component={ForumReplyForm} exact/>
  </Suspense>
)

export default ForumRouter;