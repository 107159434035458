import React, {Fragment, lazy, Suspense} from 'react';
import { lazily } from 'react-lazily';
import PrivateRoute from "../../../routes/PrivateRoute";
import ChildList from '../MotherInfantJourney/Child/ChildList'
import ChildForm from '../MotherInfantJourney/Child/ChildForm'
import FamilyList from '../MotherInfantJourney/Family/FamilyList'
import FamilyForm from '../MotherInfantJourney/Family/FamilyForm'
//import ActivityList from '../MotherInfantJourney/Activities/List/ActivityList'
import {ActivityCardPage} from '../MotherInfantJourney/Activities/List/ActivityCard'
import HealthJourneyForm from '../../../components/HealthJourney/Form/HealthJourneyForm'
import InstantMeasurement from './Child/InstantMeasurement'
import ActivityForm from '../MotherInfantJourney/Activities/Form/ActivityForm'

const FacilityList = lazy(() => import('../MedicalCareFacility/List'));
const FacilityForm = lazy(() => import('../MedicalCareFacility/Form'));
const SubFacilityTable = lazy(() => import('../MedicalCareFacility/SubFacility/SubFacilityTable'));
const SubFacilityForm = lazy(() => import('../MedicalCareFacility/SubFacility/Form'));
const GrowthChart = lazy(() => import('./Child/GrowthChart'));
const InfantPath = lazy(() => import('./Path/InfantPath'));
const InfantPathHome = lazy(() => import('./Path/InfantPathHome'));
const CounselingList = lazy(() => import('../Counseling/List/CounselingList'));
const CounselingForm = lazy(() => import('../Counseling/Form/Form'));
const CounselingReelList = lazy(() => import('../Counseling/List/CounselingReelList'));
const {ReportsPage} = lazily(() => import("./Charts/ReportsPage"));
const MemberDashboard = lazy(() => import("./Dashboards/MemberDashboard"));

const InfantJourneyRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/infant/child/:health_id" setHelpId={props.setHelpId} component={ChildList} exact />	
    <PrivateRoute path="/infant/child/form/:journey_profile_id/:id?" setHelpId={props.setHelpId} component={ChildForm} exact />	
    <PrivateRoute path="/infant/family/:id" setHelpId={props.setHelpId} component={HealthJourneyForm} exact />	
    <PrivateRoute path="/infant/family/form/:journey_profile_id/:id?" setHelpId={props.setHelpId} component={FamilyForm} exact />	
    <PrivateRoute path="/infant/activity/form/:journey_profile_id/:id?" searchString={props.searchString} component={ActivityForm} exact />
    <PrivateRoute path="/infant/measurements" component={InstantMeasurement} exact />
    <PrivateRoute path="/mcj/activity/:journey_profile_id/:id" component={ActivityCardPage} exact />
    
    <Suspense fallback={<div className="spinner"/>}>
      <PrivateRoute path="/mcj/dashboards/members" component={MemberDashboard} exact />
      <PrivateRoute path="/health/sub_facilities/:organization_id/:medical_facility_id" component={SubFacilityTable} exact />
      <PrivateRoute path="/health/sub_facility/:organization_id/:medical_facility_id/:id?" component={SubFacilityForm} exact />
      <PrivateRoute path="/infant/chart/state" component={ReportsPage} exact />
      <PrivateRoute path="/health/path/:health_id" searchString={props.searchString} component={InfantPath} exact />
      <PrivateRoute path="/health/path" component={InfantPathHome} exact />
      <PrivateRoute path="/health/chart/:health_id" component={GrowthChart} exact />
      <PrivateRoute path="/health/chart/:health_id/:index" component={GrowthChart} exact />
      <PrivateRoute path="/health/facility/:organization_id/:id?" component={FacilityForm} exact />
      <PrivateRoute path="/health/list/facilities/:organization_id/:subscription_id" searchString={props.searchString} component={FacilityList} exact />
      <PrivateRoute path="/infant/list/counseling"  searchString={props.searchString} component={CounselingList} exact/>
      <PrivateRoute path="/templates/counselings" searchString={props.searchString} component={CounselingList} exact/>
      <PrivateRoute path="/infant/list/counseling/reel/:health_id" searchString={props.searchString} component={CounselingReelList} exact/>
      <PrivateRoute path={["/infant/counseling/form/:id?", "/infant/counseling/form/:form_type/:form_id/:id?"]} component={CounselingForm} exact />
    </Suspense>
  </Fragment>
)
/*<PrivateRoute path={["/infant/activities/:health_id","/infant/weekly/:health_id"]} searchString={props.searchString} setHelpId={props.setHelpId} component={ActivityList} exact />*/
export default InfantJourneyRouter;