import React, { useEffect, useState, useContext, useRef } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import OrganizationMemberService from "./OrganizationMemberService"
import querystringify from "querystringify"
import FormContainer from '../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../components/Common/GeoLocation"
import Cs from "../../../services/CommonService"

// let formMode = null
// let member = {}
// let data = {}
// let formFn = {}

function AddFamilyMember(props) {
  let { current: scope } = useRef({ formFn: {}});
  const { state:{user:currentUser} } = useContext(AuthContext)

  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const get = () => {
    OrganizationMemberService.get(params.id).then((res) => {
      if (res.status == 200) {
        scope.member = {...res.data.organization_member, ...scope.member.geo_data}
        scope.data = scope.member.data || {}
        setFormRendered(true)
      }
    }).catch(function (res) {
      scope.member = null
    })
  }

  useEffect(() => {
    scope.member = {}
    scope.data = {}
    scope.formMode = "create-form-submissions"
    scope.formFn = {
      form_type: "members",
    }

    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (member) => {
    member.organization_id = currentUser.current_organization_id
    member.form_id = parseInt(queryParam.form_id)
    member.form_type = queryParam.label
    OrganizationMemberService.create(member).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect(res.data.member)
      }
    }).catch((res) => {

    })
  }

  const update = (member) => {
    OrganizationMemberService.update(member).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (member) => {
    if(props.isPopupMode){
      props.onFormSubmit(member)
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={queryParam.form_id}
          form={scope.member}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={scope.member}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default AddFamilyMember;