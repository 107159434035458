import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";

class OfflineService extends BaseService {

  async getJourneyComponent(req) {
    return await axios.get(`${env.health_backend}health/offline/components`, { params: req });
  }

  async getRequestQue(req) {
    return await axios.get(`${env.health_backend}offline/requests`, { params: req });
  }
}

export default new OfflineService('health_backend', 'health/offline_data');