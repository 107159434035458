import env from '../../env'
import BaseService from "../../services/BaseService"
import axios from 'axios'

class TrainingProgramsService extends BaseService {

	async getTraineeContent(req) {
		return await axios.get(`${env.health_backend}trainee/content`, { "params": req });
	}

	async getTrainingProgramByOrg(req) {
		return await axios.get(`${env.health_backend}health/org/training_programs`, { "params": req });
	}

	async schedules(req) {
		return axios.get(env.health_backend + 'health/training_program/schedules', { "params": req })
	}

}

export default new TrainingProgramsService('health_backend', 'health/training_programs');