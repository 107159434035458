import React, {useState, Fragment, useEffect} from 'react';
import { CueTip } from '../Common/CueTooltip';
import {usePageLocale} from '../../hooks/useLocale'
import {exitAlertMsg} from "../../constants/DataSourceList"

function FormSubmit({loading, form, labelLocale, getExitAlert, isPopupMode, formCancelAlertLocale, onCancel, onSubmit, activeKey, previousTab, nextTab, noOfTabs, formFn, readOnly, errors, forceUpdate, showDraftBtn}) {
    const [count, setCount] = useState(0);

  	formFn.refreshFormSubmit = () =>{
    	setCount(value => ++value)
      formFn.refreshErrorsList()
  	}

    const handleSubmit = (e) =>{
      if(Object.keys(errors.invalid).length === 0){
        onSubmit(false, e)
      }else{
        errors.isSubmitted = true;
        forceUpdate();
        setTimeout(function() {
          let ele = document.getElementById('error_list')
          ele.scrollIntoView();
        }, 0); 
        //formFn.refreshErrorsList();
      }
    }

    const showAlert = (e) =>{
      if(isPopupMode){
        if (window.confirm(getExitAlert()) == true) {
          onCancel(e)
        }
      }else{
        onCancel(e)
      }
    }

    if(loading)
      return null
    
    return (
    	<> 
        <div className="col-xs-12">
          {activeKey > 0 && 
            <button id="f_prev_btn" type="button" onClick={()=>previousTab()} 
              className="butn btn-blue pull-left">
              <i className="fa fa-chevron-left m-r-5" aria-hidden="true"/>
              {labelLocale(9)}
            </button>
          }
          {(activeKey < (noOfTabs-1)) && 
            <button id="f_next_btn" type="button" onClick={()=>nextTab()} 
              className="butn btn-blue pull-right">
              {labelLocale(8)}
              <i className="fa fa-chevron-right m-l-5" aria-hidden="true"/>
            </button>
          }
        </div>
        {(activeKey == (noOfTabs-1)) ?
          <div className="col-xs-12 text-center" style={{margin: "10px 0px 10px 0px"}}>
            {!readOnly ?
              <>
                <button id="f_submit_btn" type="button" onClick={e=>handleSubmit(e)} className="dropbtn bg-submit tooltip">
                  {form?.id?labelLocale(7):labelLocale(2)}
                  <CueTip tooltip={form?.id?labelLocale(7, 'tooltip'):labelLocale(2, 'tooltip')} positionCss="top font-14"/>
                </button>
                {!formFn.is_public && showDraftBtn &&
                  <button id="f_draft_btn" type="button" onClick={e=>onSubmit(true, e)} className="dropbtn bg-draft tooltip">
                    {labelLocale(3)}
                    <CueTip tooltip={labelLocale(3, 'tooltip')} positionCss="top font-14"/>
                  </button>
                }
              </>
              :null
            }
            <button id="f_cancel_btn" type="button" onClick={showAlert} className="dropbtn bg-cancel tooltip">
              {labelLocale(4)}
              <CueTip tooltip={labelLocale(4, 'tooltip')} positionCss="top font-14"/>
            </button>
          </div>
          : null
        }
      </>
  	)
}

export default FormSubmit;