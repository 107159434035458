import { useState, useEffect, useContext, useRef} from 'react';
import {getLocale} from '../components/FormBuilder/FieldLabel';
import { LocalizeContext } from "../contexts/Localize/LocalizeStateProvider";
import Cs from "../services/CommonService";
import DataSourceService from '../services/DataSourceService';

const usePageLocale = (currentUserLocale, cacheKey, isOptions=true) => {
  const {localeState, localeDispatch} = useContext(LocalizeContext);
  let [pageLocales, setPageLocales] = useState();
  const {current:scope} = useRef({});

  useEffect(() => {
    pageLocales = isOptions?localeState[cacheKey]?.options:localeState[cacheKey];
    setPageLocales(pageLocales);
  }, [localeState])

  //const [userLocale, setUserLocale] = useState(currentUserLocale);

  /*useEffect(() => {
    if(cacheKey){
      const local = localStorage.getItem("ct_locale_"+cacheKey)
      setPageLocales(local?JSON.parse(local):{})
    }
  }, [])

  useEffect(() => {
    if(cacheKey && Object.keys(pageLocales).length > 0){
      localStorage.setItem("ct_locale_"+cacheKey, JSON.stringify(pageLocales));
    }
  }, [pageLocales])*/

  const getPageLocales = (req, callback) =>{
    DataSourceService.getPageLocale(req).then(({status, data})=>{
      callback(data)
    })
  }

  const labelLocale = (key, localeKey="label") => {
    return getLocale(pageLocales?.[key], currentUserLocale, localeKey)
  }

  const getObjLocale = (object) => {
    return getLocale(object, currentUserLocale)
  }

  return {
    localeDispatch,
    labelLocale,
    getObjLocale,
    setPageLocales,
    getPageLocales
  }
};

export {usePageLocale};