import React, {Fragment, useState, useEffect, useMemo, useRef, useContext} from "react"
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../../services/CommonService"
import {PillDsField, PillDsList} from "../../../../Common/NotificationPill"
import ReactTooltip from "react-tooltip"
import SortDirection from '../../../../../components/Common/SortDirection'
import useSort from '../../../../../hooks/useSorting'
import ActivityService from "../../Activities/ActivityService"
import PreLoadSpinner from "../../../../../components/PreLoadSpinner"
import GenericModal from "../../../../../components/Modals/GenericModal"
import ActivityForm from '../Form/ActivityForm'
import useModal from "../../../../../hooks/useModal"
import NutritionCareNotification from '../../../../NutritionCares/NutritionCareNotification'
import DynamicTable from '../../../../Common/DynamicTable'
import {InfantGrowthZscoreLabel} from '../../../../../constants/DataSourceList'
import { CTTooltip, CueTip } from "../../../../Common/CueTooltip"
import PreScheduleAlert from "../../../../PreScheduleEvent/List/PreScheduleAlert"
import moment from '../../../../../../node_modules/moment/min/moment.min.js'
import {FontAwesomeBtn} from "../../../../Common/Button"
import {OptionsObjList} from "../../../../Common/FormInput"
import { LocalizeContext } from "../../../../../contexts/Localize/LocalizeStateProvider"
import {usePageLocale} from "./../../../../../hooks/useLocale"
import {DraftBadge} from "../../../../Common/NotificationPill"
import PaginationSM from '../../../../Common/PaginationSM'
import {EmptyRowAlert} from '../../../../Common/TableView'
import CheckAccess from '../../../../Roles/CheckAccess'
import ConfirmMenu from "../../../../Modals/ConfirmMenu"
import FormTemplateList from "../../../../../constants/FormTemplateList"
import {UserName} from '../../../../UserProfile/UserInfoPopup'
import GenericActivityCard from './GenericActivityCard'

const GenericActivityTable = (props) => {

  let {current:scope} = useRef({})
  let history = useHistory()

  const {journeyProfile, searchString, componentName, dataSource, activityReloadTab} = props
  const {child_form:formId, sid} = dataSource

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [formFields, setFormFields] = useState()
  const [tableFields, setTableFields] = useState()

  const { isOpen:isActivityFormOpen, toggleModal:toggleActivityModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal} = useModal()
  const { isOpen:isActivityCardOpen, toggleModal:toggleActivityCardModal } = useModal()

  const {labelLocale, getObjLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')
  const {labelLocale:tableHeadLocale} = usePageLocale(currentUser.current_locale, 'commonTableHeaders')

  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(currentUser, 'infant_process', sid), [currentUser.id]
  )

  useEffect(() => {
    scope.requestParams = {
      'page': currentpage,
      'per_page': 15,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'search': searchString,
      'form_fields_params': {
        'ids': [formId],
        'locale': currentUser.current_locale
      }
    }

    getActivityList()
  }, [searchString, currentpage])

  useEffect(()=>{
    if(activityReloadTab === sid)
      getActivityList()
  }, [activityReloadTab])

  let getActivityList = () => {
    setLoading(true)
    scope.requestParams.activity_form_id = formId
    scope.requestParams.activity_type = sid
    if(formFields) delete scope.requestParams.form_fields_params
    scope.requestParams.journey_profile_id = journeyProfile.id

    ActivityService.getAll(scope.requestParams).then(({status, data}) => {
      if (status === 200) {
        scope.requestParams.totalItems = data.meta.total
        setActivities(data.activities)
        setPagemeta(data.meta)
        if(data.form_fields?.[formId]){
          setFormFields(data.form_fields[formId])
          setTableFields(data.form_fields[formId].filter((f) => f.show_in_table_view==='true'))
        }
        
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(scope.requestParams, getActivityList)

  const routeActivityForm = (e, id, state) =>{
    e && e.stopPropagation();
    let qp = `?form_id=${formId}&label=${sid}&sid=${sid}`;
    if(dataSource.is_form_wizard === 'yes')qp += '&is_form_wizard=1'; //render as form wizard
    scope.formProps = {
      'match':{
        'params':{
          'journey_profile_id': journeyProfile.id,
          'id': id
        }
      },
      'location':{
        'state':{},
        'search':qp 
      }
    }
    scope.formProps.component = ActivityForm
    toggleActivityModal()
  }

  const onFormSubmit = (activity={}) =>{
    getActivityList();
    toggleActivityModal();
  }

  const deleteConfirm = (activity) =>{
    scope.selectedItem = activity;
    toggleDeleteModal();
  }

  const deleteActivity = async (e) =>{
    e.preventDefault();
    const {status} = await ActivityService.delete(scope.selectedItem.id);
    if(status === 204){
      toggleDeleteModal();
      setActivities(activities.filter(a => a.id !== scope.selectedItem.id));
      scope.selectedItem = null;
    }
  }

  const openActivityCard = (activity) =>{
    scope.selectedItem = activity;
    toggleActivityCardModal();
  }

  const date = (client_id, row) => {
    return <>
      {row.id
        ?row.data?.record_date
        :Cs.formatUpdateDate(row.scheduled_date, 'MMM D YYYY')
      }
    </>
  } 

  if(loading) return(<PreLoadSpinner/>)

    const activityProps = {
      requestParams: scope.requestParams,
      sort,
      tableHeadLocale, 
      sid, 
      dataSource, 
      permission, 
      labelLocale,
      currentUser, 
      routeActivityForm, 
      openActivityCard,
      deleteConfirm, 
      activities, 
      getObjLocale, 
      tableFields,
      journeyProfile,
    }
    
    return (
      <>
        <TableView {...props} {...activityProps}/>
        
        <PaginationSM
          className="pagination-bar"
          currentPage={currentpage}
          totalItems={pagemeta.total}
          itemsPerPage={pagemeta.per_page}
          onPageChange={setCurrentpage}
          siblingCount={2}/>

        {activities.length === 0 &&
          <div className="text-center f16 m-t-15">
            {/*There is no data to display. Click "Add" or "+" to add details.*/}
            <EmptyRowAlert/>
          </div>
        }
                
        {isActivityFormOpen && 
          <GenericModal toggleModal={toggleActivityModal} 
            isPopupMode="true" onFormSubmit={onFormSubmit} 
            closeConfirmPopup={true} 
            {...scope.formProps} />
        }

        <ConfirmMenu isOpen={isDeleteModalOpen} 
          toggleModal={toggleDeleteModal} 
          success={deleteActivity} />

        {isActivityCardOpen ? 
          <GenericModal 
            component={GenericActivityCard} 
            componentName={componentName}
            caseId={journeyProfile?.record_id}
            caseName={journeyProfile?.data?.record_title}
            currentUser={currentUser}
            routeActivityForm={routeActivityForm}
            isOpen={isActivityCardOpen}
            toggleModal={toggleActivityCardModal}
            deleteConfirm={deleteConfirm}
            activity={scope.selectedItem}
            formFields={formFields}
            sid={sid}/>
          :
          null
        }
      </>
    )

}

const userInfo = (clientId, data, row, rowIdx) => {
  if(!row.id)
    return null

  return(
    <div className="">
      <UserName id={row.created_by} role={row.member_role}>{row.author_name}</UserName>
      <div>{row?.member_role?.role}</div>
      <div>{Cs.formatUpdateDate(row.updated_at, 'MMM D YYYY hh:mm A')}</div>
    </div>
  )
}

const recordId = (clientId, data, row, rowIdx) => {
  if(!row.id)
    return null

  return(
    <div className="fw500">
      {row?.record_id}
    </div>
  )
}

const TableView = ({sid, journeyProfile, caseAssessmentCount, filter, requestParams, openActivityCard, activities, tableFields, sort, dataSource, currentUser, tableHeadLocale, routeActivityForm, onRangeFilterChange, timeFilter, deleteConfirm, openNotification, labelLocale}) =>{
  const specialFields = [
    {
      label:tableHeadLocale(0), 
      client_id:'record_id', 
      ele:recordId
    },
    {label:tableHeadLocale(2), client_id:'author_name', ele:userInfo}
  ]

  const OptionsEle = ({item}) => (
    <td className="text-center flex coldir">
      <span id="record_edit" onClick={e=>routeActivityForm(e, item.id, item.schedule_id)} className="fas fa-edit font-18 text-muted"/>
      <span id="record_delete" onClick={()=>deleteConfirm(item)} className="far fa-trash-alt m-t-15 font-18 text-muted"/>  
      {item.nutrition_care_alert_count > 0 &&
        <span id="record_alert" onClick={e=>openNotification(e, item)} className="fas fa-envelope font-18 text-muted">
          <span className="badge-2 up bg-lred">{item.nutrition_care_alert_count}</span> 
        </span>
      }    
    </td>
  )
    
  return(
    <div className="table-responsive table-fix-head">        
      <button id="add_measure_growth" data-tip="Record details in a new entry" 
        onClick={e=>routeActivityForm(e)} 
        type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
      </button>

      <DynamicTable requestParams={requestParams} onSort={sort} 
        formFields={tableFields} specialFields={specialFields} 
        formDataList={activities} tClass="table" isSortable={false} 
        OptionsEle={OptionsEle} currentLocale={currentUser.current_locale}
        onRowClick={openActivityCard} isSortable={false}
        tableType={sid === "wellbeing_checklist"? "dataInColumn" : "dataInRow"}/>
    </div>  
  )
}

export default GenericActivityTable;