import { useState, useEffect } from "react"
import importScript from "../../hooks/importScript"
import useStyle from "../../hooks/useStyle"
import { useRouteMatch, useLocation } from "react-router-dom"
//import Shepherd from 'Shepherd';

let tour = {}
let page_tours = {}

const getButtons = (idx) => {
  const buttons = [
    {
      action() {
        window.Shepherd.activeTour.complete();
      },
      text: "Don't Show Automatically",
    },
    {
      action() {
        window.Shepherd.activeTour.currentStep.destroy();
        return this.next();
      },
      text: "Next",
    }
  ]
  if(idx != 0){
    buttons.push({
      action() {
        window.Shepherd.activeTour.currentStep.destroy();
        return this.back();
      },
      text: "Back",
    })
  }
  return buttons
}

page_tours["header"] = [
  {
    title: "Navigate to home page",
    text: `Tap here to navigate back
    to the home page.`,
    attachTo: {
      element: "#header_home",
      on: "bottom",
    },
    buttons: getButtons(0),
    id: "header_home",
    showOn: function () {
      return document.querySelector(`#header_home`) ? true : false;
    },
  },
  {
    title: "View permissions",
    text: `Tap here to view
    tasks you can perform using your
    account.`,
    attachTo: {
      element: "#header_access",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "header_access",
    showOn: function () {
      return document.querySelector(`#header_access`) ? true : false;
    },
  },
  {
    title: "Edit/Update your account settings",
    text: `Tap this icon to update your user name, password,
      profile photograph, and other cueTree settings.`,
    attachTo: {
      element: "#edit_user_profile",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "edit_user_profile",
    showOn: function () {
      return document.querySelector(`#edit_user_profile`) ? true : false;
    },
  },
  {
    title: "Access the cueTree health menu",
    text: `Tap here to additional menu options.`,
    attachTo: {
      element: "#side_menu_btn",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "side_menu_btn",
    showOn: function () {
      return document.querySelector(`#side_menu_btn`) ? true : false;
    },
  },
  {
    title: "View account notifications",
    text: `Tap this icon to view cueTree health notifications for your account `,
    attachTo: {
      element: "#notification_btn",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "notification_btn",
    showOn: function () {
      return document.querySelector(`#notification_btn`) ? true : false;
    },
  },
  {
    title: "Search this page",
    text: `Tap this icon to search for content on this page`,
    attachTo: {
      element: "#search_btn",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "search_btn",
    showOn: function () {
      return document.querySelector(`#search_btn`) ? true : false;
    },
  },
  {
    title: "Switch organization",
    text: `Tap this icon to switch between organizations on the app`,
    attachTo: {
      element: "#org_btn",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "org_btn",
    showOn: function () {
      return document.querySelector(`#org_btn`) ? true : false;
    },
  },
]

page_tours["/health/table"] = [
  {
    title: "Calendar appointments",
    text: `Tap here to view upcoming calendar appointments.`,
    attachTo: {
      element: "#upcoming_schedules",
      on: "bottom",
    },
    buttons: getButtons(0),
    id: "upcoming_schedules",
    showOn: function () {
      return document.querySelector(`#upcoming_schedules`) ? true : false;
    },
  },
  {
    title: "Add New Patient",
    text: `Tap here to create a new health case account.`,
    attachTo: {
      element: "#add_new_journey",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_new_journey",
    showOn: function () {
      return document.querySelector(`#add_new_journey`) ? true : false;
    },
  },
  {
    title: "Patient list",
    text: `Patient information list `,
    attachTo: {
      element: "#patient_lists",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "patient_lists",
    showOn: function () {
      return document.querySelector(`#patient_lists`) ? true : false;
    },
  },
  {
    title: "Path",
    text: `Tap here to navigate to the
    records for this case.`,
    attachTo: {
      element: "#path_details",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "path_details",
    showOn: function () {
      return document.querySelector(`#path_details`) ? true : false;
    },
  },
  {
    title: "Remove Patient",
    text: `Tap here to remove
    this case.`,
    attachTo: {
      element: "#delete_journey",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "delete_journey",
    showOn: function () {
      return document.querySelector(`#delete_journey`) ? true : false;
    },
  },
  {
    title: "Assign",
    text: ` Tap here to assign
    this case to another user or group.`,
    attachTo: {
      element: "#assign_journey",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "assign_journey",
    showOn: function () {
      return document.querySelector(`#assign_journey`) ? true : false;
    },
  }
]

page_tours["/health/common/page/"] = [
  {
    title: "Chat message",
    text: `Tap here to type a message for your team on this case.`,
    attachTo: {
      element: "#portal_msg",
      on: "bottom",
    },
    buttons: getButtons(0),
    id: "portal_msg",
    showOn: function () {
      return document.querySelector(`#portal_msg`) ? true : false;
    },
  },
  {
    title: "Scribble Pad",
    text: `Tap here to write a message for your team on this case.`,
    attachTo: {
      element: "#portal_pen",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "portal_pen",
    showOn: function () {
      return document.querySelector(`#portal_msg`) ? true : false;
    },
  },
  {
    title: "Voice message",
    text: `Tap here to leave a voice message for your team on
    this case.`,
    attachTo: {
      element: "#portal_voice",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "portal_voice",
    showOn: function () {
      return document.querySelector(`#portal_voice`) ? true : false;
    },
  },
  {
    title: "Calendar appointments",
    text: `Tap here to view upcoming appointments for this case.`,
    attachTo: {
      element: "#portal_appointments",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "portal_appointments",
    showOn: function () {
      return document.querySelector(`#portal_appointments`) ? true : false;
    },
  },
  {
    title: "Create new record",
    text: ` Tap here to create a new record
    for this case.`,
    attachTo: {
      element: "#add_record",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_record",
    showOn: function () {
      return document.querySelector(`#add_record`) ? true : false;
    },
  },
  {
    title: "Case Menu",
    text: `Tap here to view additional details for this case.`,
    attachTo: {
      element: "#case_menu",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "case_menu",
    showOn: function () {
      return document.querySelector(`#case_menu`) ? true : false;
    },
  },
  {
    title: "Record edit",
    text: `Tap here to edit this record.`,
    attachTo: {
      element: "#record_edit",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "record_edit",
    showOn: function () {
      return document.querySelector(`#record_edit`) ? true : false;
    },
  },
  {
    title: "Record delete",
    text: `Tap here to delete this record.`,
    attachTo: {
      element: "#record_delete",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "record_delete",
    showOn: function () {
      return document.querySelector(`#record_delete`) ? true : false;
    },
  },
  {
    title: "Record upload",
    text: `Tap here to upload images to this record.`,
    attachTo: {
      element: "#record_upload",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "record_upload",
    showOn: function () {
      return document.querySelector(`#record_upload`) ? true : false;
    },
  },
  {
    title: "Record alerts",
    text: `Tap here to view counseling alerts and points for this case
    record.`,
    attachTo: {
      element: "#record_alert",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "record_alert",
    showOn: function () {
      return document.querySelector(`#record_alert`) ? true : false;
    },
  },
  {
    title: "Case Details of the People-Places-Things",
    text: `Tap each menu item to view and add case, family, and other
    general details for this case file.

    On a computer, hover mouse over a menu item to view the
    purpose for that menu item.`,
    attachTo: {
      element: "#case_first_tab",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "case_first_tab",
    showOn: function () {
      return document.querySelector(`#case_first_tab`) ? true : false;
    },
  },
  {
    title: "Case Details of the measurements",
    text: `Tap each menu item to view and add specific health/nutrition care
    and activity details for this case file.

    On a computer, hover mouse over a menu item to view the purpose
    for that menu item.`,
    attachTo: {
      element: "#case_second_tab",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "case_second_tab",
    showOn: function () {
      return document.querySelector(`#case_second_tab`) ? true : false;
    },
  },
]

const setPageTour = (key, data) =>{
  if(!page_tours[key]){
    page_tours[key] = []
  }
  page_tours[key].push({
    title: data.title,
    text: data.description,
    attachTo: {
      element: data.class?`.${data.class}`:`#${data.id}`,
      on: "bottom",
    },
    buttons: getButtons(0),
    id: data.id,
    showOn: function () {
      return document.querySelector(`#${data.id}`) ? true : false;
    },
  })
}

const resetPageTour = (key) =>{
  page_tours[key] = []
}

const setFormTour = () =>{
  setPageTour('form_tour', {
    id:'f_title',
    title:'Title',
    description:'The purpose for this form'
  })

  setPageTour('form_tour', {
    id:'f_description',
    title:'Description',
    description:'A short introduction about this form'
  })

  setPageTour('form_tour', {
    id:'f_required',
    title:'Required',
    description:'Any item marked with a red mark must be filled to submit this form'
  })

  setPageTour('form_tour', {
    id: 'tabs',
    class:'rc-tabs-nav',
    title:'Tabs',
    description:'Each form tab will include separate fields for you to fill'
  })

  setPageTour('form_tour', {
    id:'f_prev_btn',
    title:'Previous Tab',
    description:'Tap the arrow to move back inside a form with several tabs'
  })

  setPageTour('form_tour', {
    id:'f_next_btn',
    title:'Next tab',
    description:'Tap the arrow to move forward inside a form with several tabs'
  })

  setPageTour('form_tour', {
    id:'f_submit_btn',
    title:'Submit',
    description:'Tap this button to submit a filled form'
  })

  setPageTour('form_tour', {
    id:'f_cancel_btn',
    title:'Cancel',
    description:'Tap this button to cancel filling a form. You will lose any data you have entered'
  })
}
setFormTour()

const usePageTour = () => {
  useStyle("shepherd");

  let location = useLocation()

  tour.init = (restart_tour, page_id) => {
    const pageId = page_id || location.pathname.split("/")
    .map((v, i)=>{ 
      return (i<4&&v)?v:null; 
    }).join("/")

    let isTourComplete = localStorage.getItem(`${pageId}_page_tour`)

    if(restart_tour){

    }else if(isTourComplete){
      return
    }  

    importScript("/scripts/shepherd_2.min.js", () => {
      if (window.Shepherd) {
        Object.assign(
          tour,
          new window.Shepherd.Tour({
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
              useModalOverlay: true,
              allowSkipSteps: true,
              showCancelLink: true,
              scrollTo:true,
              scrollTo: {
                behavior: 'smooth',
                block: 'center'
              },
            },
            useModalOverlay: true,
            tourName: pageId,
          })
        )

        window.Shepherd.on("complete", () => {
          localStorage.setItem(`${pageId}_page_tour`, true);
        })

        tour.addSteps(page_tours[pageId]);
        tour.start()
      }
    }, window.Shepherd)
  }
  /*const match = useRouteMatch();
  const pageId = match.path.split("/:")[0];*/

  return tour;
};

export {usePageTour, setPageTour, resetPageTour};

/*scrollTo: { behavior: "smooth", block: "center" },*/
