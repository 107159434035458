import React, { Fragment, useState, useEffect } from 'react'
import {CueTip} from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, OptionLabel, Description, RequiredMsg, FieldReview, FieldPoint, SelectedCount} from '../FieldLabel'
import Cs from '../../../services/CommonService'
import GenericModal from "../../Modals/GenericModal"
import useModal from "../../../hooks/useModal"
import DataSourceMapper from "../DataSourceMapper"
import {ImageFlexList} from '../../Common/Image'
import env from '../../../env'

const InputMultiSelect = ({labelLocale, renderField, formId, screen, field, formData, formFn, readOnly, errors, forceUpdate, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale, position, fieldReview}) => {
  let listKey = field.client_id+'_array'
  formData[listKey] = formData[listKey] || []

  let [modelarray, setModelarray] = useState(formData[listKey])
  let [showOptions, setShowOptions] = useState(false)
  const [objList, setObjList] = useState({})
  const [loading, setLoading] = useState(true)
  const [optionList, setOptionList] = useState([])
  const [noneOption, setNoneOption] = useState()

  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()

  useEffect(()=>{
    if(renderField)
      setResultSet()
  }, [currentLocale, renderField])

  useEffect(()=>{
    if(renderField)
      filterOptions()
  }, [noneOption, modelarray])

  if(!renderField){
    return null
  }

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': labelLocale(5),
    'disabled': readOnly
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes)

  let validation = {
    'required': field.required
  }
  
  const op_kv = field.options_key_value
  
  const setResultSet = () =>{
    if(op_kv && op_kv.options){
      setObjList(
        op_kv.options.reduce((obj, item) => (obj[item.value] = OptionLabel({option:item, currentLocale:currentLocale}), obj), {})
      )  
      setOptionList(op_kv.options)
      setNoneOption(op_kv.options.find(o => o.sid === 'none'))
    }
    setLoading(false)
  }

  /* 
   * To check an value exist in checkbox options.
   */
  const toggle = (value) => {
    if(field.max && field.max == modelarray.length){
      return;
    }
    
    let idx = modelarray.indexOf(value);
    if(idx > -1) {
      setModelarray(modelarray.filter(i => i !== value));
      formData[listKey].splice(idx, 1);
    }else {
      setModelarray([...modelarray, value]);
      formData[listKey].push(value);
      formFn[field.client_id+'_is_modified'] = true;
    }
    formData[field.client_id] = formData[listKey].toString();
    setError();
    forceUpdate();
    FormHelper.setFormTitle(field, formData, modelarray, op_kv);
    formFn.ds[field.client_id] = formData[listKey].map(s => optionList.find(o => o.value == s));
  }

  const toggleKv = (item) => {
    if(noneOption){
      if(item?.sid === 'none' && modelarray.indexOf(item.value) < 0){
        /*None is being selected*/
        //Remove any past selections
        setModelarray([]); 
      }

      toggle(item.value);
    }else if(item){
      //No need to filter options
      toggle(item.value);
    }
  }

  const exists = (value) => {
    return modelarray.indexOf(value) > -1
  }

  const filterOptions = () =>{
    if(noneOption){
      if(modelarray.length === 0){
        setOptionList([...op_kv.options])
      }else if(modelarray.indexOf(noneOption.value) > -1){
        setOptionList(op_kv.options.filter((o)=> o.sid === 'none'))
      }else{
        setOptionList(op_kv.options.filter((o)=> o.sid !== 'none'))
      }
    }
  }

  const bindWindowClickEvent = () =>{
    if(screen.xs == true){
      toggleMenuModal()
    }else{
      let el = document.getElementById(field.client_id+'_result_set')
      setShowOptions(true)
      //el.classList.remove("hidden")
      //$scope.onOpen({req:$scope.model});
      window.addEventListener('click',eventFunction)  
    }
  }

  const eventFunction = (event) => {
    try{
      if(document.getElementById(field.client_id+'_container').contains(event.target)){
        console.log(field)
      }else{
        let el = document.getElementById(field.client_id+'_result_set')
        setShowOptions(false)
        window.removeEventListener("click",eventFunction)
        onCloseTrigger()
        //forceUpdate();
      }
    }catch(e){
    
    }
  }

  const onCloseTrigger = () =>{
    if(formFn[field.client_id+'_is_modified']){
      formFn['on_close_'+field.client_id] && formFn['on_close_'+field.client_id]();
    }
    formFn[field.client_id+'_is_modified'] = false;
  }

  //FormHelper.setDisabled(field, inputAttributes)
  //FormHelper.checkFieldValidity(field, formData, errors);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  if(loading)
    return null;

  return (
    <Fragment>
      <div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
          <FieldLabel field={field} 
            isFormWizard={isFormWizard}
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openDataSourceModal={openDataSourceModal} 
            formId={formId}
            openFieldLabelModal={openFieldLabelModal}/>
          <div className={`form-input-gray fstElement fstMultipleMode ${readOnly && 'input-readonly'}`} id={field.client_id+ '_container'}>
            {(op_kv && op_kv.options) 
              ? <OptionsObjList isMenuOpen={isMenuOpen} toggleMenuModal={toggleMenuModal} 
              list={optionList} toggle={toggleKv} currentLocale={currentLocale} formId={formId} 
              dataTypes={op_kv.data_types} exists={exists} object={objList} modelarray={modelarray} 
              field={field} bindWindowClickEvent={bindWindowClickEvent} setShowOptions={setShowOptions}
              inputAttributes={inputAttributes} showOptions={showOptions}/> 
              : <OptionsStringList list={field.options} toggle={toggle} 
              exists={exists} object={objList} modelarray={modelarray} field={field}
              bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes}
              showOptions={showOptions} setShowOptions={setShowOptions}/>
            }   
          </div>  
          <FieldPoint userRefId={formFn.userRefId} clientId={field.client_id} 
            point={field.point} currentLocale={currentLocale}  
            negPoint={field.incorrect_point}/>
          <Description description={field.description} currentLocale={currentLocale} locale={field.locale?.description}/>
          <SelectedCount label={field.label} show={formFn.showSelectedCount} count={modelarray.length}/>
          <FieldReview review={fieldReview}/>
          <Error/>
        </div>
      </div>

      {isMenuOpen && (
        <GenericModal
          component={MultiSelectPopup}
          labelLocale={labelLocale}
          toggle={op_kv?toggleKv:toggle} 
          exists={exists}
          optionObjList={op_kv?optionList:null}
          optionStringList={field.options}
          dataTypes={op_kv.data_types}
          title={field.label}
          isOpen={isMenuOpen}
          toggleModal={()=>{toggleMenuModal();onCloseTrigger()}}
          currentLocale={currentLocale}/>
      )}
    </Fragment>
  )
}

const OptionsObjList = ({showOptions, setShowOptions, dataTypes, formId, isMenuOpen, toggleMenuModal, list, toggle, exists, object, modelarray, field, bindWindowClickEvent, inputAttributes, currentLocale}) => {

  let [optionList, setOptionList] = useState([])
  let itemHeight = (dataTypes && (dataTypes.image_url))?{height: '100px'}:{}

  useEffect(()=>
    setOptionList(list)
  , [list])

  const search = (term) =>{
    if(term.length>0){
      setOptionList(FormHelper.searchObjList(list, term))
    }else{
      setOptionList([...list])
    }
  }

  const selectableItems = optionList.map((o, k) =>{  
    return(
      <Fragment key={k}>
        <span style={itemHeight} className={`fstResultItem ${exists(o.value)?'fstSelected':''}`}
         onClick={() => toggle(o)} title={o.description}>
          <div>
            <OptionLabel option={o} currentLocale={currentLocale}/>
            {(itemHeight && o.image_url) && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100" style={{height:"60px"}}/>}
            {o.images ? <ImageFlexList styleName="radio-img" list={o.images}/> : null}
          </div>
          {o.url && 
            <a href={o.url} target="_blank" onClick={e => e.stopPropagation()}>
              <i className="fas fa-link fts-14"/>
            </a> 
          }
        </span>
      </Fragment>
    )
  })

  const selectedItems = modelarray.map((v, k) =>  
    <div key={k} className="fstChoiceItem">
      {object[v]}
      <span className="fstChoiceRemove" type="button" onClick={() => toggle({'value':v})}>×</span>
    </div>
  )

  return(
    <Fragment>
      <div className="flex coldir m-b-20">
        <div className="fstControls" onClick={e=>bindWindowClickEvent(e)}>
          {selectedItems}
          <input {...inputAttributes} onChange={e=>search(e.target.value)} className="fstQueryInput" />
        </div>
        {showOptions &&
          <div className="fstResults" id={field.client_id+ "_result_set"}>
            <span className="far fa-caret-square-up fstResultsClose" type="button" onClick={()=>setShowOptions(false)}/>
            {selectableItems}
          </div>
        }
      </div>
    </Fragment>
  )
}

const OptionsStringList = ({showOptions, setShowOptions, list, toggle, exists, object, modelarray, field, bindWindowClickEvent, inputAttributes}) => {
  
  let [optionList, setOptionList] = useState(list);

  const search = (term) =>{
    if(term.length>0){
      const result = FormHelper.searchList(list, term);
      setOptionList([...result])
    }else{
      setOptionList([...list])
    }
  }

  const selectableItems = optionList && optionList.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''}`}
     onClick={() => toggle(o)} title={o}>
      {o}
    </span>
  )

  const selectedItems = modelarray.map((i, k) =>  
    <div key={k} className="fstChoiceItem">
      {i}
      <span className="fstChoiceRemove" type="button" onClick={() => toggle(i)}>×</span>
    </div>
  )

  return(
    <Fragment>
      <div className="fstControls" onClick={e => bindWindowClickEvent(e)}>
        {selectedItems}
        <input {...inputAttributes} onChange={e=>search(e.target.value)} className="fstQueryInput" />
      </div>
      {showOptions &&
        <div className="fstResults" id={field.client_id+ "_result_set"}>
          <span className="far fa-caret-square-up fstResultsClose" type="button" onClick={()=>setShowOptions(false)}/>
          {selectableItems}
        </div>
      }
    </Fragment>
  )
}

const MultiSelectPopup = ({labelLocale, dataTypes, toggle, exists, optionObjList, optionStringList, currentLocale}) =>{
  let [optionList, setOptionList] = useState([]);
  let itemHeight = dataTypes && dataTypes.image_url?{height: '100px'}:{};

  useEffect(()=>
    setOptionList(optionObjList?optionObjList:optionStringList)
  , [optionObjList])

  const search = (term) =>{
    if(term.length>0){
      const result = (optionObjList ? 
        FormHelper.searchObjList(optionObjList, term)
        :
        FormHelper.searchList(optionStringList, term))
      setOptionList([...result])
    }else{
      setOptionList(optionObjList ? optionObjList: optionStringList)
    }
  }

  const optionStringItems = optionList ? optionList.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''} `}
     onClick={() => toggle(o)} title={o}>
      {o}
    </span>
  ):null;

  const optionObjectItems = optionList ? optionList.map((o, k) =>  
    <span key={k} style={itemHeight} className={`fstResultItem ${exists(o.value)?'fstSelected':''}`}
      onClick={() => toggle(o)} title={o.description}>
        <div className="font-16">
          {/*o[currentLocale]?.label || o.label*/}
          <OptionLabel option={o} currentLocale={currentLocale}/>
          {itemHeight && o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100 pull-right" style={{height:"60px"}}/>}
        </div>
        { o.url && 
          <a href={o.url} target="_blank" onClick={e => e.stopPropagation()}>
            <i className="fas fa-link fts-14"/>
          </a> 
        }
    </span>
  ):null;

  return(
    <>
      <input className="form-control m-t-5" autoFocus
        placeholder={labelLocale(5)}
        onChange={e => search(e.target.value)}/>   
      {optionObjectItems?optionObjectItems:optionStringItems}
    </>
  )

}

export default InputMultiSelect;