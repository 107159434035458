import React, { Fragment, useState, useEffect, useContext, useMemo} from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import useStyle from "../../hooks/useStyle"
import CommonService from "../../services/CommonService"
import useEscape from "../../hooks/useEscape"
import FormAlertService from "../../services/FormAlertService"
import useModal from "../../hooks/useModal"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import env from "../../env"
import FormTemplateList from '../../constants/FormTemplateList'
import {FormAlertTypes, DataSourceSelectionType} from "../../constants/DataSourceList"
import {OptionsObjList} from '../Common/FormInput'
import DataSourceMapperModal from "./DataSourceMapperModal"
import ReactTooltip from 'react-tooltip'
import GenericModal from '../Modals/GenericModal'
import CheckAccess from '../Roles/CheckAccess'

let type = null
let dataSourceItem = null
let selectedAlert = null
const FormAlertMessage = ({field, formId}) => {
  useStyle("filter_dialog")

  let op_kv = field.options_key_value || {}
  op_kv.options = op_kv.options || []

  const { state:{user:currentUser}, dispatch } = useContext(AuthContext)
  
  const [isLoading, setLoading] = useState(true)
  const [alertType, setAlertType] = useState()
  const [fieldAlert, setFieldAlert] = useState({})
  const [dataSourceAlertList, setDataSourceAlertList] = useState([])
  
  const {isOpen: isModalOpen, toggleModal: toggleModal} = useModal()
  const {isOpen: isDsModalOpen, toggleModal: toggleDsModal} = useModal()

  useEscape(toggleModal)
  
  useEffect(() => {
    if(isModalOpen)getFormAlert();
  }, [isModalOpen])

  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(currentUser, 'form', 'field_alert'), [currentUser.id]
  )

  if(!permission?.write){
    return null
  }

  const handleChange = (e) =>{
    setFieldAlert({...fieldAlert, [e.target.name]:e.target.value})
  }

  const changeAlertType = (type) =>{
    if(type != 'min_selection'){
      deleteAlert(fieldAlert.id)
    }else if(type != 'data_source_list'){
      let req = {
        is_bulk_delete:true,
        form_template_id: field.formTemplateId,
        field_index: field.index,
        alert_type: 'data_source_list'
      }
      deleteAlert(null, req)
    }
    setAlertType(type)
  }

  let getFormAlert = () => {
    let req = {
      form_template_id: field.formTemplateId,
      field_index: field.index
    }
    setLoading(true)
    FormAlertService.get(req).then((res)=>{
      if(res.status == 200){
        setAlertType(res.data.form_alert?'min_selection':'data_source_list')
        setFieldAlert(res.data.form_alert || {})
        setDataSourceAlertList(res.data.data_source_alerts)
        setLoading(false)
      }
    })
  }

  let add = (field_alert, callback) => {
    field_alert.form_template_id = field.formTemplateId
    field_alert.field_index = field.index
    field_alert.field_client_id = field.client_id
    field_alert.alert_type = alertType
    field_alert.data_source_id = field.data_source_id
    field_alert.data_source_list_length = op_kv.options.length

    if(field_alert.id){
      FormAlertService.update(field_alert).then((res)=>{
        if(res.status == 204){
          if(alertType == 'data_source_list'){
            const idx = dataSourceAlertList.findIndex((d)=> d.id == field_alert.id)
            dataSourceAlertList[idx] = field_alert
            setDataSourceAlertList([...dataSourceAlertList])
            callback && callback()
          }
        }
      })  
    }else{
      FormAlertService.create(field_alert).then((res)=>{
        if(res.status == 201){
          if(alertType == 'min_selection'){
            fieldAlert.id = res.data.form_alert.id
            setFieldAlert({...fieldAlert})
          }else if(alertType == 'data_source_list'){
            field_alert.id = res.data.form_alert.id
            setDataSourceAlertList([field_alert, ...dataSourceAlertList])
            callback && callback()
          }
        }
      })
    }
  }

  let deleteAlert = (id, req) => {
    if(id || req?.is_bulk_delete){
      FormAlertService.delete(id, req).then((res)=>{
        if(res.status == 204){
          if(alertType != 'min_selection'){
            setFieldAlert({})
          }
        }
      })  
    }
  }

  const removeDataSourceAlert = (ds) =>{
    const idx = ds.id?'id':'index'
    if(idx == 'id')deleteAlert(ds.id);
    setDataSourceAlertList(dataSourceAlertList.filter(i => i[idx] !== ds[idx]))
  }

  const open = (t, ds) =>{
    type = t
    selectedAlert = ds
    dataSourceItem = op_kv.options.find(d => d.pk_id == ds.data_source_list_id)
    dataSourceItem && toggleDsModal()
  }

  if(!isModalOpen){
    return(
      <span className='fas fa-exclamation-triangle m-l-15 f18 cursor' onClick={e=>toggleModal(e)}/>
    )
  } 

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom w-98p brd-10">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">{field.label}</h4>
            <button className="btn-close" title="Close Modal" onClick={e=>toggleModal(e)}>
              ×
            </button>
          </div>
          
          {isLoading ? 
            <div className="spinner"/>
            :
            <div id="ct">
              <div className="p-5 scroll-x">
                <div className="m-t-15">
                  <AlertType t={FormAlertTypes[0]} {...{changeAlertType, alertType}}/>
                  {alertType == 'min_selection' && 
                    <>
                      <MinSelectionAlert {...{fieldAlert, op_kv, handleChange, DataSourceSelectionType}}/>
                      <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>add(fieldAlert)}>
                        <b>{fieldAlert.id?'Update':'Create'}</b>
                      </div>
                    </>
                  }
                </div>
                <div className="m-t-15">
                  <AlertType t={FormAlertTypes[1]} {...{changeAlertType, alertType}}/>
                  {alertType == 'data_source_list' && 
                    <DataSourceAlert {...{add, dataSourceAlertList, op_kv, DataSourceSelectionType, open, removeDataSourceAlert}}/>
                  }
                </div>
              </div>
            </div>
          }  
        </div>
      </div>

      {isDsModalOpen && 
        <DataSourceMapperModal formId={field.formTemplateId} 
        isOpen={isDsModalOpen} toggleModal={toggleDsModal} 
        dataSourceItem={dataSourceItem} field={field} type={type} 
        alert={selectedAlert}/>
      }
    </Fragment>, document.body
  )
}

const MinSelectionAlert = ({fieldAlert, op_kv, handleChange, DataSourceSelectionType}) =>{
  return(
    <span>If at least
      <input type="number" defaultValue={fieldAlert.min_selection} min="0" name="min_selection" 
      className="m-l-15 m-r-15 input-1 input-sm" onChange={e=>handleChange(e)}/>
      of the availabe {op_kv?.options?.length} data values in this field are left unselected
      
      {/*<select className="m-l-15 input-sm" name="select_condition" 
          defaultValue={fieldAlert.select_condition} onChange={e=>handleChange(e)}>
        <OptionsObjList list={DataSourceSelectionType} id_key="sid" label_key="label"/>
      </select>*/}

      <textarea defaultValue={fieldAlert.message} 
        className="form-control m-t-5" 
        name="message" onChange={e=>handleChange(e)}/>
    </span>
  )
}

const DataSourceAlert = ({add, dataSourceAlertList, op_kv, DataSourceSelectionType, open, removeDataSourceAlert}) =>{
  
  const {isOpen: isDataSourceAlertOpen, toggleModal: toggleDataSourceAlert} = useModal()

  const addDataSourceAlert = (alert) =>{
    selectedAlert = alert
    toggleDataSourceAlert()
  }

  return(
    <>
      <button className="input-sm bg-highlight white pull-right form-control font-16 bold-500 w-100 m-b-10" onClick={e=>addDataSourceAlert()}>+ Rule</button>
      <span className="req-field lred">Required item</span>
      <table className="table table-stripe table-head w-full m-t-5">
        <tbody>
          <tr>
            <td>ID</td>
            <td className="req-field">Label</td>
            <td className="req-field">Selection Status</td>
            <td>CuePoint</td>
            <td className="req-field">CuePrompt</td>
            <td>Submit</td>
          </tr>
          <DataSourceAlertList {...{add, dataSourceAlertList, op_kv, DataSourceSelectionType, open, addDataSourceAlert, removeDataSourceAlert}}/>
        </tbody>
      </table>
      {isDataSourceAlertOpen && 
        <GenericModal component={AddDataSourceAlertForm} title="Alert"
          isOpen={isDataSourceAlertOpen}
          toggleModal={toggleDataSourceAlert}
          dataSourceAlert={selectedAlert} 
          optionKeyValue={op_kv}
          add={add}
        />
      }
    </>
  )
}

const DataSourceAlertList = ({dataSourceAlertList, op_kv, DataSourceSelectionType, open, addDataSourceAlert, removeDataSourceAlert}) => dataSourceAlertList.map((d, i)=>
  <DataSourceAlertTable key={i} index={i} {...{dataSourceAlertList, op_kv, DataSourceSelectionType, open, removeDataSourceAlert, addDataSourceAlert}} d={d}/>   
)

const AddDataSourceAlertForm = ({toggleModal, add, dataSourceAlert, addDataSourceAlert, optionKeyValue}) =>{
  let [dataAlert, setDataAlert] = useState(dataSourceAlert || {})

  const handleDsChange = (e) =>{
    dataAlert[e.target.name] = e.target.value
    setDataAlert({...dataAlert})
  }

  const onDataSourceSelect = (e, data_source) =>{
    dataAlert[e.target.name] = e.target.value
    dataAlert.data_source_list_value = optionKeyValue.options[e.target.selectedIndex-1]?.value
    setDataAlert({...dataAlert})
  }

  return(
    <form className="ml-container">
      <div className="ml-section scroll-y">
        
        <label className="m-b-10"><b>Options</b></label>
        <select className="form-control m-b-20" defaultValue={dataAlert.data_source_list_id} 
          name="data_source_list_id" onChange={e=>onDataSourceSelect(e, dataAlert)}>
          <option value="">None</option>
          <OptionsObjList list={optionKeyValue.options} idKey="pk_id" labelKey="label"/>
        </select>

        {dataAlert.data_source_list_id && 
          <Fragment>
            <label className="m-b-10"><b>Selection Status</b></label>        
            <select className="form-control m-b-20" defaultValue={dataAlert.select_condition} 
              name="select_condition" onChange={e=>handleDsChange(e)}>
              <option value="">None</option>
              <OptionsObjList list={DataSourceSelectionType} idKey="sid" labelKey="label"/>
            </select>
          </Fragment>
        }

        <label className="m-b-10"><b>CuePrompt</b></label>
        <textarea className="form-control m-b-20" 
          defaultValue={dataAlert.message} 
          placeholder="Message"
          name="message" onChange={e=>handleDsChange(e)}/>

        <div className="ml-button ml-block ml-section ml-padding bg-highlight white" 
          onClick={e=>add(dataAlert, toggleModal)}>
          <b>{dataAlert.id?'Update':'Create'}</b>
        </div>
        
      </div>
    </form>
  )
}

const DataSourceAlertTable = ({addDataSourceAlert, dataSourceAlertList, op_kv, DataSourceSelectionType, open, removeDataSourceAlert, d}) => {
  const ds = op_kv.options.find((o)=> o.value == d.data_source_list_value)
  return(
    <tr>
      <td>{d.record_id}</td>
      <td>
        {ds?.label}
      </td>
      <td className="text-cap">
        {d.select_condition}
      </td>
      <td>
        {d.id && d.data_source_list_id &&
          <span className='far fa-file-alt m-l-15 m-t-10 cursor' onClick={e=>open('infant_journey.counseling', d)}/>
        }
      </td>
      <td>
        {d.message}
      </td>
      <td>
        <span className="bg-highlight round-btn m-t-4">
          <i className="fas fa-edit" onClick={e=>addDataSourceAlert(d)}></i>
        </span>
        <span className="bg-highlight round-btn m-t-4" onClick={e=>removeDataSourceAlert(d)}>
          <i className="fas fa-minus"></i>
        </span>
      </td>
    </tr>
  )  
}

const AlertType = ({t, changeAlertType, alertType}) => (
  <span className="checkbox-container" onClick={e=>changeAlertType(t.sid)}>
    <span className="font-14 bold-500">{t.label}</span>
    <input type="checkbox" checked={t.sid == alertType} readOnly/>
    <span className="checkbox-checkmark"></span>
  </span>
)

export default FormAlertMessage;