import React ,{Fragment, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../../../../services/CommonService";
import StepActivities from "../../StepActivities/StepActivities";
import ChildGrowth from "../../../../../constants/ChildGrowthDataPoints"
import {PillDsField, PillDsList} from "../../../../Common/NotificationPill"
import DataPoint from '../../../../../constants/ChildGrowthDataPoints'
import { DivRichTextView } from "../../../../Common/RichTextView";

const Activity = (props) => {
  const { state: authState, dispatch } = React.useContext(AuthContext)
  let [weightFlag, setWeightFlag] = useState(false);
  let [heightFlag, setHeightFlag] = useState(false);

  let WeightWarning = ChildGrowth.WeightWarning
  let HeightWarning = ChildGrowth.HeightWarning

  let WeightWarningLabel = ChildGrowth.WeightWarningLabel
  let HeightWarningLabel = ChildGrowth.HeightWarningLabel
  
  let activity = props.activity;
  activity.updated_at = CommonService.formatUpdateDate(activity.updated_at)
  let data = activity.data;
  let ds = activity.data_source;
 // data.baby_age = CommonService.getAge(data.dob_baby_formatted_date)
  
  const cardStyle = {
    backgroundColor:
      data.background_color || data.header_background || "#607D8B",
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!data) return null;

  let userRoleLink = {
    pathname: `/journey/user_role/${props.activity.journey_category_id}/${props.activity.education_journey_profile_id}/edu_team_journey.activity/${props.activity.id}`,
    state: { journey: props.journeyCategory, activity: props.activity },
  }

  const MailTag = () => (
    <a href={`mailto: ${activity.id}-team_activity@reply.cuetree.com?subject=Education Activity-${data.name}-${activity.record_id}`}
      target="_blank">
      <i className="fas fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle} onClick={(e) => props.routeActivity(activity)}>
      <div className="font-16">
        {data.name}
        <i className="fas fa-expand pull-right white expand"></i>
      </div>
      <div className="font-16">
        {activity.updated_at}
      </div>
    </div>
  )

  const Footer = () => {
   return(
    <div className="card-footer">
      <div className="pull-left">
        <span className="m-l-25" onClick={(e) => props.addNotes(e, activity)}>
          <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
          <span className="badge-2 up bg-lgrey">{activity.comments_count || 0}</span>
        </span>
        <span className="m-l-25" onClick={(e) => props.addChecklist(e, activity)}>
          <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
          <span className="badge-2 up bg-lgrey">{activity.check_list_count || 0}</span>
        </span>
        <span className="m-l-25">
          <Link to={`/health/incoming/emails/treatment/${activity.id}`}>
            <i className="fas fa-envelope font-18 text-muted" data-tip="Mail"></i>
            <span className="badge-2 up bg-lgrey">
              {activity.mail_count || 0}
            </span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  )}


  const CovidCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}
          onClick={(e) => props.routeActivity(activity)}>
          <div className="font-16">
            {data.name}
          </div>
          <div className="font-16">
            {activity.updated_at}
            <Link className="pull-right white expand t-0"
              onClick={(e) => e.stopPropagation()}
              to={userRoleLink}>
              <i className={`fas ${activity.user_roles == null ? "fa-lock" : "fa-unlock"} `}></i>
            </Link>
          </div>
        </div>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span className="pull-right">
                    Appointment Date: { CommonService.formatUpdateDate(data.appt_formatted_date)}
                  </span>
                  <strong>Notes</strong>
                  <div>{data.notes}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }
  
  const InfantCard = () => {
    /*let WeightColor = "bg-lightblue"
    if(data.weight_note == "below_median"){
      WeightColor = "bg-bemedian"
    }else{
      WeightColor = "bg-abmedian"
    }

    let HeightColor = "bg-lightblue"
    if(data.height_note == "below_median"){
      HeightColor = "bg-bemedian"
    }else{
      HeightColor = "bg-abmedian"
    }*/
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle} onClick={(e) => props.routeActivity(activity)}>
          <div className="font-16">
            {data.visit_date || data.date_start_cf}
          </div>
          <div className="font-16">
            {/*CommonService.formatUpdateDate(activity.updated_at)*/} 
            <div className="font-14">by {activity.author_name}</div>
          </div>
        </div>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <strong>Age</strong>
                  <div>{data.baby_age} Months</div>
                </td>
                <td>
                  <strong>Weight</strong>
                  <div>{data.weight} Kgs</div>
                </td>
                <td>
                  <strong>Height</strong>
                  <div>{data.length} Cms</div>
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <strong>Notes</strong>
                  <div>{data.notes}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            {WeightWarning[data.weight_note] && 
              <Fragment>
                <div className="p-5 m-t-5" style={{display:"flex"}}>
                  <div className="p-5 m-2"> 
                    <i className={WeightWarningLabel[data.weight_note]}></i>
                  </div>
                  <DivRichTextView className="p-5 m-2" html={WeightWarning[data.weight_note]}/>
              </div>   
              </Fragment>
            }
            {HeightWarning[data.height_note] && 
              <Fragment>
                <div className="p-5 m-2 m-t-5" style={{display:"flex"}}>
                  <div className="p-5 m-2">
                    <i className={WeightWarningLabel[data.height_note]}></i>
                  </div>
                  <DivRichTextView className="p-5 m-2" html={HeightWarning[data.height_note]}></DivRichTextView>
                </div>
              </Fragment>
            }
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const CFRecordCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle} onClick={(e) => props.routeActivity(activity)}>
          <div className="font-16">
            {data.visit_date || data.date_start_cf}
          </div>
          <div className="font-16">
            {/*CommonService.formatUpdateDate(activity.updated_at)*/} 
            <div className="font-14">by {activity.author_name}</div>
          </div>
        </div>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <strong>Age</strong>
                  <div>{data.baby_age} Months</div>
                </td>
                <td>
                  <strong>Weight</strong>
                  <div>{data.weight} Kgs</div>
                </td>
                <td>
                  <strong>Height</strong>
                  <div>{data.length} Cms</div>
                </td>
              </tr>
              <tr> 
                <td colSpan="3">
                  <strong>Body Alignment</strong>
                  <div>{data.body_alignment}</div>
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <strong>Notes</strong>
                  <div>{data.notes}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Care Person</strong>
                  <PillDsField field={ds.care_person} dataKey="label" styleName="pill text-left"/>
                </td>
                <td>
                  <strong>Food Prep</strong>
                  <PillDsField field={ds.food_prep} dataKey="label" styleName="pill text-right"/>
                </td>
                <td>
                  <strong>Water</strong>
                  <PillDsField field={ds.water} dataKey="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Exclusive BF</strong>
                  <PillDsField field={ds.exclusive_bf} dataKey="label" styleName="pill text-right"/>
                </td>
                <td>
                  <strong>BF Currently</strong>
                  <PillDsField field={ds.bf_currently} dataKey="label" styleName="pill text-left"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }

  const BFCheckCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}
          onClick={(e) => props.routeActivity(activity)}>
          <div className="font-16">
            {CommonService.formatUpdateDate(activity.updated_at)} 
            <div className="font-14">by {activity.author_name}</div>
          </div>
        </div>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <strong>Notes</strong>
                  <div>{data.notes}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Favorable Behavior</strong>
                  <PillDsList list={ds.favorable_behavior} dataKey="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Mother Holding</strong>
                  <PillDsList list={ds.mother_holding} dataKey="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Latching</strong>
                  <PillDsList list={ds.latching} dataKey="label" styleName="pill text-left"/>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>General</strong>
                  <PillDsList list={ds.general} dataKey="label" styleName="pill text-left"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }

  if(activity.activity_form_id == 482){
    return <InfantCard/>
  }else if(activity.activity_form_id == 483){
    return <CFRecordCard/>
  }else if(activity.activity_form_id == 486){
    return <BFCheckCard/>
  }

  return <CovidCard />;
}

export default Activity;

/*<span className="m-l-25"><MailTag/></span>*/