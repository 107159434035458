import React, { Fragment, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import OrganizationsModal from './OrganizationsModal'
import {NotificationPopup} from "../Common/NotificationPopup";

const SwitchActiveOrganization = (props) => {
   const { state: {user: currentUser }} = useContext(AuthContext)
   const org = currentUser?.current_organization

	return(
	  <>
		{(org && !org.is_active) 
			?  <div className="m-t-20">
		         <NotificationPopup title={org?.data?.name +" is Inactive."} 
		            message="The project is currently inactive. Contact the project leader for further information." 
		            iconClass="fas fa fa-info-circle lred"/>
		      </div>
			:  (currentUser.current_member?.is_active === false) 
			?  <div className="m-t-20">
		         <NotificationPopup title="Inactive." 
		            message="Your account inactive." 
		            iconClass="fas fa fa-info-circle lred"/>
		      </div>
			: null 
		}
		<OrganizationsModal/>
	  </>
	)  	
}

export default SwitchActiveOrganization;