import React, {useState, useContext, useEffect, useRef, useMemo} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import OrgUrlAccessService from "./OrgUrlAccessService";
import useModal from "../../../hooks/useModal";

const useOrgUrlAccessHook = (organizationId) => {
	const [loading, setLoading] = useState(true);
	const [urlAccesses, setUrlAccesses] = useState([]);

	const checkUrlAccess = async (pageSid) =>{
		const res = await OrgUrlAccessService.checkAccess({
			"organization_id":organizationId,
			"page_sid":pageSid
		})
		return res;
	}

	const getUrlAccess = async () =>{
		const {status, data} = await OrgUrlAccessService.getAll({
			"per_page":100,
			"page":1,
			"organization_id": organizationId
		})
		return data?.org_url_accesses;
	}

	const addOrRemoveUrlAccess = async (access) =>{
		if(access.id){
			const {status} = await OrgUrlAccessService.delete(access.id);
			return status;
		}else{
			const {status, data} = await OrgUrlAccessService.create({
				"organization_id": organizationId,
				"page_sid": access.pageSid,
			})
			return data;
		}
	}

	const toggleUrlAccess = async (pageSid) =>{
	    const existingAccess = findUrlAccess(pageSid);
	    if(existingAccess){
	      const status = await addOrRemoveUrlAccess(existingAccess);
	      if(status === 204){
	      	setUrlAccesses(urlAccesses.filter(a => a.id !== existingAccess.id));
	      };
	    }else{
	      const data = await addOrRemoveUrlAccess({"pageSid":pageSid});
	      if(data.org_url_access?.id) setUrlAccesses([...urlAccesses, data.org_url_access]);
	    }
	}

  	const findUrlAccess = (sid) => urlAccesses.find(a => a.page_sid === sid);

  	const getUrlAccessList = async () =>{
	    const data = await getUrlAccess();
	    setUrlAccesses(data);
  	}

	return {
		checkUrlAccess,
	    loading, setLoading,
	    getUrlAccess,
	    addOrRemoveUrlAccess,
	    urlAccesses, setUrlAccesses,
	    toggleUrlAccess, findUrlAccess, getUrlAccessList
	}
}

export {useOrgUrlAccessHook};