import env from '../env';
import axios from 'axios';

class LocationService {
    static get_countries(req){
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+'countries', {params: req}).then((res) => {
            // handle success
            resolve(res);
          })
        })   
      }

      static get_state(req){
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+'states', {params: req}).then((res) => {
            // handle success
            resolve(res);
          })
        })   
      }

      static get_district(req){
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+'cities', {params: req}).then((res) => {
            // handle success
            resolve(res);
          })
        })   
      }

      static get_taluka(req){
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+'sub_districts', {params: req}).then((res) => {
            // handle success
            resolve(res);
          })
        })   
      }

      static get_village(req){
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+'villages', {params: req}).then((res) => {
            // handle success
            resolve(res);
          })
        })   
      }
      static getPublicHealthCenters(req){ 
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+'public_health_centers', {params: req}).then((res) => {
            // handle success
            resolve(res);
          })
        })  
      }

      static reverseGeocode(req){
        return new Promise((resolve, reject) => {
          axios.get('https://nominatim.openstreetmap.org/reverse', {params:req, withCredentials: false}).then((res) => {
            // handle success
            resolve(res);
          })
        })   
      }
    

}
export default LocationService