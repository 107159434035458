import env from '../env';
import BaseService from "./BaseService";
import axios from 'axios';

class FileUploadService extends BaseService {

  async clone(req) {
    return axios.post(env.health_backend + 'health/file_upload/clone', req);
    //.then((res) => res.data);
  }

  uploadFileToUrl(file, privacy_type, data, upload_type, url){
	  return new Promise((resolve, reject) => {
      let fd = new FormData()
    	fd.append('file', file)
    	fd.append('privacy_type', privacy_type)
      if(data){
        for (const property in data) {
          if(data[property])
            fd.append(`${property}`, data[property])
        }
      }

    	let reader = new FileReader()
    	let image = new Image()
    	reader.readAsDataURL(file)  

    	reader.onload = function(_file) {
      	image.src = _file.target.result;   
      	let postImageUploadurl = env.health_backend+'health/'+(url || 'file_uploads')

      	if(this.width > 1000){
          postImageUploadurl = postImageUploadurl+'?max_width=1000'
      	}
      		
      	axios.post(postImageUploadurl, fd, {headers: {'Content-Type': undefined}}).then(function success(res){
        	try{
            if(upload_type != 'comment')res.data.file_upload.url = res.data.file_upload.url.split('?')[0]; 
        		resolve(res)
        	}catch(e){
        		reject(res)
        	}
      	}).catch(function error(data){
        	reject(data)
      	})
    	}
    })
  }

  deleteFileFromUrl(url){
    return new Promise((resolve, reject) => {	
    	var list = url.split("/");
    	var id = list[list.length-2];
    	axios.delete(`${env.health_backend}health/file_uploads/${id}`).then(function(res){
    		resolve(res)
    	}).catch(function error(data){
       	reject(data);
    	})
    })	
  }

  getFileUploads(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/file_uploads', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getJourneyHyperLinks(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/journey_links', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    }) 
  }

}

export default new FileUploadService('health_backend', 'health/file_uploads');