import React, {Fragment} from "react"
import Cs from '../../services/CommonService'
import {FormFieldTable, RenderData} from './FormFieldsView'
import { ChatGrayIcon, CheckListGrayIcon } from "./ImageIcons"

const DynamicCard = (props) => {
  console.log('props',props)
  let {item, formFields=[], addComment, addChecklist, onDataItemSelect} = props
  let {data, data_source:dataSource} = item

  const cardStyle = {backgroundColor: "#607D8B"}

  const Header = () => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
      {data.title} 
      </h4>
      <div className="f14 m-t-10 fw500">{Cs.formatUpdateDate(item.updated_at)}</div>
    </div>
  )

  const Footer = () => {
    return(
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">
            {item.record_id}
          </li>
          <li className="m-r-25">
            <ChatGrayIcon onSelect={(e) => addComment(e, item)}/>
          </li>
          <li className="m-r-25">
            <CheckListGrayIcon onSelect={(e) => addChecklist(e, item)}/>
          </li>
        </ul>
      </div>
    )
  }

  const Card = () => {
    return (
      <div id="ct">
        <div className="bg-white brd-10 card-shadow m-b-15">
          {!props.isOpen && <Header />}
          <div className="card-content p20">
            <div className="b-tab-table active scroll-auto ht-400"> 
              <FormFieldTable formFields={formFields} data={data} 
                dataSource={dataSource} onDataItemSelect={onDataItemSelect}/>
            </div>
          </div>
          <Footer/>
        </div>
      </div> 
    )
  }

  return <Card />
}

export default DynamicCard;