import React, {useState} from 'react';
import {CueTip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description, RequiredMsg, FieldReview, FieldPoint} from '../FieldLabel'

const InputTextArea = ({renderField, field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale, position, fieldReview}) => {
  const [showError, setShowError] = useState(false);

  if(!renderField){
    //delete formData[field.client_id]
    return null
  }

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':readOnly,
    'className':'form-input form-input-gray form-input-full'
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
    setError(event)
  };

  const setError = (event) => {
    if(field.required){
      const { target:{name} } = event;
      errors[name].invalid = formData[name]?.length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError)
      formFn.refreshFormSubmit();
    }
  };

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setMinMaxValidation(field, inputAttributes);
  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  return (
  	<div {...parentAttributes} title="tooltip">
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
    	  <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
      	<textarea {...inputAttributes} onChange={e => handleChange(e)} onBlur={e => {setError(e)}}
         defaultValue={formData[field.client_id]} 
         className="form-input-gray textAreaht"
         data-tip data-for={`tooltip_${field.client_id}`}/>
         <FieldPoint userRefId={formFn.userRefId} clientId={field.client_id} 
          point={field.point} currentLocale={currentLocale}  
          negPoint={field.incorrect_point}/>
         <Description description={field.description} 
          currentLocale={currentLocale}
          locale={field.locale?.description} />
        <FieldReview review={fieldReview}/>
        <Error/>
      </div>
    </div> 
  )
}

export default InputTextArea;