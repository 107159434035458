import React, {Fragment, Suspense, lazy} from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../routes/PrivateRoute';
import PublicRoute from '../../routes/PublicRoute';
//import OrganizationsTable from './OrganizationsTable'
//import PersonalUseTable from './PersonalUseTable'
import OrganizationForm from './OrganizationForm';
//import MemberList from './Members/MemberList'
import SwitchActiveOrganization from './SwitchActiveOrganization';
//import OrganizationPartnershipTable from './OrganizationPartnershipTable'
import AppRequestForm from './AppRequestForm';
const MCJMemberActivityList = lazy(() => import('./MemberActivities/MCJMemberActivityList'));
const OrganizationsTable = lazy(() => import('./OrganizationsTable'));
const PersonalUseTable = lazy(() => import('./PersonalUseTable'));
const MemberList = lazy(() => import('./Members/MemberList'));
const OrganizationPartnershipTable = lazy(() => import('./OrganizationPartnershipTable'));
const MemberRankingForm = lazy(() => import('./RankingModel/MemberRankingForm'));
const DataBackUpList = lazy(() => import('./BackUps/DataBackUpList'));
const OrgInitiativeList = lazy(() => import('./OrgInitiativeActions/OrgInitiativeList'));
const OrgInitiativeForm = lazy(() => import('./OrgInitiativeActions/OrgInitiativeForm'));
const OrgOnboardingList = lazy(() => import('./OrgOnboardingActions/OrgOnboardingList'));
const OrgOnboardingForm = lazy(() => import('./OrgOnboardingActions/OrgOnboardingForm'));
const MemberCaseActivityTable = lazy(() => import('./MemberActivities/MemberCaseActivityTable'));
const MultiOrganizationReport = lazy(() => import('./Reports/MultiOrganizationReport'));
const MCJMemberReportTabs = lazy(() => import('./MemberActivities/MCJMemberReportTabs'));

const Router = (props) => (
  <Suspense fallback={<div className="spinner"/>}>
    <PrivateRoute path="/list/organizations" component={OrganizationsTable} searchString={props.searchString} exact />
    <PrivateRoute path="/list/personal/users" component={PersonalUseTable} searchString={props.searchString} exact />
    <PrivateRoute path="/organization/create/:id?" component={OrganizationForm} exact />
    <PrivateRoute 
      path={['/:organization/members/:organization_id/:admin?', '/:organization/facility/members/:organization_id/:medical_facility_id/:sub_facility_id?']} 
      component={MemberList} searchString={props.searchString} exact />
    <PrivateRoute path="/list/partners" component={OrganizationPartnershipTable} searchString={props.searchString}/>
  	<Route path="/switch/organization" component={SwitchActiveOrganization} />
    <PublicRoute path="/app/request/:organization_id?" component={AppRequestForm} exact />
    <Route path="/members/ranking/model/:organization_id" component={MemberRankingForm} exact />
    <PrivateRoute path="/backup/:organization_id/:organization?" component={DataBackUpList} searchString={props.searchString} exact />
    <PrivateRoute path="/org/initiatives" component={OrgInitiativeList} searchString={props.searchString} exact />
    <PrivateRoute path="/new/org/initiatives/:organization_id/:id?" component={OrgInitiativeForm} exact />
    <PrivateRoute path="/members/case/activity" component={MemberCaseActivityTable} searchString={props.searchString} exact />
    <PrivateRoute path="/members/mcj/activity" component={MCJMemberReportTabs} searchString={props.searchString} exact />
    <PrivateRoute path="/org/onboarding/:organization_id" component={OrgOnboardingList} searchString={props.searchString} exact />
    <PrivateRoute path="/orgs/stats" component={MultiOrganizationReport} searchString={props.searchString} exact />
    <PublicRoute path="/new/org/onboarding/:organization_id/:id?" component={OrgOnboardingForm} exact />
  </Suspense>
)

export default Router;