import env from '../env';
import axios from 'axios';

class DataSourceService {

  static getDataSourceList(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/data_source_lists', {params:req}).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    })
  }

  static createDataSource(req){
    return new Promise((resolve, reject) => { 
      var u = env.health_backend+'health/data_source_lists'
      axios.post(u, req).then(function success(res){
        if(res.status == 201){
          resolve( res );
        }else{
          reject()
        }
      })
    })
  }

  static updateDataSourceList(id, req){
    return new Promise((resolve, reject) => { 
      var u = env.health_backend+'health/data_source_list/'+id
      axios.put(u, req).then((res) => {
        if(res.status == 204){
          resolve( res.data );
        }
      })
    })
  }

  static searchDataSource(params){
    return new Promise((resolve, reject) => {	
    	var u = env.health_backend+'health/data_source_lists/search'
     	axios.post(u,params).then(function success(res){
     		if(res.status == 200){
       		resolve( res.data );
     		}
     	})
  	})
  }

  static createDataSourceList(params){
    return new Promise((resolve, reject) => { 
      var u = env.health_backend+'health/data_source_lists'
      axios.post(u,params).then((res) => {
        if(res.status == 200){
          resolve( res.data );
        }
      })
    })
  }

  static updateDataSourceListStatus(params){
    return new Promise((resolve, reject) => { 
      let u = env.health_backend+'health/data_source_lists/status'
      axios.post(u, params).then(function success(res){
        if(res.status == 200){
          resolve( res );
        }else{
          reject()
        }
      })
    })
  }

  static deleteDataSourceListStatus(req){
    return new Promise((resolve, reject) => {
      let u = env.health_backend+'health/data_source_lists/status'
      axios.delete(u, {params:req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })  
  }

  static getDataSource(params){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/data_sources', {params: params}).then((res) => {
        // handle success
        if(params.page_locale){
          resolve(res.data);
        }else{
          resolve(res.data.data_source_lists);
        }
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getPageLocale(params){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/page_locales', {params: params}).then((res) => {
        // handle success
          resolve(res.data.page_locales);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })
  }
}

export default DataSourceService;