import React, {Fragment, useState, useEffect, useContext} from "react"
import {AuthContext} from "../../../../contexts/Authentication/AuthStateProvider"
import HealthCategoryService from "../../HealthCategoryService"
import ApexChart from "../../../Charts/ApexChart"
import {DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import PreLoadSpinner from "../../../PreLoadSpinner"
import useStyle from "../../../../hooks/useStyle"
import {TDInputMultiSelect} from "../../../Common/FormInput"
import {PresetDateRange, LeaderboardCharts} from "../../../../constants/DataSourceList"
import {Filters} from "./Filters"
import FilterSlider from "../../../Common/FilterSlider"
import useModal from '../../../../hooks/useModal'
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import Tabs, { TabPane } from "rc-tabs"

let requestParams = null
const VillageLeaderboard = ({talukaData}) => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [filter, setFilter] = useState({})
  const [charts, setCharts] = useState(LeaderboardCharts.village)
  const [chartsData, setChartsData] = useState()

  const legend = {show:true, position:'right', width:'125px'}

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  useEffect(()=>{
    requestParams = {
      filter_type:'leadership',
      geo_level:'villages',
      district:currentUser.current_organization?.data?.district,
      taluka:talukaData.taluka,  
    }
    getReport()
  }, [filter])

  const getReport = ()=>{
    setLoading(true)
    HealthCategoryService.getInfantInsights(requestParams).then((res)=>{
      setData(res.data.reports)
      setChartsData(res.data.kpis)
      setLoading(false)     
    })
  }
  
  const {sort} = useSort(requestParams, getReport)

  const SortDirIco = ({column}) =>{
    return (
      <SortDirection sortColumn={requestParams.sort_column} 
      column={column} reverse={requestParams.reverse}/>
      
    )
  }

  return(
    <>
      {loading ? 
        <div className="spinner"/>
        :
        <div id="ct">
            <div className="card-title-gray flex justspacebetween p20">
              <h4 className="fw700 f18 text-left">
                <span>
                  {currentUser.current_organization?.data?.state}  
                  {currentUser.current_organization?.data?.state && ' > '}  
                </span>
                <span>
                  {currentUser.current_organization?.data?.district} 
                  {currentUser.current_organization?.data?.district && ' > '}  
                </span>
                <span>{talukaData.taluka}</span>
              </h4>
              <h4 className="fw700 f16">
                Last 90 Days
              </h4>
            </div>
            <Tabs>
              <TabPane tab="General" key="1">
                <div className="card-body p10 table-responsive clearfix">
                  <Table className="shadow-small">
                    <THead>
                      <TR>
                        <TH onClick={e=>sort('village')}>
                          No 
                          <SortDirIco column="village"/>
                        </TH>
                        <TH onClick={e=>sort('village')}>
                          Name 
                          <SortDirIco column="village"/>
                        </TH>
                        <TH onClick={e=>sort('medical_facilities')}>
                          Facilities
                          <SortDirIco column="medical_facilities"/>
                        </TH>
                        <TH onClick={e=>sort('is_active')}>
                          Users
                          <SortDirIco column="is_active"/>
                        </TH>
                        <TH onClick={e=>sort('active_cases')}>
                          Cases
                          <SortDirIco column="active_cases"/>
                        </TH>
                        <TH onClick={e=>sort('weight_zscore_suw')}>
                          SUW
                          <SortDirIco column="weight_zscore_suw"/>
                        </TH>
                        <TH onClick={e=>sort('weight_zscore_muw')}>
                          MUW
                          <SortDirIco column="weight_zscore_muw"/>                            
                        </TH>
                        <TH onClick={e=>sort('weight_zscore_mild')}>
                          Mild
                          <SortDirIco column="weight_zscore_mild"/>                            
                        </TH>
                        <TH onClick={e=>sort('weight_zscore_normal')}>
                          Normal
                          <SortDirIco column="weight_zscore_normal"/>
                        </TH>
                      </TR>
                    </THead>
                    <TBody>
                      <TableData reports={data}/>
                    </TBody>
                  </Table>
                </div>
            </TabPane>
            <TabPane tab="KPI" key="2">
              <div className="card-body p20 clearfix">
                {charts.map((c, i) => chartsData[c.data_key][c.sid]?
                  <div className="col-md-6" key={i}>
                    <div className="p20">
                      <ApexChart id={`leaderboard_chart_${i}`} 
                        chartType={c.chart} title={c.label} legend={legend}
                        className="br-grey ht-400 wk-center bg-white w-full"
                        data={chartsData[c.data_key][c.sid]} />
                    </div>
                  </div>
                  :
                  null
                )} 
              </div>
            </TabPane>
          </Tabs>
      </div>
    }
    </>
  )
 
}

const TableData = ({reports=[]}) => reports.map((r, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {k+1}
        </TD>
        <TD className="fw500 text-blue underline">
          {r.village}
        </TD>
        <TD>
          {r.medical_facilities}
        </TD>
        <TD className="font-18">
        </TD>
        <TD>
          {r.active_cases}
        </TD>
        <TD>
          {r.weight_zscore_suw}
        </TD>
        <TD>
          {r.weight_zscore_muw}
        </TD>
        <TD>
          {r.weight_zscore_mild}
        </TD>
        <TD>
          {r.weight_zscore_normal}
        </TD>
      </TR>
    )
  })

export default VillageLeaderboard;