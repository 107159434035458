import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import ActivityService from "../ActivityService";
import querystringify from "querystringify";
import FormContainer from '../../../../FormBuilder/FormContainer';
import GeoLocation from "../../../../Common/GeoLocation"

// let formMode = null;
// let activity = {}
// let data = {}
// let formFn = {}

function UploadForm(props) {
  let { current: scope } = useRef({ formFn: {}, formMode: {}});
  const {state: { user: currentUser }} = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});


  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ActivityService.getById({id:props.item}).then((res) => {
      if (res.status == 200) {
        scope.activity = {...res.data.scope.activity, ...scope.activity.geo_data}
        scope.data = scope.activity.data || {}
        //params.form_template_id = activity.activity_form_id;
 
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      scope.activity = null;
    })
  }

  useEffect(() => {
    scope.activity = {}
    scope.data = {}
    scope.formFn = {
      form_type: "health.activity",
    }
    scope.formMode = "create-form-submissions";
    if (props.item) {
      get();
      scope.formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (activity) => {
    activity.organization_id = currentUser.current_organization_id
    activity.created_by = currentUser.id;
    activity.updated_by = currentUser.id;
    ActivityService.create(activity).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const update = (activity) => {
    activity.updated_by = currentUser.id;
    ActivityService.update(activity).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = () => {
   
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={FormTemplateId.UploadMeasureGrowthMCJForm}
          form={scope.activity}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={scope.activity}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default UploadForm;