import React, { Fragment, useState, useEffect, useContext, useRef, useMemo} from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import useStyle from "../../hooks/useStyle"
import Cs from "../../services/CommonService"
import useEscape from "../../hooks/useEscape"
import FormAlertService from "../../services/FormAlertService"
import useModal from "../../hooks/useModal"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import env from "../../env"
import FormTemplateList from '../../constants/FormTemplateList'
import {FormAlertTypes, DataSourceSelectionType} from "../../constants/DataSourceList"
import {OptionsObjList} from '../Common/FormInput'
import FormRuleAlertAssignment from "./FormRuleAlertAssignment"
import ReactTooltip from 'react-tooltip'
import GenericModal from '../Modals/GenericModal'
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { AddBtn, FontAwesomeBtn } from "../Common/Button"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import CheckAccess from '../Roles/CheckAccess'
import FormTemplateService from "../../services/FormTemplateService"

let selectedFormRule = null
const FormRuleAlert = ({formId}) => {
  useStyle("filter_dialog")

	const { state:{user:currentUser} } = useContext(AuthContext)
  
  const {current:requestParams} = useRef({
    'form_template_id':formId,
    'fields':['form_rule_alert_count'],
    'sort_column':'id',
    'sort_direction':'desc'
  })

  const [loading, setLoading] = useState(true)
  const [formRules, setFormRules] = useState([])

	const {isOpen: isFormRuleAlertAssignmentOpen, toggleModal: toggleFormRuleAlertAssignment} = useModal()

  useEffect(() => {
  	getFormRules()
  	selectedFormRule = null
  }, [])

  const getFormRules = () =>{
  	FormTemplateService.getFormTemplateRules(requestParams).then(({status, data}) => {
      if(status === 200)
    	  setFormRules(data)
        setLoading(false)
    })
  }

  const {sort} = useSort(requestParams, getFormRules)

  const syncFormRules = () =>{
    setLoading(true)
    FormTemplateService.syncFormTemplateRules().then(({status})=>{
      if(status === 204){
        alert("Success")
        getFormRules()
      }else{
        alert("Failed")
      }
      setLoading(false)
    })
  }

  const openDataMapping = (rule) =>{
    selectedFormRule = rule
    toggleFormRuleAlertAssignment()
  }

  const TableData = () => formRules.map((r, k) => {
	  return(
	    <TR key={k}>
  	    <TD className="text-gray">
  	      {r.id}
  	    </TD>
  	    <TD className="fw500">
  	      {r.name}
  	    </TD>
  	    <TD>
	        <span onClick={()=>openDataMapping(r)} className="fas fa-list">
            <span className="badge-2 sm">{r.form_rule_alert_count}</span>
          </span>
	      </TD>
	    </TR>
	  )
  })

  if(isFormRuleAlertAssignmentOpen){
    return(
    	<FormRuleAlertAssignment formId={formId} 
      isOpen={isFormRuleAlertAssignmentOpen} 
      toggleModal={toggleFormRuleAlertAssignment} 
      alertType="infant_journey.counseling"
      formRule={selectedFormRule}/>
    )
  }

  const SortDirIco = ({column}) =>{
    return (
      <SortDirection sortColumn={requestParams.sort_column} 
      column={column} reverse={requestParams.reverse}/>
    )
  }

  return(
  	<div id="ct">
  	  {loading ? 
        <div className="spinner"/>
        :
        <div className="table-fix-head">
          {/*<FontAwesomeBtn 
            iconCss="fa fa-refresh" isResponsive={false} name="Refresh"
            css="pull-right m-b-5 m-r-15 m-t-5 lh-17"
            colorCss="btn-green"
            onSelect={()=>syncFormRules()}>
          </FontAwesomeBtn>*/}

          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={()=>sort('id')}>
                  Id
                  <SortDirIco column="id"/>
                </TH>
                <TH onClick={()=>sort('name')}>
                  Name
                  <SortDirIco column="name"/>
                </TH>
                <TH>Alert</TH>
              </TR>
            </THead>
            <TBody>
              <TableData/>
            </TBody>
          </Table>
        </div>
      }
  	</div>
  )
}

const FormRuleAlertIcon = ({formId, currentUser}) =>{
  
  const {isOpen: isModalOpen, toggleModal: toggleModal} = useModal();
  useEscape(toggleModal);

  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(currentUser, 'form', 'field_alert'), [currentUser.id]
  )

  if(!permission?.write){
    return null;
  }
  
  if(!env.admin_emails.includes(currentUser.email)){
    return null; 
  }else if(isModalOpen){
  	return(
  		<GenericModal component={FormRuleAlert} 
      title="Form Rules" formId={formId}
  		toggleModal={toggleModal} isOpen={isModalOpen}/> 
   	) 	
  }else{
  	return(
      <span className='fas fa-exclamation-triangle cursor f18 text-muted-2' onClick={()=>toggleModal()}/>
    )
  } 

}

export {FormRuleAlert, FormRuleAlertIcon};