import { useState, useEffect } from 'react';

const useUserAgent = () => {
  
   // Detects if device is on iOS 
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  const isPwaMode = () =>{
    if (isInStandaloneMode() || window.matchMedia('(display-mode: standalone)').matches) {
      return true
    }
    return false
  }

  return {
    isPwaMode,
    isIos,
    isInStandaloneMode
  }

};

export default useUserAgent;
