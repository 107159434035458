import React, {useState, useEffect} from 'react';
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormContainer from '../../components/FormBuilder/FormContainer';

/*Store initial field setting before function to avoid reinitating while re-render*/

let formMode = null; /*Mode New Form or Edit Existing Form*/
let formId = null; /*Form Template id to get form from backend*/
let planner = {}; /*Store  user_id, org_id to send to backend*/
let modal_val = {}; /*Store json form field value */
let formFn = {}; /*To share use case related common code to form field component*/

let create = function(req){
  //post;
}

function Form(props) {
  /*Contain form title, description*/
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  
  /*useEffect(()=>{
    formFn.ds = {}
  }, [])*/

  formFn.handleChange = (value) =>{
    console.log(value)
    formFn.value = value;
  }

  let get = () => {
    
  }

  formMode = 'create-form-submissions';
  if(!formId && params.form_template_id) {
    if(params.planner_id==null || params.planner_id == ''){
      formMode = 'create-form-submissions';
    }else if(params.planner_id){
      formMode = 'edit-form-submissions';
      get();
    }
    formId = params.form_template_id;
  }

  let create = function(req){
    close()
  }

  let update = function(req){
    close()
  }

  let close = function(){
    props.history.goBack();
  }

  return (
    <FormContainer post={post}>
      <DynamicForm formMode={formMode} formId={formId} form={planner} data={modal_val} 
      formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={close} 
      setPost={setPost} assessmentFormId={params.assessment_form_id}/>
    </FormContainer>
  );
}

export default Form;
