import React, {Fragment, useState, useContext, useEffect} from "react";
import useStyle from '../../hooks/useStyle';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import GenericModal from "../Modals/GenericModal"
import { ParaRichTextView } from "./RichTextView";

const NotificationPopup = (props) => {
  useStyle('dashboard');      

  return(
    <div className="col-xs-12">
        <div className="card card-style">
          <div className={props.cardCss || ''}>
            <div className="success d-flex">
              <div>
                {props.imageIco ?
                  <img src={props.imageIco} className="user-dropdown flex"/>
                  :
                  <h3><i className={props.iconClass || 'fa fa-check bg-green1-dark success-ico'}/></h3>
                }
              </div>
              <div className="success-txt">
                <div className="f18">{props.title || props.titleLabel || 'Success'}</div>
                <ParaRichTextView className="m-t-5" html={props.message}/>
                {props.date?<span className="text-gray">{props.date}</span>:null}
              </div>
            </div>
            <div className="p-l-30">
              {props.onSuccess &&
                <span onClick={()=>props.onSuccess()} className="success-btn w-75 w-100p-xs">
                  {props.successLabel || 'OK'}
                </span>
              }
              {props.onCancel && 
                <span onClick={()=>props.onCancel()} className="success-btn w-75 w-100p-xs bg-lred">
                  {props.cancelLabel || 'Cancel'}
                </span>
              }
            </div>  
          </div>
        </div>
    </div>
  )
}

const ConfirmAlert = (props) => {
  useStyle('dashboard');      

  return(
    <div className="col-xs-12 bg-white">
      <div className="p-10">
        <div className="success d-flex">
          <div className="success-txt">
            <ParaRichTextView className="font-16" html={props.message}></ParaRichTextView>
          </div>
        </div>
        <div className="col-xs-12 d-flex p-10">
          {props.options.map((o, i)=>
            <span key={i} 
              onClick={e=>props.onSelect(o.sid)} 
              className={`p-5 m-l-15 m-b-5 font-16 text-center w-50p white ${o.color}`}
              htmlFor={o.html_for}>
                {o.label}
            </span>
          )}
        </div>  
      </div>
    </div>
  )
}

const ScreenOrientationAlert = ({alert}) =>{
  useStyle('dashboard');   
  const {state: {screen}} = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(screen.xs);

  const onSuccess = () => {
    localStorage.ct_screen_rotate_warning_dismiss = true;
    setIsOpen(false);
  }

  const Alert = () =>{
    return(
      <div className="col-xs-12">
        <div className="card card-style">
          <div className="success">
            <div>
              <h3><i className="fas fa-info-circle bg-green1-dark success-ico"/></h3>
            </div>
            <div className="success-txt">
              <div className="ore-alert">{alert}</div>
              <div>
                <img src="./images/orientation-200.png" className="w-100"/>
              </div>
            </div>
          </div>
          <div>
            <span onClick={()=>onSuccess()} className="success-btn w-75">OK</span>
          </div>  
        </div>
      </div>
    )
  }

  if(!localStorage.ct_screen_rotate_warning_dismiss && isOpen){
    return(
      <GenericModal component={Alert} isOpen={isOpen} toggleModal={onSuccess}/>
    )
  }  
  
  return null  
}

const AlertSM = ({className="bg-red1-dark", text, isOpen, toggleModal, posStyle={right: 0, top: '150px'}, autoClose=false}) =>{

  useEffect(()=>{
    if(isOpen && autoClose){
      setTimeout(function() {
        toggleModal()
      }, 10000);
    }
  }, [isOpen])

  if(isOpen){
    return(
      <span className={className+" p-15 pos-fix"} style={posStyle}>
        {text}
      </span>
    )
  }

  return null
}

export {NotificationPopup, ConfirmAlert, ScreenOrientationAlert, AlertSM};