import env from '../../../env'
import BaseService from "../../../services/BaseService"
import axios from 'axios'

class TrainingContentsService extends BaseService {
   	
	async updateSortOrder(req) {
		return axios.put(`${env.health_backend}health/sort/training_contents/${req.id}`, req);
	  }
	  
}

export default new TrainingContentsService('health_backend', 'health/training_contents')