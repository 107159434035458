import env from '../env';
import axios from 'axios';

class LocaleService {
    static async googleTranslate(req){
        try{
          const {status, data} = await axios.post(env.backend+"chat_bot/google_translate", req);
          if(data.error) window.alert(data.error.message);
          return data;
        }catch(e){
          console.error(e);
        }
      }

      static googleInputTools(params){
        return new Promise((resolve, reject) => {
          axios.get(env.health_backend+'health/inputtools', {params:params}).then(({status, data}) => {
            if(data[0] == "SUCCESS"){
              resolve(data[1])
            }
          })
        })
      } 

}
export default LocaleService