import env from '../env';
import axios from 'axios';

class OrganizationMemberService {
    static updateSetting(setting){
        return new Promise((resolve, reject) => {
          axios.put(`${env.health_backend}settings/${setting.id}`, setting).then((res) => {
            resolve(res);
          }).catch((e) => {
            reject(e);
          })
        }) 
      }
}
export default OrganizationMemberService