import React from 'react';
import {CueTooltip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description} from '../FieldLabel'
import { DivRichTextView } from '../../Common/RichTextView';

const InputExplanatoryText = ({renderField ,field, isFormWizard, openFieldLabelModal, currentLocale}) => {
  if(!renderField){
    return null
  }

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  /*const SetError = () => 
    <div className="errormsg">{errors[inputAttributes.name] && errors[inputAttributes.name].message}</div>*/
    
  return (
  	<div className="form-group col-xs-12" {...parentAttributes}>
      <div className="flex coldir m-b-20">
    	  <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <DivRichTextView className="br-grey p-5 bg-white" 
          html={(field.locale?.description_text?.[currentLocale] || field.description_text)} />
 	    </div>
    </div> 
  )
}

export default InputExplanatoryText;