import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
import useStyle from '../../hooks/useStyle';

const PaginationSM = props => {
  useStyle('pagination_sm');
  const {
    onPageChange,
    totalItems,
    siblingCount = 1,
    currentPage,
    itemsPerPage,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalItems,
    siblingCount,
    itemsPerPage
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={classnames('pagination-container', { [className]: className })}>
      <li className={classnames('pagination-item', {disabled: currentPage === 1})} onClick={onPrevious}>
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots" key={index}>&#8230;</li>;
        }

        return (
          <li key={index}
            className={classnames('pagination-item', {selected: pageNumber === currentPage})}
            onClick={() => onPageChange(pageNumber)}>
              {pageNumber}
          </li>
        )
      })}
      <li className={classnames('pagination-item', {disabled: currentPage === lastPage})}
        onClick={onNext}>
          <div className="arrow right" />
      </li>
    </ul>
  );
};

export default PaginationSM;
//https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/