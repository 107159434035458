import React, {useRef, useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import SortDirection from '../Common/SortDirection'
import useSort from '../../hooks/useSorting'
import useStyle from '../../hooks/useStyle'
import Cs from '../../services/CommonService'
import env from "../../env"
import GenericModal from "../Modals/GenericModal"
import useModal from "../../hooks/useModal"
import {usePageLocale} from "../../hooks/useLocale"
import DigitalContentService from "../DigitalContent/DigitalContentService"
import CTLazyLoad from "../Common/CTLazyLoad"
import {DivRichTextView} from "../Common/RichTextView"

const AboutUs = (props) => {
  const params = props.match.params
  const { state:{user:currentUser} } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState({})
    
  useEffect(() => {
    getAll()
  }, []);

  const getAll = () =>{
    const req = {
      page:1,
      per_page:1,
      sort_column:'record_id',
      sort_direction:'desc',
      content_type:params.content_type
    }
    DigitalContentService.getAll(req).then((res)=>{
      setContent(res.data.digital_contents[0])
      setLoading(false)
    })
  }

  if(loading)
    return <div className="spinner"/>

  return <Content content={content}/> 
}

const Content = ({content, contentCss}) =>{
  return(
    <CTLazyLoad css={['/css/tab_banner_layout.css']}>
      <div id="ct">
        <div className="app-content m-t-15">
          {content.data?.title ? 
            <div className="bg-white brd-10 flex justspacebetween m-b-20 p10 m-t-20">
              <h2 className="m-r-30 f18-xss">
                {content.data.title}
              </h2>
            </div>
            :
            null
          }
            
          <div className="row">
            <div className="col-md-12">
              <div className={`${contentCss || ''} bg-white brd-10 flex coldir m-b-20 p10`}>
                <DivRichTextView className="pre-line" html={(content.data.details || content.data.description)}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CTLazyLoad>
  )
}

export {AboutUs, Content};