import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../PreLoadSpinner";
import DynamicForm from "../FormBuilder/DynamicForm";
import FormTemplateId from "../../constants/FormTemplateList";
import querystringify from "querystringify";
import FormContainer from '../FormBuilder/FormContainer';
import FormTestInputService from './FormTestInputService';
import useModal from "../../hooks/useModal";

function TestInputForm(props) {
  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)

  let {current:scope} = useRef({
    formMode:null,
    form:{},
    data:{},
    formFn:{}
  });

  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params
  const path = props.match.path
  const history = props.history

  const get = async () => {
    const {status, data} = await FormTestInputService.get(params.id);
    if(status === 200){
      scope.form = data.form_test_input;
      scope.data = data.form_test_input.data;
      setFormRendered(true);
    }
  }

  useEffect(() => {
    scope.formFn = {"form_type": "form_test_input", "editPermission": props.editPermission}
    scope.formMode = "create-form-submissions"
    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = async (form) => {
    form.form_id = params.formId;
    form.organization_id = currentUser.current_organization_id;
    form.description = params.description;
    form.is_template = params.isTemplate;
    const {status, data} = await FormTestInputService.create(form);
    if(status === 201){
      onSubmit(data.form_test_input)
    }
  }

  const update = async (form) => {
    form.description = params.description;
    const {status, data} = await FormTestInputService.update(form);
    if(status === 204){
      onSubmit(form)
    }
  }

  const onSubmit = (form) => {
    props.onSubmit(form)
    props.toggleModal()
  }

  const onCancel = () => props.toggleModal()

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
            formId={params.formId}
            form={scope.form}
            data={scope.data}
            formFn={scope.formFn}
            onCreate={create}
            onUpdate={update}
            post={post}
            onCancel={onCancel}
            setPost={setPost}
            isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null
  }
  
}

export default TestInputForm;