import React, { useState, useEffect } from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon';
import {FieldLabel, OptionLabel, Description, RequiredMsg, FieldReview, FieldPoint} from '../FieldLabel';
import {CueTip} from '../../../components/Common/CueTooltip';
import {ImageFlexList} from '../../Common/Image';

const InputCheckbox = ({renderField, field, formFn, position, formData, readOnly, errors, forceUpdate, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale, fieldReview}) => {
  let opkv = field.options_key_value;
  let listKey = field.client_id+'_array';
  formData[listKey] = formData[listKey] || [];

  /*For State Management*/
  const [loading, setLoading] = useState(true);
  const [modelarray, setModelarray] = useState(formData[listKey]);
  const [optionList, setOptionList] = useState([]);
  const [noneOption, setNoneOption] = useState();

  useEffect(()=>{
    formFn.ds[field.client_id] = formFn.ds[field.client_id] || [];
  }, [])

  useEffect(()=>{
    if(renderField){
      setResultSet();
    }
  }, [currentLocale, renderField])

  useEffect(()=>{
    if(renderField){
      filterOptions();
    }
  }, [noneOption, modelarray])

  if(!renderField){
    return null;
  }

  let inputAttributes = {
    'type':'text',
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled': readOnly
  }

  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  //let validation = {'required': field.required};
  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  const setResultSet = () =>{
    if(opkv && opkv.options){
      setOptionList(opkv.options)
      setNoneOption(opkv.options.find(o => o.sid === 'none'))
    }
    setLoading(false)
  }

  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
    let idx = modelarray.indexOf(item);
    if (idx > -1) {
      setModelarray(modelarray.filter(i => i !== item));
      formData[listKey].splice(idx, 1);
      formFn.ds[field.client_id].splice(idx, 1);
    }else {
      setModelarray([...modelarray, item]);
      item = FormHelper.isNumeric(item);
      formData[listKey].push(item);
      formFn.ds[field.client_id].push(item);
    }
     
    formData[field.client_id] =  formData[listKey].toString();
    setError();
    forceUpdate();
    FormHelper.setFormTitle(field, formData, modelarray, opkv);
    //$scope.setValidity();
  }

  const toggleKv = (item) => {
    if(noneOption){
      if(item?.sid === 'none' && modelarray.indexOf(item.value) < 0){
        /*None is being selected*/
        //Remove any past selections
        setModelarray([]) 
      }

      toggle(item.value)
    }else if(item){
      //No need to filter options
      toggle(item.value)
    }
  }

  const exists = (item) => {
    return modelarray.indexOf(item) > -1;
  }

  const filterOptions = () =>{
    if(noneOption){
      if(modelarray.length === 0){
        setOptionList([...opkv.options]);
      }else if(modelarray.indexOf(noneOption.value) > -1){
        setOptionList(opkv.options.filter((o)=> o.sid === 'none'));
      }else{
        setOptionList(opkv.options.filter((o)=> o.sid !== 'none'));
      }
    }
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name];
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  return (
    <div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
        <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openDataSourceModal={openDataSourceModal}
          openFieldLabelModal={openFieldLabelModal}/>
        {(opkv && opkv.options) 
          ? <OptionsObjList list={optionList} inputAttributes={inputAttributes} 
            toggle={toggleKv} exists={exists} currentLocale={currentLocale} 
            showOnlyImage={field.show_only_image}/> 
          : <OptionsStringList list={field.options} inputAttributes={inputAttributes} 
            toggle={toggle} exists={exists} />
        }     
        <FieldPoint userRefId={formFn.userRefId} 
          clientId={field.client_id} point={field.point} 
          currentLocale={currentLocale} negPoint={field.incorrect_point}/>
        <Description description={field.description} 
          currentLocale={currentLocale}
          locale={field.locale?.description}/>
        <FieldReview review={fieldReview}/>
        <Error/>
      </div>
    </div> 
  )
}

const OptionsObjList = ({list, inputAttributes, toggle, exists, currentLocale, showOnlyImage=false}) => list.map((o, k) =>{
  const style = {'top': o.images?'1px':''};

  return(
    <span className={`checkbox-container ${o.read_only==='yes'?'input-disabled':''} `} key={k} onClick={() => toggle(o)}>
      {showOnlyImage ? 
        null
        :
        <span className="black-light-1 f14">
          <OptionLabel option={o} currentLocale={currentLocale}/>
        </span>
      }
      {o.images ? <ImageFlexList styleName="img-responsive" list={o.images}/> : null}
      <input type="checkbox" checked={exists(o.value)} readOnly/>
      <span className="checkbox-checkmark"/>
    </span>
  )
})

const OptionsStringList = ({list, inputAttributes, toggle, exists}) => list.map((o, k) =>
  <span className="checkbox-container" key={k} onClick={() => toggle(o)}>
    <span className="black-light-1 f14">{o}</span>
    <input type="checkbox" checked={exists(o)} readOnly/>
    <span className="checkbox-checkmark"/>
  </span>
)

export default InputCheckbox;