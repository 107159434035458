import React, {useEffect, useState} from 'react'
import Cs from "../../services/CommonService"
import {CountryCodes} from "../../constants/CountryCodes"
import LocationService from '../../services/LocationService'

const GeoLocation = ({geoData, getAddress}) => {
  
  let [geoWatch, setGeoWatch] = useState()

  useEffect(() => {
  	detectSupport()
  }, [])

  const detectSupport = () =>{
  	if(navigator.geolocation) {
	  checkCurrentPosition()	      	
  	}else{
      console.log("Geolocation is not supported by this browser.")
      geoData.geo_data = {"error":"Geolocation is not supported by this browser."}
  	}
  }

  const checkCurrentPosition = () =>{
  	navigator.geolocation.getCurrentPosition( setCurrentPosition, positionError, { 
        enableHighAccuracy: false, 
        timeout: 15000, 
        maximumAge: 0 
      } 
    )
  }

  const setCurrentPosition = ( position ) => { 
    try{
      //console.log(position)
      
      geoData.geo_data = {
        accuracy: position.coords.accuracy, 
        altitude: position.coords.altitude, 
        altitudeAccuracy: position.coords.altitudeAccuracy, 
        heading: position.coords.heading,
        latitude: position.coords.latitude, 
        longitude: position.coords.longitude, 
        speed: position.coords.speed
      }

      if(getAddress){
        let req = {
          format: 'jsonv2',
          lat: position.coords.latitude,
          lon: position.coords.longitude
        }

        LocationService.reverseGeocode(req).then(({status, data})=>{
          if(status === 200){ 
            geoData.geo_data.address = data.address
            geoData.geo_data.country_code = CountryCodes[data.address?.country_code][2]
            geoData.geo_data.phone_format = CountryCodes[data.address?.country_code][3] 
        	  console.log(data)	
          }  
        })
      }
    }catch(e){
      console.error(e.message)
    }
  }

  const positionError = ( error ) => { 
  	let errorMsg = null
    switch ( error.code ) { 
        case error.PERMISSION_DENIED: 
            console.error( "User denied the request for Geolocation." ); 
            errorMsg = "User denied the request for Geolocation."
            break; 
    
        case error.POSITION_UNAVAILABLE: 
            console.error( "Location information is unavailable." ); 
            errorMsg = "Location information is unavailable."
            break; 
    
        case error.TIMEOUT: 
            console.error( "The request to get user location timed out." ); 
            errorMsg = "The request to get user location timed out."
            break; 
    
        case error.UNKNOWN_ERROR: 
            console.error( "An unknown error occurred." );
            errorMsg = "An unknown error occurred."
            break; 
    }
    geoData.geo_data = {"error":errorMsg}
  }
    
  const startWatch = () => {   
    if ( !geoWatch ) { 
        if ( "geolocation" in navigator && "watchPosition" in navigator.geolocation ) { 
            let geoWatchTemp = navigator.geolocation.watchPosition( setCurrentPosition, positionError, { 
                enableHighAccuracy: false, timeout: 15000, maximumAge: 0 
            }) 
            setGeoWatch(geoWatchTemp)
        }else{
        	console.log("Geolocation watchPosition is not supported by this browser.");
        	geoData.geo_data = {"error":"Geolocation watchPosition is not supported by this browser."}
        }
    }
  }

  const stopWatch = () => { 
    navigator.geolocation.clearWatch( geoWatch ); 
    geoWatch = undefined;
  }

  return null
}

export default GeoLocation;