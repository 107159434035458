import React, {Fragment, useState, useEffect, useContext} from "react"
import NotesService from '../../../services/NotesService'
import useStyle from '../../../hooks/useStyle'
import PreLoadSpinner from "../../PreLoadSpinner"
import Cs from "../../../services/CommonService"
import {OptionsObjList} from "../../Common/FormInput"
import GenericModal from "../../Modals/GenericModal"
import useModal from "../../../hooks/useModal"
import {usePageLocale} from '../../../hooks/useLocale'
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider"
import { CTTooltip, CueTip } from "../../Common/CueTooltip"
import CommentsModal from "../../Modals/CommentsModal"
import {subscribe, unsubscribe} from "../../../hooks/useEvents"

let requestParams = {}
let dataSource = {}
const NotesBox = ({journeyProfileId, noteableId, currentUser, incrementCount}) => {
  
  const [data, setData] = useState()
  const [notes, setNotes] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    requestParams = {
      journey_profile_id: journeyProfileId,
      noteable_id: noteableId,
      organization_id: currentUser.current_organization_id,
      noteable_type: 'journey_profile',
      data_source_params:{
        data_source_id: [3692],
      }
    }
    getNoteList()
  }, [])

  let setNote = (e) =>{
    setData({note: e.target.value})
  }

  let getNoteList = () => {
    setLoading(true)
    NotesService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        dataSource = res.data.data_source_list
        setNotes(res.data.notes)
        setLoading(false)
      }
    })
  }

  let create = () =>{
    setLoading(true)
    let req = {
      created_by: currentUser.id,
      updated_by: currentUser.id,
      journey_profile_id: journeyProfileId,
      noteable_id: noteableId,
      noteable_type: 'journey_profile',
      data:data,
      organization_id: currentUser.current_organization_id,
    }
    NotesService.create(req).then((res) => {
      if (res.status === 201) {
        setNotes(res.data.notes)
        setData()
        incrementCount()
        setLoading(false)
      }
    })    
  }

  /*const setSubject = (e) =>{
    setData({...data, subject:e.target.value})
  }*/

  let NotesList = () => notes.map((n, i)=>
    <div className="br-top-grey" key={i}>
      <div className="font-18">{n.data.note}</div>
      <small className="font-12">{n.author_name}, {Cs.formatUpdateDate(n.updated_at)}</small>
    </div>
  )
  
  if(loading) return(<div className="spinner"/>)

  return(
    <Fragment>
      <div className="row p-5">
    	  <div className="col-xs-12 p-5">
          <form name="ct_note" onSubmit={()=>create()}>
            <label className="form-label m-t-10 m-b-5 req-field">Note</label>
            <textarea onChange={e=>setNote(e)}
              className="form-control ml-margin-bottom" 
              placeholder="Type and leave notes" 
              rows="4" required />
          </form>
          {data?.note ?
            <div className="ml-button ml-block ml-section ml-padding bg-highlight white" 
              onClick={()=>create()}>
                <b>ADD</b>
            </div>
            :
            null
          }
          <div className="vh-52 scroll-y">
            <NotesList/>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export {NotesBox};