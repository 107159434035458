import React, {Fragment, Suspense, lazy} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
/*import List from './List'
import Form from './Form'
import AlertForm from './AlertForm'*/
const List = lazy(() => import('./List'))
const Form = lazy(() => import('./Form'))
const AlertForm = lazy(() => import('./AlertForm'))

const NutritionCareRouter = (props) => (
  <Suspense fallback={<div className="spinner"/>}>
    <PrivateRoute path="/nutrition_cares" searchString={props.searchString} component={List} exact />
    <PrivateRoute path="/templates/nutrition_cares" searchString={props.searchString} component={List} exact />
    <PrivateRoute path="/nutrition_care/edit/:id?" component={Form} exact />
    <PrivateRoute path="/nutrition_care/create/:type/:formId/:id?" component={Form} exact />
  	<PrivateRoute path="/nutrition_care/alert/:nutrition_care_id/:id?" component={AlertForm} exact />
  </Suspense>
)

export default NutritionCareRouter;