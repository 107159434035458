import React, { useEffect, useState, Fragment } from "react"
import ChildService from "./ChildService"
import {CheckBox, DateTimePicker} from '../../../Common/FormInput'
import useStyle from "../../../../hooks/useStyle"
import FormHelper from "../../../FormBuilder/FormHelpers"
import DigitalContentService from "../../../DigitalContent/DigitalContentService"
import {ImagePlayList} from "../../../Common/Image"
import env from "../../../../env"
import {InfantGrowthZscoreLabel} from '../../../../constants/DataSourceList'
import Cs from "../../../../services/CommonService"

let bgImgStyle= {}

function InstantMeasurement(props) {
  useStyle("card")
  useStyle("card_table")

  let [loading, setLoading] = useState(false)
  const [file, setFile] = useState()
  let [data, setData] = useState({})
  let [error, setError] = useState({})
  let [report, setReport] = useState()
  let [planners, setPlanners] = useState([])
  const [digitalContentList, setDigitalContentList] = useState([])

  const [types, setType] = useState([
    {label:'Z-score and Percentile calculator for baby weight and height', sid:'measurement'},
    {label:'Multiple weight and height measurements for a single case', sid:'measurement_list'}
  ])

  const [gender, setGender] = useState([
    {label:'Female', sid:'Female'},
    {label:'Male', sid:'Male'}
  ])

  useEffect(()=>{
    bgImgStyle = {}
    getDigitalContents()
  }, [])

  useEffect(()=>{
    FormHelper.validate('ct_form', data, error, setError)
  }, [data])

  const getDigitalContents = () => { 
    setLoading(true)
    const req = {
      page:1,
      per_page:10,
      content_type:'illustration'
    }
    DigitalContentService.getAll(req).then(({status, data})=>{
     if(status == 200){
        const images = data.digital_contents?.map(d=> d.image)
        setImage(images)
        setDigitalContentList(images)
        setLoading(false)
      }
    })
  }

  const setImage = (images) => 
    bgImgStyle = {
      backgroundImage: `url("${images[0]}")`,
      backgroundColor: 'rgb(222 221 207)',
      height: '500px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      backgroundBlendMode: 'overlay'
    }

  const handleChange = (e) =>{
    data[e.target.name] = e.target.value
    setData({...data})
  }

  const toggle = (value, key) =>{
    data[key] = value
    setData({...data})
  }

  const exists = (value, key) =>{
    return data[key] == value
  }

  const onDateChanged = (name, value, date) =>{
    setData({...data, [name]:value})
  }

  const reset = () => {
    setData({})
    setReport()
    setPlanners() 
    setFile()
  }

  const submit = () =>{
    setLoading(true)
    if(data.type == 'measurement_list'){
      ChildService.uploadMeasurements(file, data).then(({status, data})=>{
        if(status == 200){
          setReport(data.measurements)
          setPlanners(data.planners)
          setLoading(false)
          scrollToResult()
        }
      })
    }else{
      ChildService.checkMeasurement(data).then(({status, data})=>{
        if(status == 200){
          setReport(data.measurement)
          setPlanners(data.planners)
          setLoading(false)
          scrollToResult()
        }
      })
    }
  }

  const scrollToResult = () =>{
    setTimeout(()=>{
      const element = document.getElementById("result");
      element.scrollIntoView();  
    }, 0)
  }

  const Icon = () => <span className="far fa-dot-circle m-r-5"/>

  const onFileUpload = (e) =>{
    setFile(e.target.files[0])
    setData({...data, file_name:e.target.files[0].name})
    e.target.value = null
  }

  return(
    <Fragment>
      <div className="content m-t-20 clearfix">
        <div className="col-xs-12 m-t-15">
          <h4>cueTree Health</h4>
        </div>
      </div>

      <div className="row content">
        <div className="col-xs-12 col-sm-7 br-grey p-3">
          <div className="p-5 bg-highlight white font-16">
            MCJ data explorer
          </div>
          <form name="ct_form" id="ct_form" className="m-b-15">
            <div style={bgImgStyle}>
            {/*<div className="row">
              <div className="col-xs-12 m-t-5 wk-center">
                <ImagePlayList list={digitalContentList} styleName="w-300"/>
              </div>
            </div>*/}

            <div className="row m-t-15">
              <div className="col-xs-12 col-sm-4 col-md-3">
                <label className="req-field font-14">1) Choose option</label>
              </div>
              <div className="col-xs-12 col-sm-8 col-md-9 font-14">
                <CheckBox list={types} toggle={toggle} exists={exists} labelKey="label" valueKey="sid" name="type" required="true"/>
              </div>
            </div>
            
            <div className="row m-t-15">  
              <div className="col-xs-12 col-sm-4 col-md-3">
                <label className="req-field font-14">2) Gender</label>
              </div>
              <div className="col-xs-12 col-sm-8 col-md-9">
                <CheckBox list={gender} toggle={toggle} exists={exists} labelKey="label" valueKey="sid" name="gender" required="true"/>
              </div>
            </div>

            <div className="row m-t-15">  
              <div className="col-xs-12 col-sm-4 col-md-3">
                <label className="req-field font-14">3) Date of birth</label>
              </div>
              <div className="col-xs-12 col-sm-8 col-md-9 font-14">
                <DateTimePicker name="dob" onChange={onDateChanged} 
                  dateFormat="YYYY-MM-DD" defaultValue={data.dob}
                  placeholder="DOB" className="form-input form-input-gray w-300" required="true"/>
              </div>
            </div>

            {data.type == 'measurement_list' ?
              <div className="row m-t-15 m-b-15">  
                <div className="col-xs-12 col-sm-4 col-md-3">
                  <label className="req-field font-14">4) Upload measurement data</label>
                </div>
                <div className="col-xs-12 col-sm-8 col-md-9 font-14">
                  <a href={env.s3_public+'Child_Growth_Measurement.xls'} target="_blank" className="underline m-r-15 black-light-1">
                    Download and use this XLS template
                  </a>
                  <label className="underline m-r-15 m-t-15 black-light-1" htmlFor="upload_file">Upload filled XLS file</label>
                  <input id="upload_file" name="file_name" type="file" className="hidden" onChange={e => onFileUpload(e)} />
                  <div>{data.file_name}</div>
                </div>
              </div>
              :
              <>
                <div className="row m-t-15">  
                  <div className="col-xs-12 col-sm-4 col-md-3">
                    <label className="req-field font-14">4) Measurement date</label>
                  </div>
                  <div className="col-xs-12 col-sm-8 col-md-9 font-14">
                    <DateTimePicker name="measure_date" onChange={onDateChanged} 
                      dateFormat="YYYY-MM-DD" defaultValue={data.measure_date}
                      placeholder="Measure Date" className="form-input form-input-gray w-300" required="true"/>
                  </div>
                </div>

                <div className="row m-t-15">  
                  <div className="col-xs-12 col-sm-4 col-md-3">
                    <label className="req-field font-14">5) Weight (Kgs)</label>
                  </div>
                  <div className="col-xs-12 col-sm-8 col-md-9 font-14">
                    <input type="number" min="1" max="25" step=".5" onChange={e => handleChange(e)} 
                    name="weight" className="form-input form-input-gray w-300" 
                    value={data.weight || ''} required/>
                  </div>
                </div>

                <div className="row m-t-15 m-b-15">  
                  <div className="col-xs-12 col-sm-4 col-md-3">
                    <label className="req-field font-14">6) Height (cms)</label>
                  </div>
                  <div className="col-xs-12 col-sm-8 col-md-9 font-14">
                    <input type="number" min="1" max="200" step=".5" onChange={e => handleChange(e)} 
                    name="height" className="form-input form-input-gray w-300" required
                    value={data.height || ''}/>
                  </div>
                </div>
              </>
            }
            
            </div>

            <div className="row m-t-15 text-center">  
              {error.is_valid && <button type="button" onClick={()=>submit()} className="butn btn-success white">Explore</button>}
              <button type="button" onClick={()=>reset()} className="butn btn-inverse m-l-15">Reset</button>
            </div>
              
          </form>
        </div>

        {loading && <div className="spinner"/>}

        {data.type == 'measurement' && report &&
          <div className="col-xs-12 col-sm-5 br-grey p-3" id="result">
            <div className="p-5 bg-highlight white font-16">
              Results
            </div>
            <div className="p-5">
              <div className="font-16">Age (weeks): {report.baby_age_weeks}</div>
              
              <div className="underline font-16 bold-500">Weight for age</div>
              <div className="m-l-5 font-16 m-b-10">
                <div> <Icon/> Z score: {report.weight_zscore}</div>
                <div> <Icon/> Percentile: {parseInt(report.weight_percentile || 0)}</div>
                <div> <Icon/> Status: {report.weight_zscore_label || 'None'}</div>
              </div>
                
              <div className="underline font-16 bold-500">Height for age</div>
              <div className="m-l-5 font-16 m-b-10">
                <div> <Icon/> Z score: {report.height_zscore}</div>
                <div> <Icon/> Percentile: {parseInt(report.height_percentile || 0)}</div>
                <div> <Icon/> Status: {report.height_zscore_label || 'None'}</div>
              </div>
                
              <div className="underline font-16 bold-500">Weight for Height</div>
              <div className="m-l-5 font-16 m-b-10">
                <div> <Icon/> Z score: {report.wfh_zscore}</div>
                <div> <Icon/> Percentile: {parseInt(report.wfh_percentile || 0)}</div>
                <div> <Icon/> Status: {report.wfh_zscore_label || 'None'}</div>
              </div>
            </div>

            <div className="p-5 bg-light-green white font-16">
              Field Guidance
            </div>
            <div className="p-5">
              {planners?.map((p, i) =>
                <a key={i} target="_blank" href={p.data.reference_url} className="font-16 m-b-5">
                  <Icon/>{p.data.name}
                </a>              
              )}
            </div>
          </div>
        } 
      </div>

      {data.type == 'measurement_list' && report &&
        <div className="col-xs-12 br-grey p-3" id="result">
          <div className="p-5 bg-highlight white font-16">
            Results
          </div>
          <GrowthMeasureTable child={data} measurements={report || []}/>
        </div>  
      }

      <div className="m-b-80"/>  

    </Fragment>
  )
  
}

const GrowthMeasureTable = ({measurements, child}) =>{
  child.age_in_weeks =  Cs.compareDate(child.dob, 'weeks', true)
  
  return(
    <div className="table-responsive m-b-80">
      <table className="table-sm shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className='p-2 bold-400 text-center miw-95'>
              Date 
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              {child.age_in_weeks > 13 ? 'Age(weeks)' : 'Age(months)'}
            </th>
                
            <th className='p-2 bold-400 text-center miw-95'>
              W (kgs)
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Z score
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Percentile (%)
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Status
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              H (cms)
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Z score
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Percentile (%)
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Status
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              WFH
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Z score
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Percentile (%)
            </th>

            <th className='p-2 bold-400 text-center miw-95'>
              Status
            </th>

            </tr>
          </thead>
          <tbody>
            <GrowthMeasureTableData measurements={measurements || []} child={child}/>
          </tbody>
        </table>
      </div>
    )
  }

  const GrowthMeasureTableData = ({measurements, child}) => measurements.map((data, k) => {
    
    const weightZscoreStyle = {
      color:InfantGrowthZscoreLabel[data.weight_zscore_label]?.color
    }
    const heightZscoreStyle = {
      color:InfantGrowthZscoreLabel[data.height_zscore_label]?.color
    }
    const wfhZscoreStyle = {
      color:InfantGrowthZscoreLabel[data.wfh_zscore_label]?.color
    }
      
    return(
      <tr key={k}>
        <td className="text-center">
          {Cs.formatUpdateDate(data.measure_date, 'MMMM D YYYY')}
        </td>
        
        <td className="text-center">
            {Cs.compareDate(
              data.dob, 
              data.measure_date, 
              (child.age_in_weeks > 13 ? 'months': 'weeks'), 
              true, 
              true
            )?.toFixed(1)}
        </td>

        <td className="text-center bg-grey-1">{data.weight}</td>

        <td className="text-center bg-grey-1">{data.weight_zscore}</td>

        <td className="text-center bg-grey-1">{Cs.round(data.weight_percentile)}</td>

        <td className="text-center bg-grey-1" style={weightZscoreStyle}>{data.weight_zscore_label}</td>

        <td className="text-center bg-lblue-1">{data.height}</td>

        <td className="text-center bg-lblue-1">{data.height_zscore}</td>

        <td className="text-center bg-lblue-1">{Cs.round(data.height_percentile)}</td>

        <td className="text-center bg-lblue-1" style={heightZscoreStyle}>{data.height_zscore_label}</td>

        <td className="text-center bg-grey-2">{data.wfh}</td>

        <td className="text-center bg-grey-2">{data.wfh_zscore}</td>

        <td className="text-center bg-grey-2">{Cs.round(data.wfh_percentile)}</td>

        <td className="text-center bg-grey-2" style={wfhZscoreStyle}>{data.wfh_zscore_label}</td>
        
      </tr>
    )
  })

export default InstantMeasurement;