import env from '../../env';
import axios from 'axios';
import BaseService from '../../services/BaseService';

class HealthCategoryService extends BaseService{

  async timelines(req) {
    return await axios.get(`${env.health_backend}health/timelines`, { params: req });
  }
  
  async getInfantInsights(req) {
    return await axios.post(`${env.health_backend}health/infant_insights`, req);
  }
  
  async getNotificationReports() {
    return await axios.get(`${env.health_backend}health/reports/event_driven_notifications`);
  }
  
  async getContentManagerData() {
    return await axios.get(`${env.health_backend}content/manager`);
  }

  async getUpcomingCaseInfo(req){
    return await axios.get(env.health_backend+'health/upcoming/journey_profiles', {params: req});
  }

  async getJourneyNotifications(req){
   return await axios.get(env.health_backend+'health/journey_profiles:notifications', {params: req});
  }

}

export default new HealthCategoryService('health_backend','health/journey_profiles', {post: true, put: false});