import React, {useState} from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description, RequiredMsg, FieldReview} from '../FieldLabel'
import {CueTip} from '../../../components/Common/CueTooltip';

const InputColorPicker = ({renderField ,field, position, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale, fieldReview}) => {
  const [showError, setShowError] = useState(false);
  formData[field.client_id] = formData[field.client_id] || field.default_value;

  if(!renderField){
    return null
  }

  let inputAttributes = {
    'type':'color',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':readOnly,
    'required':field.required,
    'className':'form-input form-input-gray form-input-full'
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {required: true}
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  //FormHelper.setDisabled(field, inputAttributes, parentAttributes);

  const handleChange = (event) =>{
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
  }
  
  const setError = (event) => {
    if(field.required){
      const { target:{name, validity:{valueMissing, typeMismatch}} } = event;
      errors[name].invalid = valueMissing;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
    	  <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
   	    <input {...inputAttributes} onChange={e => handleChange(e)} onBlur={e => {setError(e)}}
        defaultValue={formData[field.client_id]} 
        data-tip data-for={`tooltip_${field.client_id}`}/> 	 
        <span className="fts-13">{field.description}</span>
        <Description description={field.description} 
          currentLocale={currentLocale}
          locale={field.locale?.description}/>
        <FieldReview review={fieldReview}/>
        <Error/>
      </div>
 	  </div> 
  )
}

export default InputColorPicker;