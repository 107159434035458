import env from '../../env';
import axios from 'axios';
import BaseService from '../../services/BaseService';
const url = `${env.health_backend}health/roles`;


class RoleService extends BaseService {
  async getRoleAutofill(req){
    return await axios.get(env.health_backend+'autofill/roles', {params: req})
  }
}

export default new RoleService('health_backend','health/roles');