import React, {Fragment, useState, useEffect, useContext} from "react"
import {AuthContext} from "../../../../contexts/Authentication/AuthStateProvider"
import HealthCategoryService from "../../HealthCategoryService"
import ApexChart from "../../../Charts/ApexChart"
import {DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import PreLoadSpinner from "../../../PreLoadSpinner"
import useStyle from "../../../../hooks/useStyle"
import {TDInputMultiSelect} from "../../../Common/FormInput"
import {PresetDateRange, LeaderboardCharts} from "../../../../constants/DataSourceList"
import {Filters} from "./Filters"
import FilterSlider from "../../../Common/FilterSlider"
import useModal from '../../../../hooks/useModal'
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import VillageLeaderboard from './VillageLeaderboard'
import GenericModal from '../../../Modals/GenericModal'
import {CopyRight} from "../../../Common/CopyRight"

let requestParams = null
const Leaderboard = ({isFilterOpen, toggleFilter, activeTab}) => {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [filter, setFilter] = useState({})
  const [charts, setCharts] = useState(LeaderboardCharts)
  const [reportType, setReportType] = useState('leadership')
  const [selectedTaluka, setSelectedTaluka] = useState()

  //const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen:isVillageReportOpen, toggleModal:toggleVillageReportModal } = useModal()

  /*useEffect(() => {
    toggleFilterModal()
  }, [isFilterBtnClick])*/

  useEffect(()=>{
    requestParams = {
      filter_type:reportType,
      geo_level:'talukas',
      start_date:filter.start_date,
      end_date:filter.end_date,
      district:currentUser.current_organization?.data?.district,
      talukas:filter.talukas,  
      villages:filter.villages, 
      medical_facility_ids:filter.medical_facility_ids 
    }
  	getReport()
  }, [filter, reportType])

  const getReport = ()=>{
  	setLoading(true)
  	HealthCategoryService.getInfantInsights(requestParams).then((res)=>{
      setData(res.data.reports)
	    setLoading(false)			
	  })
  }
  
  const {sort} = useSort(requestParams, getReport)

  const openTalukaReport = (taluka_data) =>{
    setSelectedTaluka(taluka_data)
    toggleVillageReportModal()
  }

  const SortDirIco = ({column}) =>{
    return (
      <SortDirection sortColumn={requestParams.sort_column} 
      column={column} reverse={requestParams.reverse}/>
      
    )
  }

  return(
    <>
      {loading ? 
        <div className="spinner"/>
        :
        <div className="app-content-section">
          <div className="col-md-12">
            <div className="bg-white brd-10 m-b-30">
                  <div className="card-title-gray flex justspacebetween p20">
                    <h4 className="fw700 f18 text-left">
                      <span>
                        {currentUser.current_organization?.data?.state} 
                        {currentUser.current_organization?.data?.state && ' > '} 
                      </span>
                      <span>{currentUser.current_organization?.data?.district} </span> 
                    </h4>
                    <h4 className="fw700 f16">
                      Last 90 Days
                    </h4>
                  </div>
                  <div className="card-body p20 table-responsive clearfix">
                    <Table className="shadow-small">
                      <THead>
                        <TR>
                          <TH onClick={e=>sort('taluka')}>
                            No 
                            <SortDirIco column="taluka"/>
                          </TH>
                          <TH onClick={e=>sort('taluka')}>
                            Name 
                            <SortDirIco column="taluka"/>
                          </TH>
                          <TH onClick={e=>sort('villages')}>
                            Villages
                            <SortDirIco column="villages"/>
                          </TH>
                          <TH onClick={e=>sort('medical_facilities')}>
                            Facilities
                            <SortDirIco column="medical_facilities"/>
                          </TH>
                          <TH onClick={e=>sort('active_cases')}>
                            Cases
                            <SortDirIco column="is_active"/>
                          </TH>
                          <TH onClick={e=>sort('weight_zscore_suw')}>
                            SUW
                            <SortDirIco column="weight_zscore_suw"/>
                          </TH>
                          <TH onClick={e=>sort('weight_zscore_muw')}>
                            MUW
                            <SortDirIco column="weight_zscore_muw"/>                            
                          </TH>
                          <TH onClick={e=>sort('weight_zscore_mild')}>
                            Mild
                            <SortDirIco column="weight_zscore_mild"/>                            
                          </TH>
                          <TH onClick={e=>sort('weight_zscore_normal')}>
                            Normal
                            <SortDirIco column="weight_zscore_normal"/>
                          </TH>
                        </TR>
                      </THead>
                      <TBody>
                        <TableData reports={data} openTalukaReport={openTalukaReport}/>
                      </TBody>  
                    </Table>
                  </div>
              </div>
          </div>
        </div>
      }
      {isVillageReportOpen && 
        <GenericModal component={VillageLeaderboard} 
          isOpen={isVillageReportOpen}
          toggleModal={toggleVillageReportModal}
          talukaData={selectedTaluka}
          widthCss="w-90p"
        />
      }
      {(isFilterOpen && activeTab === 'leadership') && 
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={()=>toggleFilter()}
          chartFilter={filter}
          setChartFilter={setFilter}
          onFilterSubmit={getReport}
          activeTab={activeTab}/>
      }
    </>
  )
 
}

const TableData = ({reports=[], openTalukaReport}) => reports.map((r, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {k+1}
        </TD>
        <TD onSelect={()=>openTalukaReport(r)} className="fw500 text-blue underline">
          {r.taluka}
        </TD>
        <TD>
          {r.villages}
        </TD>
        <TD>
          {r.medical_facilities}
        </TD>
        <TD>
          {r.active_cases}
        </TD>
        <TD>
          {r.weight_zscore_suw}
        </TD>
        <TD>
          {r.weight_zscore_muw}
        </TD>
        <TD>
          {r.weight_zscore_mild}
        </TD>
        <TD>
          {r.weight_zscore_normal}
        </TD>
      </TR>
    )
  })

export default Leaderboard;