import React, { useEffect, useState, useContext, useRef } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import DynamicForm from "../DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import NotesService from "../../../services/NotesService"
import querystringify from "querystringify"
import FormContainer from "../FormContainer"
import GeoLocation from "../../Common/GeoLocation"
import Cs from "../../../services/CommonService"

// let formMode = null
// let note = {}
// let data = {}
// let formFn = {}

function NoteForm(props) {
  const { state: { screen, user: currentUser } } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let { current: scope } = useRef({ formFn: {} });

  const params = props.match.params
  let queryParam = querystringify.parse(props.location.search);

  const get = () => {
    NotesService.get(params.id).then((res) => {
      if (res.status === 200) {
        scope.note = { ...res.data.note, ...scope.note.geo_data }
        scope.data = { ...scope.data, ...scope.note.data }
        setFormRendered(true)
      }
    }).catch(function (res) {
      setFormRendered(true)
      scope.note = null
    })
  }

  useEffect(() => {
    scope.formFn = {
      form_type: "notes",
    }
    scope.note = {}
    scope.data = {}
    setFormMode()
  }, [])

  const setFormMode = () => {
    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      scope.formMode = "create-form-submissions"
      setFormRendered(true)
    }
  }

  const create = (note) => {
    note.organization_id = currentUser.current_organization_id
    note.created_by = currentUser.id
    note.updated_by = currentUser.id
    note.noteable_field_id = queryParam.noteable_field_id
    note.noteable_type = queryParam.noteable_type
    note.annotation_form_id = params.annotation_form_id
    NotesService.create(note).then((res) => {
      if (res.status === 201) {
        onSubmitPageRedirect(res.data.note)
      }
    }).catch((res) => {
      onSubmitPageRedirect()
    })
  }

  const update = (note) => {
    NotesService.update(note).then((res) => {
      if (res.status === 204) {
        onSubmitPageRedirect()
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (note) => {
    /*if(props.isPopupMode){
      props.onFormSubmit(note)
    }*/
    props.history.goBack()
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={scope.formMode}
          formId={FormTemplateId.FormFieldNote}
          form={scope.note}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}
        />
        <GeoLocation geoData={scope.note} />
      </FormContainer>
    )
  } else {
    return null
  }
}

export default NoteForm;