import env from "../../env"
import Cs from "../../services/CommonService"

let CheckAccess = {};

CheckAccess.destructUser = (current_user)=>{
  const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.accesses || {}
  const permissions = currentMember?.permissions || {}
  return {
    currentMember:currentMember,
    orgMenuAccesses:orgMenuAccesses,
    permissions:permissions
  }
}

CheckAccess.checkOrgLevelAccess = (current_user, sid, orgMenuKey)=>{
  const {orgMenuAccesses} = CheckAccess.destructUser(current_user)

  if(orgMenuKey && orgMenuAccesses[orgMenuKey]?.[sid]){
    return true;
  }else if(orgMenuAccesses?.[sid]){
    return true;
  }

  return false;
}

CheckAccess.checkOrgAccess = (current_user, sid, menuKey="menu")=>{
  const {currentMember, orgMenuAccesses, permissions} = CheckAccess.destructUser(current_user)

  /*const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.accesses || {}
  */
  const userMenuAccess = permissions?.[menuKey] || {}

  if(orgMenuAccesses?.[sid] && (currentMember.role_master || currentMember.role_admin || userMenuAccess?.[sid])){
    return true
  }

  return false
}

CheckAccess.checkTrainingAccess = (current_user, sid)=>{
  const {currentMember, orgMenuAccesses, permissions} = CheckAccess.destructUser(current_user)
  const userMenuAccess = permissions?.training || {}

  if(orgMenuAccesses?.training?.[sid] && (currentMember.role_master || currentMember.role_admin || userMenuAccess?.[sid])){
    return true
  }

  return false
}

CheckAccess.checkUserAccess = (current_member={}, user_access={}, sid)=>{
  if(current_member.role_master || current_member.role_admin || current_member.role_dupty_admin || user_access?.[sid]){
    return true
  }

  return false
}

CheckAccess.checkIsAdmin = (current_user) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  return currentMember.role_master || currentMember.role_admin || currentMember.is_dupty_admin
}

CheckAccess.checkLocaleTranslateAccess = (current_user, data_item, data_type, rw_type) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  const rw = currentMember?.permissions?.menu[data_type] 
  return CheckAccess.checkIsAdmin(current_user) 
    || data_item.created_by === current_user.id
    || (rw?.[rw_type] 
      && currentMember?.permissions?.locale_translate?.indexOf(data_item?.data_source?.language?.sid) > -1
    )
}

CheckAccess.checkLocaleAuditAccess = (current_user, data_item, data_type) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  return (currentMember?.permissions?.menu[data_type]?.read
    && currentMember?.permissions?.locale_audit?.indexOf(data_item?.data?.language_sid) > -1
  )
}

CheckAccess.getMenuPermission = (current_user, sid)=>{
  /*const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.accesses || {}
  const userMenuAccess = currentMember?.permissions?.menu || {}*/
  const {orgMenuAccesses, permissions} = CheckAccess.destructUser(current_user);
  const userMenuAccess = permissions?.menu || {};
  if(orgMenuAccesses?.[sid] && CheckAccess.checkIsAdmin(current_user)){
    //default full access
    return {
      read:true, write:true, csv_download:true, delete:true, 
      ...CheckAccess.addExtraSubPermission(sid)
    }
  }

  return userMenuAccess?.[sid] ? userMenuAccess?.[sid] : {read:false, write:false};
}

CheckAccess.addExtraSubPermission = (sid) =>{
  if(["training", "user_reg_request"].includes(sid)){
    return {verify: true, add_to_org_member: true}; 
  }
  if(sid === "feedback"){
    return {org_feedback: true}; 
  }
  return {};
}

CheckAccess.getRoleName = (current_user) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)

  if(currentMember.role_master){
    return 'Org Master'
  }else if(currentMember.role_admin){
    return 'Org Admin'
  }else if(currentMember.is_dupty_admin){
    return 'Org Deputy Admin'
  }else{
    return currentMember.role_names
  }
}

CheckAccess.getJourneyTypeIds = (current_user) =>{
  return current_user
  ?.current_subscription
  ?.journeys
  ?.map((j) => j.pk_id.toString()) || []
}

CheckAccess.hasDeleteAccess = (current_user, data_item) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  return CheckAccess.checkIsAdmin(current_user) || data_item.created_by === current_user.id
}

CheckAccess.hasEditAccess = (current_user, data_item) => {
  return (current_user.id === data_item.created_by)
}

CheckAccess.isDevUser = (email) => env.admin_emails.includes(email)

CheckAccess.filterUserLocale = (localeList, currentUser) =>{
  return localeList.filter(l => (['english', currentUser?.current_organization?.data_source?.language?.sid].indexOf(l.sid)>-1))
}

CheckAccess.getMemberPermissionBySid = (current_user, permission_sid, rw_sid)=>{
  const {orgMenuAccesses, currentMember} = CheckAccess.destructUser(current_user)

  if(orgMenuAccesses?.[permission_sid] && CheckAccess.checkIsAdmin(current_user)){
    //default full access
    return {
      read:true, write:true, csv_download:true, delete:true, filters:true,
      ...CheckAccess.addExtraSubPermission(permission_sid)
    }
  }

  return currentMember?.permissions?.[permission_sid]?.[rw_sid] || {read:false, write:false};
}

CheckAccess.isRoleMentor = (current_user) => {
  const {currentMember} = CheckAccess.destructUser(current_user)
  return currentMember?.is_shareable || CheckAccess.checkIsAdmin(current_user)
}

CheckAccess.hasMcjFormWriteAccess = (current_user, sid) => {
  if(sid){
    let permission = CheckAccess.getMemberPermissionBySid(current_user, 'infant_process', sid)
    if(permission.write) return true
    
    permission = CheckAccess.getMemberPermissionBySid(current_user, 'infant_ppt', sid)
    if(permission.write) return true
  }
  return false
}

CheckAccess.hasMcjActivityEditAccess = (current_user, activity, sid) => {
  return activity.id && (activity.draft || Cs.isToday(activity.updated_at) || CheckAccess.hasMcjFormWriteAccess(current_user, sid))
}

CheckAccess.getRoleType = (current_user) =>{
  const currentMember = current_user.current_member || {}
  if(CheckAccess.hasAllFacilityAccess(current_user)){
    return 'admin';
  }else if(currentMember.is_shareable){
    //Role Mentor
    return 'mentor';
  }else if(currentMember.supervise_role_rids?.length > 0){
    //Role Supervisor
    return 'supervisor';
  }
}

CheckAccess.hasAllFacilityAccess =(current_user) =>{
  try{
    const currentMember = current_user.current_member || {};
    return CheckAccess.checkIsAdmin(current_user) || currentMember.has_all_facility_access;
  }catch(e){
    console.log(e);
    return false;
  }  
}

CheckAccess.isInSameOrg =(currentUser, orgId) =>{
  try{
    return currentUser?.current_organization_id === orgId;
  }catch(e){
    console.log(e)
    return false
  }  
}

CheckAccess.getRoleIdsToSupervise =(currentUser) =>{
  try{
    const currentMember = currentUser.current_member || {};
    return CheckAccess.checkIsAdmin(currentUser) ? [] : currentMember.supervise_role_rids?.map(r => parseInt(r));
  }catch(e){
    console.log(e)
    return false
  }  
}

CheckAccess.getMfTalukas =(currentUser) =>{
  try{
    const currentMember = currentUser.current_member || {};
    const org = currentUser.organizations.find(o => o.id === currentUser.current_organization_id);
    return currentMember?.has_all_facility_access ? [] : (org?.medical_facilities ? [...new Set(org?.medical_facilities?.map(f=>f.taluka))] : [])
  }catch(e){
    console.log(e)
    return false
  }  
}

export default CheckAccess;

/*CheckAccess.checkOrgAccess = (current_user, sid)=>{
  const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.accesses || {}
  const userMenuAccess = currentMember?.permissions?.menu || {}

  if(currentMember.role_master || currentMember.role_admin || 
      (orgMenuAccesses?.[sid] && userMenuAccess?.[sid])){
    return true
  }

  return false
}*/

//let role = {};

/*CheckAccess.userAccess = (currentUser, journey, invited_journey_member) =>{
  if(currentUser.id == journey.updated_by ||
    (invited_journey_member && (invited_journey_member.full_access ||  
    (invited_journey_member.permissions && invited_journey_member.permissions.full_access)))){
      journey.is_admin_user = true;
      journey.is_template_journey = false;
  }else if(journey.education_journey_profiles.data.ai_training_user=='Yes'){
      journey.is_template_journey = true;
  }
}

CheckAccess.hasStepActivityAccess = (currentUser, journey, activity) =>{
  try{
    if(journey.is_admin_user || currentUser.id == activity.created_by){
      return true;
    }else if(activity.user_roles && activity.user_roles.permissions.add_step_activity){
      return true;
    }
  }catch(e){
      return false;  
  }
}

CheckAccess.hasChecklistAccess = (journey, activity) =>{
  return (journey.is_admin_user || (activity.user_roles && activity.user_roles.permissions.add_checklist))
}

CheckAccess.hasRatingAccess = (activity) =>{
  return activity.user_roles && activity.user_roles.permissions.add_review
}

CheckAccess.hasQuickOrPathAccess = (permission) =>{
  return (permission.quick_access || permission.track)
}

CheckAccess.hasAccess = (permission, key) =>{
  return (permission[key])
}

CheckAccess.isJourneyAuthor = (currentUser, journey)=>{
  return currentUser.id == journey.updated_by
}*/