import React, {Fragment, useState, useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../services/CommonService"
import useModal from "../../../../hooks/useModal"
import {FormFieldTable, RenderData} from "../../../Common/FormFieldsView"
import DailyMeasurementsService from "../DailyMeasurementsService"
import useStyle from '../../../../hooks/useStyle'
import GenericModal from "../../../../components/Modals/GenericModal"
import DailyMeasurementForm from '../Form/DailyMeasurementForm'
import ReactTooltip from "react-tooltip"
import { CTTooltip } from "../../../Common/CueTooltip"
import useSort from "../../../../hooks/useSorting"
import DailyCheckTable from "./DailyCheckTable"
import FilesLinksModal from "../../HealthFilesLinks/FilesLinksModal"
import FilterModal from "../../Wellness/List/WellnessFilterModal"
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import NotesModal from "../../../Modals/NotesModal"
import CommentsModal from "../../../Modals/CommentsModal"
import DynamicCard from "../../../Common/DynamicCard"

let requestParams = {}
let selectedItem = null
let measureFormProps = null
let dataSources = {}
const DailyMeasurementTable = (props) =>{

  const {journeyProfile, searchString, componentName, dataSource:component} = props
  const {child_form:formId} = component

  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [measurements, setMeasurements] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [formFields, setFormFields] = useState()
  const [graphData, setGraphData] = useState({})

  const { isOpen: isMeasurementFormOpen, toggleModal:toggleMeasurementModal} = useModal()
  const { isOpen: isFormFilterOpen, toggleModal:toggleFormFilterModal} = useModal()
  const { isOpen: isCommentOpen, toggleModal: toggleCommentModal} = useModal()
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal()
  const { isOpen: isChecklistOpen, toggleModal: toggleChecklistModal} = useModal()
  const { isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal} = useModal()
  const { isOpen: isMeasurementCardOpen, toggleModal: toggleMeasurementCardModal} = useModal()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:7,
      journey_profile_id: journeyProfile.id,
      /*chart_type:'daily_check',
      date_filter:"week",*/
      form_fields_params: {
        ids:[formId],
        //is_table_view:true
      }
    }
    getActivityList()
  }, [searchString, currentpage])

  let getActivityList = () => {
    setLoading(true)
    if(formFields) delete requestParams.form_fields_params
    DailyMeasurementsService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        dataSources = res.data_source_list
        requestParams.totalItems = res.data.meta.total
        setGraphData(res.data.report)
        setMeasurements(res.data.measurements)
        if(!formFields)setFormFields(res.data.form_fields)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getActivityList)

  const openForm = (path, id) =>{
    measureFormProps = Cs.routeCancerPath(path, journeyProfile, history, id)
    measureFormProps.component = DailyMeasurementForm
    toggleMeasurementModal()
  }

  const onFormSubmit = (activity={}) =>{
    getActivityList()
    toggleMeasurementModal()
  }

  const addNotes = () =>{
    toggleCommentModal()
  }
  
  const addChecklist = (e) => {
    toggleChecklistModal()
  }

   const openMeasurementCard = (measurement) => {
    selectedItem = measurement
    toggleMeasurementCardModal()
  }

  const Footer = () => (
    <div className="card-footer m-t-10 ht-25">
      <div className="pull-left">
        <span className="m-l-15">
         
        </span>
        <span className="m-l-25" onClick={()=>addChecklist()}>
          <i className="far fa-check-circle font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">0</span>
        </span>         
        <span className="m-l-25" onClick={()=>addNotes()}>
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">0</span>
        </span> 
      </div>
      <div className="pull-right">
        <span className="m-l-25">
          <i data-tip="Menu" className="fa fa-ellipsis-h m-r-15" />
        </span>
      </div>
    </div>
  )

  if(isMeasurementFormOpen){
    return (
      <GenericModal toggleModal={toggleMeasurementModal} 
        isPopupMode="true" onFormSubmit={onFormSubmit} 
        closeConfirmPopup={true} {...measureFormProps} 
      />
    )
  }

  if(isMeasurementCardOpen){
    return (
      <GenericModal toggleModal={toggleMeasurementCardModal} 
        isOpen={isMeasurementCardOpen}
        component={DynamicCard}
        formFields={formFields[formId]}
        item={selectedItem}
        addNotes={addNotes} 
        addChecklist={addChecklist}
        isPopupMode="true" 
      />
    )
  }

  /*if(component.sid == 'daily_check'){*/
    return(
      <>
        <div className="p5">
          <div className="app-header flex rowrev">
            <span id="add_measure_growth" data-tip="Record details in a new entry" 
              className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" 
              onClick={()=>openForm(component)}>
                <i className="fas fa-plus" />
            </span>
          </div>
            
          {loading ? 
            <div className="spinner"/> : 
            <DailyCheckTable requestParams={requestParams} 
              sort={sort} measurements={measurements} 
              formFields={formFields[formId]}
              routeForm={openForm} 
              Footer={Footer}
              graphData={graphData}
              component={component}
              dataSources={dataSources}
              addNotes={addNotes} 
              addChecklist={addChecklist}
              onRowClick={openMeasurementCard}
            />
          }    
        </div>

        {isCommentOpen && 
          <CommentsModal isOpen={isCommentOpen} 
            toggleModal={toggleCommentModal} 
            item={journeyProfile} type="daily_measurements"/>
        }

        {isChecklistOpen && 
          <ChecklistsModal isOpen={isChecklistOpen} 
            toggleModal={toggleChecklistModal} 
            item={journeyProfile} type="daily_measurements"/>
        }
        
        {isFileLinkOpen && <FilesLinksModal toggleModal={toggleFileLinkModal}/>}
      </>
    )
  /*}*/

  return null
  
}

export default DailyMeasurementTable;