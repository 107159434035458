import React ,{Fragment,useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../../services/CommonService'
import {FormFieldCard, RenderData} from "../../Common/FormFieldsView"
import CommonService from '../../../services/CommonService'
import {MaximizeIcon, ChatGrayIcon, LinkDeleteIcon, EditGrayIcon, HamburgerMenuIcon} from '../../Common/ImageIcons'
import FormTemplateId from "../../../constants/FormTemplateList"

const NoteCard = (props) => {
  let {note, formFieldsList, permission, onDelete} = props
  let {data, data_source} = note
  let formFields = formFieldsList[FormTemplateId.FormFieldNote] || []

  const cardStyle = {backgroundColor: "#607D8B"}

  if (!data) return null;

  const Header = () => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.title}
      </h4>
      <div className="f14 m-t-10 fw500">
        {note.author_name} &nbsp;-&nbsp; 
        {CommonService.formatUpdateDate(note.updated_at)} 
      </div>
    </div>
  )

  const Footer = () => {
    return(
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">{note.record_id}</li>
          <li className="m-r-25">
            <EditGrayIcon link={`/add/formfield/notes/${note.annotation_form_id}/${note.id}`}/>
          </li>
          <li className="m-r-25">
            <LinkDeleteIcon onSelect={() => onDelete(note.id)}/>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className="bg-white brd-10 card-shadow m-b-15">
      <Header/>
      <div className="card-content p20">
        <div className="b-tab-table active scroll-auto ht-400">
          <div className="p-b-15 m-b-15 m-t-15">
            <FormFieldCard formFields={formFields} data={data} dataSource={data_source}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default NoteCard;