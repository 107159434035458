import React, { Fragment, useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import useOnLine from '../../hooks/useOnLine'
import Cs from '../../services/CommonService'

const Footer = (props) => {
  useStyle('/css/footer.css', true)

  const { state:{isAuthenticated, user:currentUser}, dispatch } = useContext(AuthContext)
  const geoData = currentUser?.geo_data?.address

  const {isOnline, connectionStatus} = useOnLine();

  return (
    <div id="ct" className="footer-container">
      {currentUser.current_organization?.data?.consent_terms_privacy == 1 ?
        <footer className="demo-org-notice-bar">
          <div className="flex font-16 aligncenter justspacebetween p-4-29 bg-cancel white">
            <span>Test account <u>{currentUser.name || currentUser.email}</u></span>
            <span>{geoData?.county} - {geoData?.country}</span>
            <span>{Cs.formatUpdateDate(new Date())}</span>
          </div>
        </footer>
        :
        null
      }
      
      {connectionStatus ? <div className="offline">{connectionStatus}</div> : null}
      
      <footer className="bottom-bar hide-xss">
        <div className="flex justspacebetween">
          <p className="f11 text-white p-5 lh-14">
            (C) {new Date().getFullYear()} IPIPAL INC. 
            All Rights Reserved.
          </p>
          <p className="text-white p-5 lh-14">
            <span className={`${isOnline?'green':'red'} fas fa-wifi font-14 m-r-5`}/>
            {geoData ? <span className="font-12">Access location: {geoData.county} - {geoData.country}.</span> : null}
          </p>
          <p className="text-white lh-14">
            <Link className="font-12 white m-r-15" target="_blank" to="/about_us/terms_of_service">Terms&nbsp;</Link> 
            <Link className="font-12 white m-r-15" target="_blank" to="/about_us/privacy_policy">&nbsp;Privacy</Link>
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
