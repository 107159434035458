import React, { Fragment, useState, useContext } from 'react'
import useStyle from '../../hooks/useStyle'
import useUserAgent from '../../hooks/useUserAgent'
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider"
import OrganizationMemberService from '../Organizations/Members/OrganizationMemberService'

let addToScreenPrompt = null;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  addToScreenPrompt = e;
})

const AddToHomeScreen = (props) => {
  useStyle('snackbar')

  const [isOpen, setIsOpen] = useState(true)
  const {isIos, isInStandaloneMode} = useUserAgent()

  if (!addToScreenPrompt) {
    return null
  }

  if(!isOpen) return null;

  /*if (nativeApp && doesVersionSendPushMessages(nativeApp.version)) {
    // There’s an installed native app that handles sending push messages.
    // No need to do anything.
    return;
  }*/

  const addToHome = () =>{
    // Show the prompt
    if(addToScreenPrompt){
      addToScreenPrompt.prompt();
      // Wait for the user to respond to the prompt
      addToScreenPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
          setIsOpen(false);
        }
        addToScreenPrompt = null;
      })
    }
  }

  // Checks if should display install popup notification:
  const InstallMessage = () => {
    if (isIos() && !isInStandaloneMode()) {
     return (
      <div className="font-15">Install this webapp on your iPhone<br/> <img className="w-30 pull-left" src="images/add_to_home.png"/> tap and then Add to homescreen.</div>
     )
    }else{
      return (
        <div className="add-to-home" onClick={e=>addToHome(e)}>ADD TO HOME SCREEN</div>
      )
    }
  }

  return (
    <div id="snackbar" className="show">
      <span onClick={e=>setIsOpen(false)} className="ml-button font-20 ml-hover-red ml-display-topright color-red2-dark" title="Close Modal">
        <i className="fa fa-times" aria-hidden="true"></i>
      </span>
      <div className="col-xs-3">
        <img src="images/logo/apple-touch-icon-57x57.png" alt="logo"/>
      </div>
      <div className="col-xs-9">
        <h4 className="green">cueTree Health</h4>
      </div>
      <div className="col-xs-12">
        <InstallMessage/>
      </div>
    </div>
  )

}

const AppDownload = ({label}) =>{
  const {isIos, isPwaMode} = useUserAgent()
  const { state: { user: currentUser }, dispatch} = useContext(AuthContext)

  const create = async () => {
    const req = {
      'organization_id': currentUser.current_organization_id,
      'email': currentUser.email,
      'mobile': currentUser.phone_no
    }
    await OrganizationMemberService.isValidMember(req)
  }

  if(!isIos() && !isPwaMode()){
    return(
      <div onClick={()=>create()} className="m-b-15" data-tip="Download App">
        <i className="fas fa-file-download w-30 color-blue2-dark" />
        <span>{label}</span>
      </div>
    )
  }

  return null;
}

export {AddToHomeScreen, AppDownload};