import React, {Fragment, useState, useEffect, useContext, useMemo} from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../services/CommonService"
import {PillDsField, PillDsList, PillTagList, PillList} from "../../../Common/NotificationPill"
import ReactTooltip from "react-tooltip"
import {ImageList, ImagePlayList} from "../../../Common/Image"
import env from "../../../../env"
import { CTTooltip } from "../../../Common/CueTooltip"
import { ChatGrayIcon, CheckListGrayIcon, HamburgerMenuIcon, MaximizeIcon, LinkFontAwesomeIcon } from "../../../Common/ImageIcons"
import {VideoPlayer} from "../../../Common/VideoPlayer"
import useModal from "../../../../hooks/useModal"
import GenericModal from '../../../Modals/GenericModal'
import {MediaListBtn} from '../../../MediaFiles/MediaList'
import {LocaleLabel, LocaleLabelList} from "../../../Common/FormInput"
import {DataSourceMapperIcon} from "../../../FormBuilder/MapperDataSourceListModal"
import {getLocale} from "../../../FormBuilder/FieldLabel"
import {tabMoreIcon} from "../../../Common/Button"
import Tabs, { TabPane } from "rc-tabs"
import { DivRichTextView, SpanRichTextView } from "../../../Common/RichTextView"

const Counseling = (props) => {
  let {counseling, update, index, stepPlanners, currentUser, permission} = props;
  
  const [planner, setPlanner] = useState();
  const { isOpen: isVideoPlayerOpen, toggleModal: toggleVideoPlayer } = useModal();

  if (!counseling.data) return null;

  const openVideo = (item, item_type) =>{
    if(item_type === 'step_planner' && item.data.url){
      const params = new URL(item.data.url).searchParams;
      item.data.video_url = `${env.youtube_url}${params.get('v')}`;
      const startSec = Cs.getSeconds(item.data.start_time);
      const endSec = Cs.getSeconds(item.data.end_time);
      item.data.video_url = `${item.data.video_url}?start=${startSec}&end=${endSec}`;
      setPlanner(item);
      toggleVideoPlayer();
    }/*else if(item.planner_fk_id){
      setPlanner(item)
      toggleVideoPlayer() 
    }*/
  }

  const addImageToCounseling = (file_upload) =>{
    counseling.data.image_upload = counseling.data.image_upload || []
    counseling.data.image_upload.push(file_upload.file)
    const req = {
      id: counseling.id,
      data: counseling.data
    }
    update(req, index)
  }

  return (
    <>
      <CounselingCard counseling={counseling} stepPlanners={stepPlanners}
      cardHeightCss={props.cardHeightCss} showMenu={props.showMenu}
      addNotes={props.addNotes} addChecklist={props.addChecklist}
      openMapper={props.openMapper} currentUser={props.currentUser}
      onPlannerSelect={openVideo} addImageToCounseling={addImageToCounseling}
      currentUser={currentUser} permission={permission}/>

      {isVideoPlayerOpen && 
        <GenericModal component={VideoPlayer}
          isOpen={isVideoPlayerOpen} 
          toggleModal={toggleVideoPlayer}
          title="Video Player"
          url={planner.data.video_url} 
          widthCss="w-100p"/>
      }

      {/*isVideoPlayerOpen && 
        <PlannerVideoPlayer 
          toggleModal={toggleVideoPlayer}
          plannerId={planner.planner_fk_id}/>*/
      }
    </>  
  )  
}

const Footer = ({currentUser, permission, counseling, addNotes, addChecklist, openMapper, showMenu, addImageToCounseling}) => {
  return(
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25" data-tip="Chat">
          <ChatGrayIcon onSelect={(e) => addNotes(e, counseling)} count={counseling.comments_count || 0}/>
        </li>
        <li className="m-r-25" data-tip="Checklist">
          <CheckListGrayIcon onSelect={(e) => addChecklist(e, counseling)} count={counseling.check_list_count || 0}/>
        </li>
        {permission.write && 
          <li className="m-r-25" data-tip="Add Data Source Mapper">
            <DataSourceMapperIcon mappingType="infant_journey.counseling" mappingId={counseling.id}/>
          </li>
        }
        {permission.write &&
          <li className="m-r-25" data-tip="Add Image Source">
            <MediaListBtn fileableId={counseling.id} fileableType='counseling' 
            onFileCloneSuccess={addImageToCounseling}/>
          </li>
        }
      </ul>
      <HamburgerMenuIcon onSelect={(e)=>showMenu(e, counseling)}/>
    </div>
  )
}

const UpDown = ({activeIndex, currentIndex}) =>(
  <i className={`fas ${activeIndex === currentIndex ? "fa-chevron-up" : "fa-chevron-down"} black pull-right m-r-10`}/>
)

const PanelSm = ({title, setActiveIndex, currentIndex, activeIndex, extraClass}) => (
  <div className={extraClass}>
    <div className={`black p-5 font-15 br-grey m-b-10 m-t-10 br-l1`}
      onClick={(e) => setActiveIndex(currentIndex)}>
        {title}
      <UpDown currentIndex={currentIndex} activeIndex={activeIndex} />
    </div>
  </div>
) 

const Description = ({text, activeIndex, currentIndex}) =>{
  if(text && activeIndex === currentIndex){
    return(
      <div className="popup-menu-grid br-grey">
        <div className="ver-icons">
          <div className="black-light-1">
            <DivRichTextView className="ws-inl" html={text}/>
          </div>
        </div>
      </div>
    )  
  }else{
    return null
  }
}

const CounselingPanelList = ({data, onPlannerSelect, stepPlanners}) =>{
  const [index, setIndex] = useState(null)

  const setActiveIndex = (i) => {
    setIndex(i === index ? null : i)
  }

  return(
    <Fragment>
      <div className="border-dashed-sm p-b-20 text-center">
        <ImagePlayList list={data.image_upload} loading="lazy" styleName="img-responsive ht-150"/>
      </div>
      <div className="accordion-main">
      <div className={`accordion-item ${index === 0 ? 'accordion-open' :''}`}>
        <div className="accordion-title flex aligncenter justspacebetween" onClick={(e) => setActiveIndex(0)}>
          <div className="flex aligncenter">
            <h5>Planners</h5>
          </div>
          <div className="accordion-arrow">
            <img src="./images/arrow-right-accordion.png" alt="" />
          </div>
        </div>
        <div className="accordion-body">
          {data.planners_array?.map((p, i)=>
            <div key={i}>
              <span className="badge bg-black-light-1 text-white d-block m-b-10">
                <i className="fas fa-external-link-alt m-r-5"/> 
                {p.name} 
              </span>
              {p.step_planners?.length > 0 && stepPlanners ? p.step_planners.map((s, k) => 
                <span key={k} 
                  onClick={()=>onPlannerSelect(stepPlanners[s], 'step_planner')} 
                  className="badge bg-black-light-1 text-white d-block m-r-15 m-b-10">
                    <i className="fas fa-external-link-alt m-r-5"/> 
                    {stepPlanners[s]?.data?.description} 
                </span>
              ):null}
            </div>
          )}
        </div>
      </div>

      <div className={`accordion-item ${index === 1 ? 'accordion-open' :''}`}>
        <div className="accordion-title flex aligncenter justspacebetween" onClick={(e) => setActiveIndex(1)}>
          <div className="flex aligncenter">
            <h5>The suggested method</h5>
          </div>
          <div className="accordion-arrow">
            <img src="./images/arrow-right-accordion.png" alt="" />
          </div>
        </div>
        <div className="accordion-body">
          <SpanRichTextView className="pre-wrap rich-text" html={data.description}></SpanRichTextView>
        </div>
      </div>

      <div className={`accordion-item ${index === 2 ? 'accordion-open' :''}`}>
        <div className="accordion-title flex aligncenter justspacebetween" onClick={(e) => setActiveIndex(2)}>
          <div className="flex aligncenter">
            <h5>Methods to avoid</h5>
          </div>
          <div className="accordion-arrow">
            <img src="./images/arrow-right-accordion.png" alt="" />
          </div>
        </div>
        <div className="accordion-body">
          <SpanRichTextView className="pre-wrap rich-text" html={data.avoid}></SpanRichTextView>
        </div>
      </div>

      <div className={`accordion-item ${index === 3 ? 'accordion-open' :''}`}>
        <div className="accordion-title flex aligncenter justspacebetween" onClick={(e) => setActiveIndex(3)}>
          <div className="flex aligncenter">
            <h5>Important details</h5>
          </div>
          <div className="accordion-arrow">
            <img src="./images/arrow-right-accordion.png" alt="" />
          </div>
        </div>
        <div className="accordion-body">
          <SpanRichTextView className="pre-wrap rich-text" html={data.details}></SpanRichTextView>
        </div>
      </div>
      
      <div className={`accordion-item ${index === 4 ? 'accordion-open' :''}`}>
        <div className="accordion-title flex aligncenter justspacebetween" onClick={(e) => setActiveIndex(4)}>
          <div className="flex aligncenter">
            <h5>Points that each mother should know</h5>
          </div>
          <div className="accordion-arrow">
            <img src="./images/arrow-right-accordion.png" alt="" />
          </div>
        </div>
        <div className="accordion-body">
          <SpanRichTextView className="pre-wrap rich-text" html={data.tips}></SpanRichTextView>
        </div>
      </div> 
      </div>
    </Fragment>
  )
}

const TabDescription = ({description}) =>{
  if(!description)
    return null

  return(
    <div className="accordion-item accordion-open">
      <div className="accordion-body">
        <SpanRichTextView html={description}/>
      </div>
    </div>
  )
}

const TabEle = ({tab, currentLocale, iconClass}) => {
    const tabLocale = useMemo(() => {
      return{
        'label' : getLocale(tab, currentLocale),
      }
    }, []);

  return(
    <span>
      {iconClass ? <i className={`${iconClass} m-r-5`}/>:null}
      {tabLocale.label}
    </span>
  )
}

const CounselingPanelTab = ({data, formFields, currentLocale}) =>{
  return(
    <div className="accordion-main">
      <Tabs defaultActiveKey="1" moreIcon={tabMoreIcon}>
        <TabPane tab={<TabEle tab={formFields.avoid} iconClass="fas fa-times-circle" currentLocale={currentLocale}/>} key="1">
          <TabDescription description={data.avoid}/>              
        </TabPane>

        <TabPane tab={<TabEle tab={formFields.description} iconClass="fas fa-check-circle" currentLocale={currentLocale}/>} key="2">
          <TabDescription description={data.description}/>               
        </TabPane>

        <TabPane tab={<TabEle tab={formFields.details} iconClass="fab fa-wpforms" currentLocale={currentLocale}/>} key="3">
          <TabDescription description={data.details}/>         
        </TabPane>

        <TabPane tab={<TabEle tab={formFields.tips} iconClass="fas fa-baby" currentLocale={currentLocale}/>} key="4">
          <TabDescription description={data.tips}/>     
        </TabPane>       
      </Tabs>
    </div>
  )
}

const MotherInfo = ({data}) =>{
  return(
    <> 
      <h5 className="f16 m-b-10">Video</h5>
      <div className="border-dashed-sm m-b-10">
        <ImagePlayList list={data.image_upload} styleName="center-block w-250 img-responsive"/>
      </div>
      <p className="f14 m-b-10 m-t-5">Details</p>
      <ol className="m-t-15 card-numbers border-dashed-sm p-b-15 p-l-15">
        <DivRichTextView className="ws-inl m-t-10" html={data.tips}/>
      </ol>
    </>
  )
}

const CounselingCard = ({onPlannerSelect, permission, counseling, cardHeightCss, addNotes, addChecklist, openMapper, showMenu, currentUser, addImageToCounseling, stepPlanners}) => {
  let data = counseling.data || {}
  let ds = counseling.data_source || {}
  counseling.updated_at = Cs.formatUpdateDate(counseling.updated_at)

  const cardStyle = {
    "backgroundColor": "#607D8B",
  }

  return (
    <Fragment>
      <div className="bg-white brd-10 card-shadow m-b-15">
        <div className="card-title card-title-gray flex p-b-15 p20 coldir">
          <h4 className="fw700 f18 card-heading flex coldir text-left">
            <LocaleLabel dataSource={ds.stage} currentLocale={currentUser.current_locale}/> 
              - 
            <LocaleLabel dataSource={ds.language} currentLocale={currentUser.current_locale}/>
          </h4>
          <div className="f14 fw500">
            {ds.symptoms ?
              <LocaleLabelList list={ds.symptoms} currentLocale={currentUser.current_locale}/>
              :
              <LocaleLabel dataSource={ds.step} currentLocale={currentUser.current_locale}/>
            }
          </div>
          <div className="f14 m-t-10 fw500">{counseling.author?.name} &nbsp;-&nbsp; {Cs.formatUpdateDate(counseling.updated_at)}</div>
        </div>
        <div className="card-content p20 ht-420 scroll-auto">
          <CounselingPanelList data={data} onPlannerSelect={onPlannerSelect} stepPlanners={stepPlanners}/>
        </div>
        
        <Footer {...{counseling, permission, addNotes, addChecklist, openMapper, showMenu, currentUser, addImageToCounseling}}/>
      </div>
      <CTTooltip />
    </Fragment>
  )
}

export {Counseling, CounselingPanelList, CounselingPanelTab, MotherInfo};