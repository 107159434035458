import React, {useState, Fragment, useContext, useEffect} from 'react';
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import MemberJourneyList from "../../Organizations/Members/MemberJourneyList";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import {NavIcon} from '../../Common/MenuItem';
import {usePageLocale} from '../../../hooks/useLocale';
import {DataSourceMenu} from "../../Modals/DataSourceMenu";
import Cs from "../../../services/CommonService";

const PreTrainingCaseList = (props) => {
   
  const { state: {user: currentUser}} = useContext(AuthContext);
  
  const { isOpen: isJourneyMenuOpen, toggleModal: toggleJourneyMenuModal } = useModal();

  const { labelLocale } = usePageLocale(currentUser.current_locale, 'journeyListPage');

  const routeJourneyForm = (ds) =>{
    Cs.routeJourneyForm(ds, props.history, null, true);
  }

  return (
    <div id="ct">
      <div className="app-content">
        <div className="flex justspacebetween m-t-10">
          <div className="app-title m-r-20">Pre-Training Cases</div>
          <div className="flex aligncenter rgap-5-xs">
            <NavIcon id="add_new_journey" 
              label="Launch a new health journey"  
              dataTip={labelLocale(3)} 
              onSelect={toggleJourneyMenuModal}
              className="bg-highlight round-btn float-right m-r-5" 
              localeId="3"
              icon="fas fa-plus"/>
          </div>
        </div>
      
        <div className="app-content-section m-t-30">
          <MemberJourneyList 
            pageId="PreTrainingCaseList"
            isRouteEnabled={false}
            toggleJourneyMenuModal={toggleJourneyMenuModal}
            params={{
              'organization_id':currentUser.current_organization_id, 
              'medical_facility_id':currentUser.current_medical_facility_id
            }} 
            member={currentUser.current_member} /> 
        </div>
      </div>

      {isJourneyMenuOpen && 
        <DataSourceMenu toggleModal={toggleJourneyMenuModal} 
          datasourceList={currentUser.current_subscription?.journeys}
          dataSourceId={3419} onSelect={routeJourneyForm}/>
      }
    </div>
  )

}

export default PreTrainingCaseList;