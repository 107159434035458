import React, {useState, Fragment, useContext, useEffect, useMemo, useRef} from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import Pagination from '../../Common/Pagination';
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';
import useModal from "../../../hooks/useModal";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../services/CommonService";
import {AddLocale} from "../../Locale/AddLocale";
import GenericModal from "../../../components/Modals/GenericModal";
import env from "../../../env";
import CheckAccess from '../../Roles/CheckAccess'; 
import {FontAwesomeLink} from "../../Common/Button";
import {useSubscriptionAccess} from '../../Subscriptions/useSubscription';
import FormTestInputReviewService from './FormTestInputReviewService';
import useSort from '../../../hooks/useSorting';
import PaginationSM from "../../Common/PaginationSM";
import DynamicTable from '../../Common/DynamicTable';
import ReviewForm from './ReviewForm';

const useFormTestReview = () => {
  const {isOpen:isTestInputReviewFormOpen, toggleModal:toggleTestInputReviewForm} = useModal();

  const checkTestInputReviewNeeded = (fromData, fallback) =>{
    if(fromData.test_input){
      toggleTestInputReviewForm()
    }else if(fallback){
      fallback()
    }
  }

  return {
    checkTestInputReviewNeeded,
    isTestInputReviewFormOpen,
    toggleTestInputReviewForm,
  }
};

const FormTestInputReviewFormPopup = ({isOpen, toggleModal, testInputId, itemId, itemType, onclose}) => {
  const formId = 622;
  let {current:scope} = useRef({});

  scope.formProps = {
    'isPopupMode':true,
    'match':{
      'params':{
        'formId':formId,
        'id':testInputId,
        'item_id':itemId,
        'item_type':itemType
      }
    }
  }

  const onSubmit = () =>{
    onclose()
  }

  if(isOpen){
    return <GenericModal 
      component={ReviewForm} 
      isOpen={isOpen} 
      toggleModal={toggleModal}
      onSubmit={onSubmit}
      {...scope.formProps}/>
  }

  return null
  
}

export {useFormTestReview, FormTestInputReviewFormPopup}