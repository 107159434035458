import React, {useState, Fragment, useContext, useEffect, useMemo, useRef} from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import Pagination from '../Common/Pagination';
import {Link} from "react-router-dom";
import useStyle from '../../hooks/useStyle';
import useModal from "../../hooks/useModal";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Cs from "../../services/CommonService";
import GenericModal from "../../components/Modals/GenericModal";
import {OptionsObjList} from  "../Common/FormInput";
import CheckAccess from '../Roles/CheckAccess'; 
import { CTTooltip } from "../Common/CueTooltip";
import {usePageTitle} from '../../hooks/pageHead';
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView";
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons";
import {FontAwesomeLink} from "../Common/Button";
import {useSubscriptionAccess} from '../Subscriptions/useSubscription';
import {NavIcon} from '../Common/MenuItem';
import TestInputForm from './TestInputForm';
import {useFormTestInput} from './useFormTestInputHook';
import DynamicTable from '../Common/DynamicTable';
import PaginationSM from "../Common/PaginationSM";

const FomTestInputList = ({formId, currentUser, onTestInputDataSelect, toggleModal}) =>{
    
    const {
      requestParams, loading, sort, currentpage, setCurrentpage,
      testDataList, setTestDataList, pagemeta, setPagemeta, 
      getFormTestInputList, formFields
    } = useFormTestInput(formId);

    useEffect(() => {
      getFormTestInputList()
    }, [])

    const IdEle = (client_id, data, row) => <span>{row.record_id}</span>;
    const DescriptionEle = (client_id, data, row) => <span>{row.description}</span>;
    
    const specialFields = [
      {"label":"ID", "ele":IdEle},
      {"label":"Description", "ele":DescriptionEle}
    ]

    const OptionsEle = ({item}) => (
      <td className="text-center flex coldir">
        <span className="far fa-play-circle f18 text-muted pointer" onClick={()=>onTestInputDataSelect(item, toggleModal)}/>
      </td>
    )

    return(
      <div id="ct">
        <div className="app-content-section">
          {loading ?
            <div className="spinner"/>
            :
            <div className="table-responsive table-fix-head">      
              <DynamicTable 
                requestParams={requestParams} 
                formDataList={testDataList} 
                specialFields={specialFields}
                onSort={sort} 
                tClass="table" 
                currentLocale={currentUser.current_locale}
                OptionsEle={OptionsEle}
                isSortable={false}/>

              <PaginationSM
                className="pagination-bar"
                currentPage={currentpage}
                totalItems={pagemeta.total}
                itemsPerPage={pagemeta.per_page}
                onPageChange={setCurrentpage}
                siblingCount={2}/>
            </div>
          }
        </div>  
      </div>
    )
}        

const FormTestInputIcon = (props) =>{

  const { isOpen, toggleModal } = useModal();

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(props.currentUser, 'form_test_inputs')
  , [props.currentUser.id])

  if(permission?.verify){
    return(
      <>
        <span className={props.className}  onClick={()=>toggleModal()}>
          <i className="fas fa-clipboard-check text-muted-2 f18"/>
        </span>

        {isOpen && 
          <GenericModal component={FomTestInputList} 
            toggleModal={toggleModal} 
            title="Example entries for testing use"
            {...props}/>
        }
      </>
    )
  }

  return null
}

export {FormTestInputIcon};