const devEmails = [
  'author@ipipal.com', 
  'skasi@ipipal.com', 
  'jaibharathi1991@gmail.com'
]

//Production
export default {
  health_backend: 'https://www.api.cuedwell.com/',
  backend: 'https://beta.cuetree.com/',
  infant_backend: 'https://www.api.cuedwell.com/infant/',
  socket:'wss://www.api.cuedwell.com',
  youtube_url:'https://www.youtube.com/embed/',
  file_url:'https://www.api.cuedwell.com/health/files?url=',
  s3_public:'https://selftree-public.s3.us-west-2.amazonaws.com/uploads/health_file_upload/',
  profile_image_url:'https://cuedwell.s3.ap-south-1.amazonaws.com/uploads/user/profile_image/',
  admin_emails:devEmails,
  google_sso:{
    client_id: "230889789246-b01vb51lad11faggvlgur4hfmkbhpdof.apps.googleusercontent.com"
  }
}

//Test
/*export default {
  health_backend_local: 'https://demo.api.cuedwell.com/',
  health_backend: 'https://demo.api.cuedwell.com/',
  backend: 'https://beta.cuetree.com/',
  infant_backend: 'https://demo.api.cuedwell.com/infant/',
  socket:'wss://demo.api.cuedwell.com',
  youtube_url:'https://www.youtube.com/embed/',
  file_url:'https://demo.api.cuedwell.com/health/files?url=',
  profile_image_url:'https://cuedwell.s3.ap-south-1.amazonaws.com/uploads/user/profile_image/',
  s3_public:'https://selftree-public.s3.us-west-2.amazonaws.com/uploads/health_file_upload/',
  admin_emails:devEmails,
  google_sso:{
    client_id: "230889789246-b01vb51lad11faggvlgur4hfmkbhpdof.apps.googleusercontent.com"
  }
  //google_sso:{
    //client_id: "313393603854-na39sfneapvb20ldg3qv5njuej3qnn5j.apps.googleusercontent.com"
  //}
}*/

//Local
/*export default {
  health_backend:'http://127.0.0.1:9000/',
  backend:'https://beta.cuetree.com/',
  infant_backend:'http://127.0.0.1:9000/infant/',
  socket:'ws://127.0.0.1:9000',
  file_url:'http://127.0.0.1:9000/health/files?url=',
  youtube_url:'https://www.youtube.com/embed/',
  profile_image_url:'https://cuedwell.s3.ap-south-1.amazonaws.com/uploads/user/profile_image/',
  s3_public:'https://selftree-public.s3.us-west-2.amazonaws.com/uploads/health_file_upload/',
  admin_emails:devEmails,
  google_sso:{
    client_id: "230889789246-b01vb51lad11faggvlgur4hfmkbhpdof.apps.googleusercontent.com"
  }
}*/