import React, {useContext} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../../../services/CommonService";

const Child = (props) => {
  const { state: authState, dispatch } = useContext(AuthContext)

  let activity = props.activity;
  activity.updated_at = CommonService.formatUpdateDate(activity.updated_at)
  
  let data = activity.data;
  let ds = props.dataSource;
  data.baby_age = CommonService.getAge(data.dob_baby_formatted_date)

  const cardStyle = {
    backgroundColor: data.background_color || data.header_background || "#607D8B",
  }

  if (!data) return null;

  let userRoleLink = {
    pathname: `/journey/user_role/${props.activity.journey_category_id}/${props.activity.education_journey_profile_id}/edu_team_journey.activity/${props.activity.id}`,
    state: { journey: props.journeyCategory, activity: props.activity },
  }

  const MailTag = () => (
    <a href={`mailto: ${activity.id}-team_activity@reply.cuetree.com?subject=Education Child-${data.name}-${activity.record_id}`}
      target="_blank">
      <i className="fas fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle} onClick={(e) => props.routeChild(activity)}>
      <div className="font-16">
        {data.name}
        <i className="fas fa-expand pull-right white expand"></i>
      </div>
      <div className="font-16">
        {activity.updated_at}
        <Link className="pull-right white expand t-0" onClick={(e) => e.stopPropagation()}
          to={userRoleLink}>
          <i className={`fas ${activity.user_roles == null ? "fa-lock" : "fa-unlock"} `}></i>
        </Link>
      </div>
    </div>
  )

  const Footer = () => {
    return(
    <div className="card-footer">
      <div className="pull-left">
        <span className="m-l-15">
          {activity.activity_form_id} - {activity.record_id}
        </span>
        <span className="m-l-25" onClick={(e) => props.addNotes(e, activity)}>
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">{activity.comments_count || 0}</span>
        </span>
        <span className="m-l-25" onClick={(e) => props.addChecklist(e, activity)}>
          <i className="far fa-check-circle font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">{activity.check_list_count || 0}</span>
        </span>
        <span className="m-l-25">
          <Link to={`/health/incoming/emails/treatment/${activity.id}`}>
            <i className="fas fa-envelope font-18 text-muted"></i>
            <span className="badge-2 up bg-lgrey">
              {activity.mail_count || 0}
            </span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  )}

  const CovidCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16">
            {data.name_baby}
            <i className="fas fa-expand pull-right white expand"></i>
          </div>
          <div className="font-16">
            {activity.updated_at}
          </div>
        </div>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <strong>Mother ID</strong>
                  <div className="m-r-15">REG_{data.mother_fk_id}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Date of birth</strong>
                  <div>{data.dob_baby}</div>
                </td>
                <td>
                  <strong>Age</strong>
                  <div>{data.baby_age} Month</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Height</strong>
                  <div>{data.birth_length}</div>
                </td>
                <td>
                  <strong>Weight</strong>
                  <div>{data.birth_weight}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Food</strong>
                  <div>{data.details_food_hospital}</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong>Note</strong>
                  <div>{data.notes_delivery}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer/>
      </div>
    )
  }

  return <CovidCard/>;

}

export default Child;
