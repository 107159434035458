import React, {Fragment, useState, useEffect, useContext, useMemo} from "react"
import GenericModal from '../Modals/GenericModal'
import AuthService from '../../services/AuthService'
import useModal from '../../hooks/useModal'
import env from "../../env"
import {CountryCodes} from "../../constants/CountryCodes"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {MCJGroupByPregnancyStage, MCJCaseScheduleAdherence, CaseActivityCountTable, MeasureGrowthZscoreChartByCase, McjOrgMemberDailyReport, MCJCaseScheduleReport} from '../Organizations/Members/ChartReports'
import Tabs, { TabPane } from "rc-tabs"
import CheckAccess from "../Roles/CheckAccess"
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider"
import {usePageLocale} from '../../hooks/useLocale'
import AddCaseAssessmentIco from "../HealthJourney/CaseAssessments/AddCaseAssessmentIco"
import OrganizationMemberService from "../Organizations/Members/OrganizationMemberService"

const UserInfoPopup = ({userId, organizationId, role}) => {
  const {state:{user:currentUser}, dispatch} = useContext(AuthContext);
  const {localeDispatch} = useContext(LocalizeContext);

  const [user, setUser] = useState({});
  const [reports, setReports] = useState({});
  const [loading, setLoading] = useState(true);

  const { labelLocale:tabLocale } = usePageLocale(currentUser.current_locale, 'userInfoTabs');
  const { labelLocale:labelLocale } = usePageLocale(currentUser.current_locale, 'userInfoLabels');

  const isRoleTypePermitted = useMemo(() => 
    CheckAccess.getRoleType(currentUser), [currentUser]
  )
  const orgId = organizationId || currentUser.current_organization_id;

  useEffect(()=>{
    getUser();
  }, [])

  const getUser = async () =>{
    //move tabs 2,3,4 to health/organization_member/report/charts
    const {status, data} =  await AuthService.getUserInfoById(
      userId, 
      {
        'page_locale':{
          'data_source_ids':[4000, 4004],
          'locale':currentUser.current_locale,
        }
      }
    );
    let ext = CountryCodes[currentUser.current_organization.data?.country_code?.toLowerCase()];
    ext = ext?ext[2]:'';
    setUser({
      ...data.user, 
      'tel':`tel:+${ext}${data.user.phone_no}`,
      'mailto':`mailto:${data.user.email}`,
      'whatsapp':`https://wa.me/+${ext}${data.user.phone_no}?text=hi`
    });
    setReports(data);
    localeDispatch({
      'type': "UPDATE",
      'payload': {
        'userInfoTabs':(data.page_locale?.user_info_tabs),
        'userInfoLabels':(data.page_locale?.user_info_common_labels)
      }
    });
    setLoading(false);
  }

  const updateFavorite = async () =>{
    if(isRoleTypePermitted){
      const isFavorite = !currentUser.current_member.is_favorite;
      const {status} = await OrganizationMemberService.update({
        'id':currentUser.current_member.id,
        'context':'open',
        'is_favorite':isFavorite
      })
      if(status === 204){
        currentUser.current_member.is_favorite = isFavorite;
        dispatch({
          type: "UPDATE",
          payload: {'user': currentUser},
        })
      }
    }
  }

  return(
    <div className="p-t-40-xss">
      <Tabs moreIcon="+">
        <TabPane tab={user.name || tabLocale(1)} key="0">
          <div className="flex justspacebetween aligncenter p-l-15 p-r-15">
            {loading?<div className="spinner"/>:null}
            <div>
              <h4>{user.name}</h4>
              <div>{role?.role}</div>
              <div className="flex font-24 m-t-4">
                {user.phone_no?<a href={user.tel} className="fas fa-mobile-alt m-r-20"></a>:null}
                {user.email?<a href={user.mailto} className="fas fa-envelope m-r-20"></a>:null}
                {user.phone_no?<a href={user.whatsapp} target="_blank" className="fab fa-whatsapp-square"></a>:null}
                <AddCaseAssessmentIco className="m-l-15 highlight" assessUserId={userId} assessmentData={{'assessment_types':'3', 'assessment_types_array':[3]}}>
                  <span className="far fa-list-alt"/>
                </AddCaseAssessmentIco>
                {currentUser.current_member
                  ? <span className="font-20 m-l-15">
                      <i className={`${currentUser.current_member.is_favorite ? 'fas green' : 'far'} fa-star`} onClick={()=>updateFavorite()}/>
                    </span>
                  : null
                }
              </div>
            </div>
            <div>
              <span className="flex aligncenter m-r-30 m-r-22-xs user-dropdown-xs">
                <img className="user-md flex" src={user.profile_image?env.file_url+user.profile_image:'../images/user-demo-icon.png'}/>
              </span>
            </div>
          </div>
        </TabPane>

        {(isRoleTypePermitted || (userId === currentUser.id)) ?
          <>
            <TabPane tab={tabLocale(2)} key="1">
              {loading?null:<MCJGroupByPregnancyStage userId={userId} organizationId={orgId}/>}
            </TabPane>

            <TabPane tab="Actions" key="2">
              {loading?null:<MCJCaseScheduleReport userId={userId} organizationId={orgId}/>}
            </TabPane>

            <TabPane tab={tabLocale(3)} key="3">
              {loading?null:<MCJCaseScheduleAdherence userId={userId} organizationId={orgId}/>}
            </TabPane>

            <TabPane tab={tabLocale(4)} key="4">
              {loading?null:<CaseActivityCountTable userId={userId} mcjComponents={reports.mcj_components} organizationId={orgId}/>}
            </TabPane>

            <TabPane tab={tabLocale(5)} key="5">
              {loading?null:<MeasureGrowthZscoreChartByCase userId={userId} organizationId={orgId}/>}
            </TabPane>

            <TabPane tab="Daily Report" key="6">
              {loading?null:<McjOrgMemberDailyReport userId={userId} organizationId={orgId}/>}
            </TabPane>
          </>
          :
          null
        }
      </Tabs>  
      <div className="row">
        <small className="m-l-5 lgrey d-block">
          <i className="m-r-5 far fa-copyright" aria-hidden="true"/>
          {new Date().getFullYear()} IPIPAL Inc. All rights reserved.
        </small>
      </div>
    </div>
  )    
}

const UserName = ({id, orgId, className, role, children}) =>{
  const {state:{user:currentUser}} = useContext(AuthContext)
  const [isOpen, setIsOpen] = useState()

  if(!id)
    return null

  const userPopupEnabled = CheckAccess.checkOrgLevelAccess(currentUser, 'user_info_popup')

  if(userPopupEnabled){
    return(
      <>
        <div onClick={(e)=>{e.stopPropagation();setIsOpen(true)}} className={`uline-hov ${className}`}>
          {children}
        </div>
        {isOpen &&
          <GenericModal component={UserInfoPopup} toggleModal={()=>setIsOpen(false)} 
          userId={id} organizationId={orgId} role={role}/>
        }
      </>
    )
  }else{
    return <>{children}</>
  }
  
}

export {UserName}