import React ,{Fragment, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../../../services/CommonService";
import {FormFieldCard, RenderData} from "../../../../Common/FormFieldsView";
import CheckAccess from '../../../../Roles/CheckAccess';
import {DraftBadge} from "../../../../Common/NotificationPill";
import {FontAwesomeBtn} from "../../../../Common/Button";
import { LinkFontAwesomeIcon, ChatGrayIcon } from "../../../../Common/ImageIcons";
import useStyle from '../../../../../hooks/useStyle'

const GenericActivityCard = (props) => {
  useStyle('/css/tile_components.css', true)

  const {caseId, sid, formFields, activity, componentName, caseName, currentUser, routeActivityForm, toggleModal, deleteConfirm, isReviewEnabled, enableScroll=true} = props;
  
  return (
    <div id="ct">
      <div className="bg-white brd-10 card-shadow m-b-15">
        <Header activity={activity} caseName={caseName}/>
        <div className="card-content p20">
          <div className={"b-tab-table active "+(enableScroll?'scroll-auto ht-400':'')}>
            <div className="p-b-15 m-b-15 m-t-15">
              <FormFieldCard formFields={formFields} 
                dataSource={activity.data_source || {}}
                data={activity.data || {}}/>
            </div>
          </div>
        </div>
        <Footer {...{sid, caseId, caseName, activity, currentUser, routeActivityForm, toggleModal, deleteConfirm}}/>
      </div>
    </div>
  )
}

const Header = ({activity, caseName}) => (
  <div className="card-title card-title-gray flex p-b-15 p20 coldir">
    <h4 className="fw700 f18 card-heading flex coldir text-left">
      {caseName}
    </h4>
    <div className="f14 m-t-10 fw500">
      {activity.author_name} 
      &nbsp;-&nbsp; 
      {Cs.formatUpdateDate(activity.updated_at)}
      <DraftBadge isDraft={activity.draft}/>
    </div>
  </div>
)

const Footer = ({caseId, caseName, activity, currentUser, routeActivityForm, toggleModal, deleteConfirm, sid}) => {
  return(
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25">{activity.record_id}</li>
        {CheckAccess.hasMcjActivityEditAccess(currentUser, activity, sid) ?
          <>
            <li className="m-r-25">
              <LinkFontAwesomeIcon 
                tooltip="Edit"
                iconCss="fas fa-edit white"
                onSelect={e=>{routeActivityForm(e, activity.id);toggleModal()}}/>
            </li>
            <li className="m-r-25">
              <LinkFontAwesomeIcon 
                tooltip="Delete"
                iconCss="far fa-trash-alt white"
                onSelect={() => deleteConfirm(activity)}/>
            </li>
          </>
          :
          null
        }
      </ul>
    </div>
  )
}

export default GenericActivityCard;