import React, {useState, Fragment, useContext, useEffect} from 'react';
import FilterSlider from "../Common/FilterSlider";
import {CheckBox} from "../Common/FormInput";
import useModal from '../../hooks/useModal';
import {useToggle, ToggleIco} from '../../hooks/useToggle';
import SortDirection from '../Common/SortDirection';
import useSort from '../../hooks/useSorting';
import FormFieldFilter from "../Common/FormFieldFilter";
import {ActiveStatusList, setDefaultLabel} from "../../constants/DataSourceList";
import Cs from "../../services/CommonService";
import RoleService from "../Roles/RoleService";
import FacilityService from '../HealthJourney/MedicalCareFacility/FacilityService';
import FormHelper from "../FormBuilder/FormHelpers";
import { CacheContext } from "../../contexts/Cache/CacheStateProvider";
import {useMedicalFacilityAutofillHook} from "../HealthJourney/MedicalCareFacility/useMedicalFacilityApi";

const Filters = ({openSideNav, filter, setFilter, preFilter, organizationId, requestParams, getList, pageViewSid}) =>{
  const {sort} = useSort(requestParams, getList);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const {toggleIdx, toggle:togglePan} = useToggle();

  const {
    getFilters,
    getTaluka,
    getVillages,
    getDepartmentPkId,
    getPhcList,
    getPhcTypes,
    talukas, setTalukas,
    phcTypes, setPhcTypes,
    dataSource, setDataSource,
    facilities, setFacilities,
    villages, setVillages
  } = useMedicalFacilityAutofillHook({filter, setLoading, organizationId})

  useEffect(()=>{
    getFilters();
  }, [])

  const StatusList = [
    {'label':'Approved/Joined', 'sid':'approved'},
    {'label':'Email Verified', 'sid':'email_verified'},
    {'label':'Email Verification sent', 'sid':'verification_email_sent'},
    {'label':'Not Self-Verified', 'sid':'email_not_verified'},
  ]
  
  const sortColumns = [
    {'label':'Role', 'sortColumn':'user_requests.data.role'},
    {'label':'PHC', 'sortColumn':'user_requests.data.phc_name'}
  ]

  let quizFilter = [
    {'label':'Test Not Taken', 'sid':'not_all_quiz'},
    {'label':'Test Taken', 'sid':'any_quiz'},
  ]

  if(pageViewSid === "trainee_list" || pageViewSid === "user_reg_request"){
    quizFilter = [
      ...quizFilter,
      {'label':'Not this session', 'sid':'not_this_session'},
      {'label':'Not this quiz', 'sid':'not_this_quiz'}
    ]
  }

  const duplicateUser = [
    {"label":"Show Duplicate users", "sid":true},
  ]

  const inactiveUser = [
    {"label":"Show Flagged Users", "sid":false},
  ]

  /*const getFilters = async () =>{
    setLoading(true);
    const req = {
      'organization_id':organizationId
    }
    Promise.all([
      Cs.getDataSource({'data_source_params':{'data_source_id':[4610, 4612, 4613, 4645]}})
    ]).then(function(responses){
      setDataSource(responses[0]);
      setLoading(false);
    }).catch(function(err){
      console.log(err);
    })
  }

  const getTaluka = async ()=>{
    if(!filter.department) return;
    const req = {
      'organization_id':organizationId,
      'department':filter.department
    }
    const {status, data} = await FacilityService.getTalukasAutofill(req);
    if(status === 200){
      setTalukas(data.map(t => {return {"taluka":t.taluka.split('/')?.[0]}}));
    }
  }*/

  if(!openSideNav) return null;
  
  //const getDepartmentPkId = (department) => dataSource.health_department_india?.options?.find(o=>o.value == department)?.pk_id;

  const getRoles = async ()=>{
    if(!filter.department) return;
    const req = {
      'organization_id':organizationId,
      'department_dsl_id':getDepartmentPkId(filter.department)
    }
    const {status, data} = await RoleService.getRoleAutofill(req);
    if(status === 200){
      setRoles(data);
    }
  }

  /*const getPhcList = async (filters={}) =>{
    setLoading(true);
    const {status, data:res} = await FacilityService.getPhcAutofill({
      'organization_id':organizationId,
      'department':filter.department, 
      ...filters 
    });
    setFacilities(res);
    setLoading(false);
  }

  const getPhcTypes = async (filters={}) =>{
    setLoading(true);
    const {status, data:res} = await FacilityService.getPhcTypesAutofill({
      'organization_id':organizationId,
      'department_dsl_id':getDepartmentPkId(filter.department)
    })
    setPhcTypes(res);
    setLoading(false);
  }*/

  const toggleFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      filter[name] = value;
      setFilter({...filter});
    }else{
      delete filter[name];
      if(name === 'quiz_taken'){
        delete filter.test_type;
      }
      setFilter({...filter});
    }
    if(name === 'department') {
      delete filter.talukas;
      delete filter.role_record_ids;
      delete filter.medical_facility_ids;
      delete filter.phc_type;
      delete filter.villages;
      if(preFilter?.talukas) filter.talukas = [...preFilter.talukas];
      if(preFilter?.role_record_ids) filter.role_record_ids = [...preFilter.role_record_ids];

      setRoles([]);
      setTalukas([]);
      setFacilities([]);
      setPhcTypes([]);
      setVillages([]);

      setFilter({...filter});

      getRoles();
      getTaluka();
      getPhcTypes();
    }
  }

  const filterExists = (value, name) => filter[name] == value;
  
  const toggleListFilter = (value, name) => {
    const setter = (list) => {
      if(list?.length === 0){
        delete filter[name]
        setFilter({...filter});
      }else{
        setFilter({...filter, [name]:list});
      }
      if(name === 'talukas'){
        if(filter.department == "2"){
          //ICDS
          getVillages({'talukas':list});
        }else{
          getPhcList({'talukas':list});
        }
      }
      if(name === 'villages')getPhcList({'talukas':filter.talukas, 'villages':list}); 
    }
    FormHelper.toggleListString(value, filter[name], setter);
  }

  const filterListExists = (value, name) => FormHelper.existsListString(value, filter[name]);

  const onInputUserRegIds = (value) =>{
    toggleFilter(value, "rids_txt");
  }

  return (
    <div className="filter-box-scroll p-5">
      {requestParams ?
        <div className="bg-white">
          <h5 className="font-16 text-center sub-title" onClick={()=>togglePan('sort')}>
            Sort
            <ToggleIco active={toggleIdx === 'sort'} className="pull-right"/>
          </h5>
          {toggleIdx === 'sort'
            ? <div>
                {sortColumns.map((c, i)=>
                  <div onClick={()=>sort(c.sortColumn)} className="pill white bg-lgrey" key={i}>
                    {c.label}     
                    <SortDirection sortColumn={requestParams.sort_column} column={c.sortColumn} 
                    reverse={requestParams.reverse}/>
                  </div>
                )}
              </div>
            : null
          }
        </div>
        :
        null
      }

      {loading ? <div className="spinner"/> : null}

      <div>
        {/*<div className="bg-white">
          <h5 className="font-16 text-center sub-title" onClick={()=>togglePan('status')}>
            Status
            <ToggleIco active={toggleIdx === 'status'} className="pull-right"/>
          </h5>
          {toggleIdx === 'status' 
            ? <CheckBox list={StatusList} labelKey="label" valueKey="sid" 
              toggle={toggleFilter} exists={filterExists} name="status"/> 
            : null
          }
        </div>*/}

        {pageViewSid === "user_reg_request" 
          ? <>
              <h5 className="font-16 text-center sub-title">
                Filter By Ids(seperated by newline) 
              </h5>
              <div className="m-b-15">
                <textarea 
                  defaultValue={filter.rids_txt}
                  onChange={e => onInputUserRegIds(e.target.value)}
                  className="form-input-gray textAreaht form-input form-input-gray form-input-full"/>
              </div>
            </>
          : null
        }

        {pageViewSid != "quiz_grading" ?
          <div className="bg-white">
            <h5 className="font-16 text-center sub-title">
              Quiz Taken
            </h5>
            <div className="mxh-250 scroll-auto">
              <CheckBox list={quizFilter || []} 
                labelKey="label" valueKey="sid" 
                toggle={toggleFilter} exists={filterExists} name="quiz_taken"/> 

              {filter.quiz_taken ? 
                <div className="m-t-15">
                  <CheckBox list={dataSource.mcj_iithst_type_tests?.options || []} 
                    labelKey="label" valueKey="value" 
                    toggle={toggleFilter} exists={filterExists} name="test_type"/> 
                </div>
                :
                null
              }
            </div>

          </div>
          :
          null
        }

        {pageViewSid != "quiz_grading" ?
          <div className="bg-white">
            <h5 className="font-16 text-center sub-title">
              Duplicate users
            </h5>
            <div className="mxh-250 scroll-auto">
              <CheckBox list={duplicateUser} 
                labelKey="label" valueKey="sid" 
                toggle={toggleFilter} exists={filterExists} name="duplicate_user"/>

              <CheckBox list={inactiveUser} 
                labelKey="label" valueKey="sid" 
                toggle={toggleFilter} exists={filterExists} name="is_active"/> 
            </div>
          </div>
          :
          null
        }

        <div className="bg-white">
          <h5 className="font-16 text-center sub-title">
            Department
          </h5>
          <div className="mxh-250 scroll-auto">
            <CheckBox list={dataSource.health_department_india?.options || []} 
              labelKey="label" valueKey="value" 
              toggle={toggleFilter} exists={filterExists} name="department"/> 
          </div>
        </div>

        <div className="bg-white">
          <h5 className="font-16 text-center sub-title">
            Roles
          </h5>
          <div className="mxh-250 scroll-auto">
            <CheckBox list={roles} 
              labelKey="role" valueKey="record_id" 
              toggle={toggleListFilter} exists={filterListExists} name="role_record_ids"/> 
          </div>
        </div>

        <div className="bg-white">
          <h5 className="font-16 text-center sub-title">
            Taluka/Block/Project
          </h5>

          <div className="mxh-250 scroll-auto">
            <CheckBox list={talukas} 
              labelKey="taluka" valueKey="taluka" 
              toggle={toggleListFilter} exists={filterListExists} name="talukas"/> 
          </div>
        </div>

        {filter.department == "2" ?
          <div className="bg-white">
            <h5 className="font-16 text-center sub-title">
              Sector/Village
            </h5>

            <div className="mxh-250 scroll-auto">
              <CheckBox list={villages} 
                labelKey="village" valueKey="village" 
                toggle={toggleListFilter} exists={filterListExists} name="villages"/> 
            </div>
          </div>
          : null
        }

        {/*<div className="bg-white">
          <h5 className="font-16 text-center sub-title">
            Type of center
          </h5>
          {filter.department!=null 
            ? <div className="mxh-250 scroll-auto">
                <CheckBox list={phcTypes} 
                  labelKey="label" valueKey="id" 
                  toggle={toggleFilter} exists={filterExists} name="phc_type"/>
              </div>
            : null 
          } 
        </div>*/}

        <div className="bg-white">
          <h5 className="font-16 text-center sub-title">
            Facilities/Centers
          </h5>

          <div className="mxh-250 scroll-auto">
            <CheckBox list={facilities} 
              labelKey="phc_name" valueKey="id" 
              toggle={toggleListFilter} exists={filterListExists} name="medical_facility_ids"/> 
          </div>
        </div>

      </div>
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const filterCount = Object.keys(props.filter).length
  
  return(
    <>
      <span data-tip="Filter" 
        className="bg-highlight round-btn float-right m-l-15" 
        onClick={()=>toggleFilterModal()}>
          <i className="fas fa-sliders-h"/>
          {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span>:''}
      </span>

      <FilterSlider component1={Filters} 
        openSideNav={isFilterOpen}
        setOpenSideNav={toggleFilterModal}
        {...props}/>
    </>
  )
}

export {Filters, FilterBtn};