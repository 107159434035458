import React, {useContext, Fragment} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import env from "../../env"
import DataSourceMapperModal from "./DataSourceMapperModal"
import useModal from "../../hooks/useModal";
import FormTemplateList from '../../constants/FormTemplateList';

let type = null
const DataSourceMapper = ({field, dataSourceItem, formId}) => {
  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);

  const {isOpen: isModalOpen, toggleModal: toggleModal,} = useModal()

  const open = (e, t) =>{
  	e.stopPropagation();
  	type = t
  	toggleModal()
  }
  
  if(!(env.admin_emails.includes(currentUser.email) && FormTemplateList.MappingForms.includes(formId))){
  	return null	
  }
  
  return(
  	<Fragment>
	    <span className='fas fa-list m-l-15 m-t-10 cursor' onClick={e=>open(e, 'planner')}/>
	    <span className='far fa-file-alt m-l-15 m-t-10 cursor' onClick={e=>open(e, 'infant_journey.counseling')}/>
  	  {isModalOpen && <DataSourceMapperModal formId={formId} isOpen={isModalOpen} toggleModal={toggleModal} 
  	  	dataSourceItem={dataSourceItem} field={field} type={type}/>}
  	</Fragment>
  )
}

export default DataSourceMapper;