import React, { useEffect, useRef, useState } from 'react'
import PreLoadSpinner from "../PreLoadSpinner"
import DynamicForm from '../FormBuilder/DynamicForm'
import FormTemplateId from '../../constants/FormTemplateList'
import FormContainer from '../FormBuilder/FormContainer'
import OrganizationMemberService from './Members/OrganizationMemberService'
import querystringify from "querystringify"

// let formMode = null; 
// let request = {}; 
// let data = {}; 
// let formFn = {};  

function AppRequestForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const params = props.match.params
  let queryParam = querystringify.parse(props.location.search);

  useEffect(() => { 
    scope.data = {}
    scope.request = {} 
    scope.formFn = {'is_public':true, locale:queryParam.locale} //Access From Template without login
    scope.formMode = 'create-form-submissions'
    setFormRendered(true)
  }, [])

  const create = async (request) => {
    const req = {
      'organization_id': params.organization_id,
    }
    if(!request.data.email && !request.data.mobile){
      alert('Email or Mobile number Missing')
      scope.formFn.setLoading(false)
      return
    }
    if(request.data.email)req.email = request.data.email;
    if(request.data.mobile)req.mobile = request.data.mobile;
    setErrorMsg('Thank you. The download will start soon.')
    setIsSubmitted(true)
    const {status, data} = await OrganizationMemberService.isValidMember(req)
  }

  const routeBlog = () => {
    props.history.push(`/blogs/${params.organization_id}`)
  }

  const AlertMessage = () =>{
    if(isSubmitted){
      return(
        <h4 className="bg-white p-20 text-center">
          {errorMsg}
        </h4>
      )
    }

    return null
  }

  if(isFormRendered){
    return (
      <div id="ct" className="m-t-10">
        <FormContainer post={post} isPopupMode={true} locale={scope.formFn.locale}>
          {isSubmitted?
            <AlertMessage/>
            :
            <DynamicForm formMode={scope.formMode} formId={FormTemplateId.AppRequestForm} 
            form={scope.request} formFn={scope.formFn} onCreate={create} data={scope.data} post={post} 
            onCancel={routeBlog} setPost={setPost} isPopupMode={true}/>
          }
        </FormContainer>
      </div>
    )  
  }else{
    return null
  }
  
}

export default AppRequestForm;