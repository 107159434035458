import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import moment from '../../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../../components/Common/SortDirection'
import CheckAccess from '../../Roles/CheckAccess'
import ChildGrowth from "../../../constants/ChildGrowthDataPoints"
import Cs from "../../../services/CommonService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import HealthCategoryService from "../HealthCategoryService"
import GenericModal from "../../../components/Modals/GenericModal"
import {OptionsObjList} from "../../Common/FormInput"
import {Table, THead, TH, TR, TD, TBody} from "../../Common/TableView"
import {PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon, LinkFontAwesomeIcon, FontAwesomeIcon2} from "../../Common/ImageIcons"
import {DraftBadge} from "../../Common/NotificationPill"
import {NotificationBadge} from "../../Common/BadgePill"
import {Path, RecordId, MCJDetails, MCJDetails_2, MobileRequired, BabyNameRequired, AddressRequired, StatuDropDown, MultiplePregnancy, AdultMetabolicDetails, AdultMetabolicDetails_2} from "./Common"
import {NotificationPopup} from "../../Common/NotificationPopup"

let selectedJourney = {}

const InfantJourneyTable = (props) => {
  useStyle('table');  

  const {permission, journeyDispatch, routeJourney, openNotification, update, undoDelete, dataSources, labelLocale, currentLocale, pregnancyStageKv, toggleJourneyMenuModal, isAssessmentEnabled} = props;
 
  const {sort} = useSort(props.requestParams, props.getJourneyList);
 
  const { isOpen, toggleModal:toggleDeleteModal } = useModal();

  const onDelete = (j) => {
    selectedJourney = j;
    toggleDeleteModal();
  }

  const deleteJourney = (e) => {
    e.preventDefault();
    HealthCategoryService.delete(selectedJourney.id).then((res) => {
      if(res.status == 204) {
        journeyDispatch({
          'type': "DELETE",
          'id':selectedJourney.id
        })
        //props.setJourneyList(props.journeyList.filter(i => i.id !== selectedJourney.id))
      }
    })
    toggleDeleteModal(e);
  }

  const tableData = props.journeyList.map((j, k) => {
    const sid = j.data_source?.health_condition?.sid;

    if(sid === 'cancer_journey'){
      return(
        <CancerRow j={j} routeJourney={routeJourney} 
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          key={k} index={k}
          currentLocale={currentLocale} labelLocale={labelLocale}/>
      )
    }else if(sid === 'dementia_journey'){
      return(
        <DementiaRow j={j} routeJourney={routeJourney} 
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          key={k} index={k} undoDelete={undoDelete}
          currentLocale={currentLocale} labelLocale={labelLocale}/>
      )
    }else if(sid === 'ckd_journey'){
      return(
        <CancerRow j={j} routeJourney={routeJourney}
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          key={k} index={k} undoDelete={undoDelete}
          currentLocale={currentLocale} labelLocale={labelLocale}/>
      )
    }else if(sid === 'infant_journey'){
      return(
        <MCJRow j={j} routeJourney={routeJourney} 
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          key={k} index={k} undoDelete={undoDelete}
          currentLocale={currentLocale} labelLocale={labelLocale}
          pregnancyStageKv={pregnancyStageKv}
          isAssessmentEnabled={isAssessmentEnabled}
          permission={permission}/>
      )
    }else if(j.journey_type === 'adult_metabolic'){
      return(
        <AdultMetabolic j={j} routeJourney={routeJourney} 
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          key={k} index={k} undoDelete={undoDelete}
          currentLocale={currentLocale} labelLocale={labelLocale}/>
      )
    }
  })

  /*
    <SortDirection sortColumn={props.requestParams.sort_column} column="record_id" reverse={props.requestParams.reverse}/>
    onClick={()=>sort('record_id')}
    <SortDirection sortColumn={props.requestParams.sort_column} column="data.record_title" reverse={props.requestParams.reverse}/>
    onClick={()=>sort('data.record_title')}
    <SortDirection sortColumn={props.requestParams.sort_column} column="last_update_at" reverse={props.requestParams.reverse}/>
    onClick={()=>sort('last_update_at')}
    <SortDirection sortColumn={props.requestParams.sort_column} column="status" reverse={props.requestParams.reverse}/>
    onClick={()=>sort('status')}
  */

  return (
    <Fragment>
      <div className="app-content-section app-section-2 table-fix-head m-t-30">
        <Table className="shadow-small" id="patient_lists">
          <THead>
            <TR>
              <TH>
                {labelLocale(9)}
              </TH>
              <TH>
                {labelLocale(8)}
              </TH>
              <TH>
                {labelLocale(14)}
              </TH>
              <TH>
                {labelLocale(10)}
              </TH>
              <TH>
                {labelLocale(11)}
              </TH>
            </TR>
          </THead>
          <TBody>
            {tableData}
          </TBody>
        </Table>
        {props.journeyList.length == 0 && 
          <NotificationPopup title={labelLocale(16)}
          onSuccess={toggleJourneyMenuModal}  
          iconClass="fas fa-circle-info lred"/>
        }    
      </div>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
    </Fragment>
  )
}

const MCJRow = ({permission, labelLocale, j, index, statusStyle, routeJourney, onDelete, undoDelete, openUserRole, openNotification, update, pregnancyStageKv, currentLocale, isAssessmentEnabled}) => {
  return (
    <TR className="">
      <TD className="text-gray wk-center">
        <RecordId j={j}/>
      </TD>
      <TD>
        <MCJDetails j={j} routeJourney={routeJourney} labelLocale={labelLocale}/>
        <Path j={j} pregnancyStageKv={pregnancyStageKv} currentLocale={currentLocale}/>
      </TD>
      <TD>
        <MCJDetails_2 j={j}/>
      </TD> 
      <TD>
        {j.status !== 'closed' && 
          <>
            <div className="flex aligncenter cgap-5 m-t-25 pointer">
              <LinkMapIcon id="path_details" tooltip={labelLocale(19)} onSelect={()=>routeJourney(j)}>
                <NotificationBadge count={j.schedule_today_count}/>
                <NotificationBadge 
                  position="bottom"
                  count={j.note_count + j.audio_file_count}/>
              </LinkMapIcon>
              <LinkUserIcon id="assign_user" tooltip={labelLocale(20)} onSelect={()=>openUserRole(j)} count={j.shared_journey_count}/>
              {j.nutrition_care_alert_count > 0 &&
                <LinkMailIcon id="notification" count={j.nutrition_care_alert_count} 
                  tooltip="Journey Notification" onSelect={()=>openNotification(j)} 
                  className="far fa-envelope font-18 text-muted"
                  tooltip={labelLocale(23)}/>
              }
            </div>
            <div className="flex aligncente pointer cgap-5 m-t-20">
              {j.child ?
                <FontAwesomeIcon2 id="chart" tooltip={labelLocale(24)}
                  parentCss="tooltip table-icon-lgrey flex aligncenter justifycenter"
                  link={{
                    pathname: `/health/chart/${j.id}`, 
                    state: {journey:j, child: j.child},
                  }}
                  iconCss="font-18 white fas fa-chart-line" />
                :
                null  
              }
              {isAssessmentEnabled ? 
                <LinkFontAwesomeIcon id="assessment" 
                  count={j.case_assessment?.count}
                  link={`/case/${j.id}/assessments/${j.created_by}`} 
                  iconCss="font-18 white far fa-list-alt" 
                  tooltip={labelLocale(22)}/>
                :
                null
              }
              {permission?.delete 
                ? <>{j.is_active 
                      ? <LinkDeleteIcon id="delete_journey" tooltip={labelLocale(21)} onSelect={()=>onDelete(j)}/>
                      : <LinkDeleteIcon id="undo_delete" onSelect={()=>undoDelete(j.id)}/>
                    }
                  </>
                : null
              }
            </div>
            {j.case_assessment ? <div className="flex rowrev m-t-15">{Cs.formatUpdateDate(j.case_assessment.last_updated_at)}</div> : null}
          </>
        }
      </TD>
      <TD>
        <StatuDropDown id={j.id} status={j.status} statusReason={j.status_reason} {...{statusStyle, update, index, currentLocale}}/>
      </TD>
    </TR>
  )
}

const AdultMetabolic = ({j, labelLocale, index, statusStyle, routeJourney, onDelete, undoDelete, openUserRole, openNotification, update, currentLocale}) => {
  return (
    <TR className="">
      <TD className="text-gray wk-center">
        <RecordId j={j}/>
      </TD>
      <TD>
        <AdultMetabolicDetails j={j} routeJourney={routeJourney} labelLocale={labelLocale}/>
      </TD>
      <TD>
        <AdultMetabolicDetails_2 j={j}/>
      </TD> 
      <TD>
        {j.status !== 'closed' && 
          <>
            <div className="flex aligncenter cgap-5 m-t-25 pointer">
              <LinkMapIcon id="path_details" tooltip={labelLocale(19)} onSelect={()=>routeJourney(j)}>
                <NotificationBadge count={j.schedule_today_count}/>
                <NotificationBadge 
                  position="bottom"
                  count={j.schedule_missed_count}/>
              </LinkMapIcon>
              <LinkUserIcon id="assign_user" tooltip={labelLocale(20)} onSelect={()=>openUserRole(j)} count={j.shared_journey_count}/>
              {j.nutrition_care_alert_count > 0 &&
                <LinkMailIcon id="notification" count={j.nutrition_care_alert_count} 
                  tooltip="Journey Notification" onSelect={()=>openNotification(j)} 
                  className="far fa-envelope font-18 text-muted"
                  tooltip={labelLocale(23)}/>
              }
            </div>
            <div className="flex aligncente pointer cgap-10 m-t-20">
              {j.is_active 
                ? <LinkDeleteIcon id="delete_journey" tooltip={labelLocale(21)} onSelect={()=>onDelete(j)}/>
                : <LinkDeleteIcon id="undo_delete" onSelect={()=>undoDelete(j.id)}/>
              }
            </div>
          </>
        }
      </TD>
      <TD>
        <StatuDropDown id={j.id} status={j.status} statusReason={j.status_reason} {...{statusStyle, update, index, currentLocale}}/>
      </TD>
    </TR>
  )
}

const CancerRow = ({labelLocale, j, index, statusStyle, routeJourney, onDelete, openUserRole, openNotification, update, currentLocale}) => {
  let ds = j.data_source || {}
  let data = j.data || {}

  return (
    <TR className="">
      <TD className="text-gray">
        {j.record_id}
        <DraftBadge isDraft={j.draft}/>
      </TD>
      <TD>
        <div className="m-b-10 pointer" onClick={e=>routeJourney(j)}>
          {data.patient_name} 
        </div>
        <div className="m-b-10">
          {ds.health_condition?.label}
        </div>
        <div className="flex coldir-xs cgap-10">
          <div className="flex aligncenter">
            <PhoneIcon title={data.cell_number || <MobileRequired labelLocale={labelLocale}/>}/>
          </div>
          <div className="flex aligncenter">
            <MapIcon title={data.taluka || <AddressRequired labelLocale={labelLocale}/>}/>
          </div>
        </div>
      </TD>
      <TD>
        <div className="dblock m-b-10">{Cs.formatUpdateDate(j.updated_at)}</div>
        <div className="dblock">{j.updated_user?.name}</div>
      </TD>
      <TD>
        {j.status != 'closed' && 
          <div className="flex aligncenter pointer">
            <LinkMapIcon id="path_details" tooltip="View path details" onSelect={()=>routeJourney(j)}/>
            <LinkUserIcon tooltip="Assign journey" onSelect={()=>openUserRole(j)}/>
            <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" onSelect={()=>onDelete(j)}/>
          </div>
        }
      </TD>
      <TD>
        <StatuDropDown id={j.id} status={j.status} statusReason={j.status_reason} {...{statusStyle, update, index, currentLocale}}/>
      </TD>
    </TR>
  )
}

const DementiaRow = ({labelLocale, j, index, statusStyle, routeJourney, onDelete, openUserRole, openNotification, update, currentLocale}) => {
  let ds = j.data_source || {}
  let data = j.data || {}

  return (
    <TR className="">
      <TD className="text-gray">
        {j.record_id}
        <DraftBadge isDraft={j.draft}/>
      </TD>
      <TD>
        <div className="m-b-10 pointer" onClick={e=>routeJourney(j)}>
          {data.patient_name} 
        </div>
        <div className="m-b-10">
          {ds.health_condition?.label}
        </div>
        <div className="flex cgap-10 coldir-xs">
          <div className="flex aligncenter m-r-20">
            <PhoneIcon title={data.cell_number || <MobileRequired labelLocale={labelLocale}/>}/>
          </div>
          <div className="flex aligncenter">
            <MapIcon title={data.taluka || <AddressRequired labelLocale={labelLocale}/>}/>
          </div>
        </div>
      </TD>
      <TD>
        <div className="dblock m-b-10">{Cs.formatUpdateDate(j.updated_at)}</div>
        <div className="dblock">{j.updated_user?.name}</div>
      </TD> 
      <TD className="">
        {j.status != 'closed' && 
          <div className="flex aligncenter pointer">
            <LinkMapIcon id="path_details" tooltip="View path details" onSelect={()=>routeJourney(j)}/>
            <LinkUserIcon tooltip="Assign journey" onSelect={()=>openUserRole(j)}/>
            <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" onSelect={()=>onDelete(j)}/>
          </div>
        }
      </TD>
      <TD className="">
        <StatuDropDown id={j.id} status={j.status} statusReason={j.status_reason} {...{statusStyle, update, index, currentLocale}}/>
      </TD>
    </TR>
  )
}

export default InfantJourneyTable;