import React, { Fragment, useState, useContext, useReducer, useEffect, lazy, Suspense} from 'react';
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "./PreLoadSpinner";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute";
import { Route, Switch } from 'react-router-dom';
import SideMenu from './SideMenu/SideMenu';
import ProfileForm from "./HealthJourney/Form/ProfileForm";
import Header from './Header/Header';
import HealthJourneyRouter from './HealthJourney/HealthJourneyRouter';
import useModal from "../hooks/useModal";
import UserProfile from './UserProfile/UserProfile';
import HelpRouter from "./Help/HelpRouter";
import ForumRouter from "./Forums/ForumRouter";
import env from "../env";
//import ChatBot from "../components/Chats/ChatBot"
import Footer from "./Footer/Footer";
import ChatModal from "./Chats/ChatModal";
import {useSocket, PopupNotification} from "./Chats/useSocket";
import {CTTooltip} from "./Common/CueTooltip";
import NutritionCareRouter from "./NutritionCares/Router";
import DigitalContentRouter from './DigitalContent/DigitalContentRouter';
import ScreeningEventRouter from './ScreeningCamp/ScreeningEventRouter';
import LocaleList from "./Locale/LocaleMenu";
import GenericModal from "./Modals/GenericModal";
import PreScheduleEventRouter from './PreScheduleEvent/PreScheduleEventRouter';
import Organization from './Organizations/Route';
import OrganizationForm from './Organizations/OrganizationForm';
import OrganizationsModal from "./Organizations/OrganizationsModal";
import SubscriptionsRouter from './Subscriptions/Route';
import WelcomeTour from './DigitalContent/WelcomeTour';
import TrainingRouter from './TrainingPrograms/Route';
import ContactsRouter from './Contacts/ContactsRouter';
import {AddToHomeScreen} from "./Common/AddToHomeScreen";
import { CacheContext } from "../contexts/Cache/CacheStateProvider";
import { LocalizeContext } from "../contexts/Localize/LocalizeStateProvider";
import CacheReducer from '../contexts/Cache/CacheReducer';
import LocaleReducer from '../contexts/Localize/LocalizeReducer';
import FormList from './Offline/FormList';
import {AboutUs} from './AboutUs/AboutUs';
import {TermsPrivacyConsent} from './AboutUs/TermsPrivacyConsent';
import ErrorBoundary from "./Common/ErrorBoundary";
import NoteForm from "./FormBuilder/FormFieldNotes/NoteForm";
import querystringify from "querystringify";
import {PushNotification} from "./PushNotification/PushNotificationPage"; 
import ResourceManagementRoute from './ResourceManagement/ResourceManagementRoute';
import {useSubscriptionAccess} from './Subscriptions/useSubscription';
import FormTestInputRoute from './FormTestInput/FormTestInputRoute';
import CookieNotice from "./Common/CookieNotice";
import GeographyRoute from "./Geography/GeographyRoute";
import UserRequestList from "./UserRequests/UserRequestList";
import {UpdateBreakPoints} from "../hooks/useScreenViewport";
import UserSurveyTable from './UserSurvey/UserSurveyTable';

const ImageCompress = lazy(() => import('./ImageEditor/ImageCompress'));
const FeedbackForm = lazy(() => import('./Feedback/FeedbackForm'));
const FeedbackList = lazy(() => import('./Feedback/List/FeedbackList'));
const UserHelpRequest = lazy(() => import('./Feedback/List/UserHelpRequest'));
const UserRequestForm = lazy(() => import("./UserRequests/Form"));
const NonRegUserValidationForm = lazy(() => import("./UserRequests/NonRegUserValidationForm"));
const DataSourceList = lazy(() => import('./DataSources/DataSourceList'));
const FormsList = lazy(() => import('./DataSources/FormsList'));
const FormSuggestion = lazy(() => import('./DataSources/FormSuggestion'));
const LeaderShipDashboard = lazy(() => import("./HealthJourney/Dashboard/LeaderShipDashboard"));
const NotificationList = lazy(() => import('./Notifications/NotificationList'));
const PublicNotificationsPage = lazy(() => import('./Notifications/PublicNotificationsPage'));
const ContentManager = lazy(() => import('./Dashboard/ContentManager'));
const LoginHistory = lazy(() => import('./Authentication/LoginHistory'));
const Form = lazy(() => import('./FormBuilder/Form'));
const FormImageSelect = lazy(() => import('./FormBuilder/FormImageSelect'));
const JourneyLogs = lazy(() => import('./ApiLogs/JourneyLogs'));
const DataEntryMemberList = lazy(() => import("./DataEntryUser/DataEntryMemberList"));
const FormSuggestionsList  = lazy(() => import('./DataSources/FormSuggestionsList'));
const ExamineesList = lazy(()=> import('./UserRequests/ExamineesList'));
const UserSurveyForm = lazy(()=> import('./UserRequests/UserSurveyForm'));
const SurveyTableList = lazy(()=> import('./UserRequests/SurveyTableList'));
const UserRegReqLeaderboard = lazy(()=> import('./UserRequests/UserRegReqLeaderboard'));
const FormLabelList = lazy(() => import('./DataSources/FormLabelList'));

const Home = (props) => {
  const queryParam = querystringify.parse(props.location.search);

  const { state:{screen, user:currentUser, isAuthenticated:isAuthenticated}, dispatch } = useContext(AuthContext);
  const [cacheState, cacheDispatch] = useReducer(CacheReducer, {});
  const [localeState, localeDispatch] = useReducer(LocaleReducer, {});

  let [openSideNav, setOpenSideNav] = useState(false);
  let [searchString, setSearchString] = useState(queryParam.search);
  let [openChatBot, setOpenChatBot] = useState(false);

  const {isOpen: showChatModal, toggleModal: toggleChatModal} = useModal();
  const {isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal();
  const {isOpen: showOrgModal, toggleModal: toggleOrgModal} = useModal();

  const {getSubscriptionAccess, loading:subscriptionLoading} = useSubscriptionAccess();
  UpdateBreakPoints();

  useEffect(()=>{
    getSubscriptionAccess()
  }, [currentUser.current_organization_id, currentUser.current_locale])

  if(subscriptionLoading){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <>
      <LocalizeContext.Provider value={{localeState, localeDispatch}}>
        <CacheContext.Provider value={{cacheState, cacheDispatch}}>
          {isAuthenticated && 
            <Header openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} 
            setSearchString={setSearchString} searchString={searchString}
            toggleLocaleModal={toggleLocaleModal} queryParam={queryParam}
            toggleOrgModal={toggleOrgModal} {...props}/>
          }
          
          <ChatModal isOpen={showChatModal} toggleModal={toggleChatModal} currentUser={currentUser}/>
        
          {/*screen.xs && <AddToHomeScreen/>*/}

          {isAuthenticated && 
            <SideMenu openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} 
            toggleOrgModal={toggleOrgModal} toggleChatModal={toggleChatModal}
            toggleLocaleModal={toggleLocaleModal}/>
          }

          {showLocaleModal && 
            <GenericModal component={LocaleList} 
            title="Language" type="user_locale"
            selectedItems={[currentUser.current_locale]}
            isOpen={showLocaleModal} toggleModal={toggleLocaleModal}/>
          }

          {showOrgModal && 
            <GenericModal component={OrganizationsModal} 
            title="Select Organization"
            isOpen={showOrgModal} toggleModal={toggleOrgModal}/>
          }

          <PopupNotification currentUser={currentUser}/>

          <ErrorBoundary>
            <Switch> 
              <Route path="/profile/:id?" component={OrganizationForm} exact />
              <PrivateRoute path="/user/profile" component={UserProfile} exact />
              <PrivateRoute path="/add/formfield/notes/:annotation_form_id/:id?" component={NoteForm} exact/>
              <Suspense fallback={<div className="spinner"/>}>
                <PrivateRoute path="/image/test" component={ImageCompress} exact />
                <PrivateRoute path="/feedback/:id?" component={FeedbackForm} exact/>
                <PrivateRoute path="/list/feedback" component={FeedbackList} exact/>
                <PrivateRoute path="/user/feedbacks" component={UserHelpRequest} exact/>
                <PublicRoute path="/user/request/:organization_id/:id?" component={UserRequestForm} exact/>
                <PublicRoute path="/new/user/onboarding/:form_id/:organization_id/:id?" component={UserRequestForm} exact/>
                <PublicRoute path="/examinee/registration/:organization_id/:id?" component={UserRequestForm} exact/>
                <PublicRoute path={["/user/survey", "/learner/survey"]} component={UserSurveyForm} exact/>
                <PrivateRoute path="/data/entry/members" component={DataEntryMemberList} exact />
                <PrivateRoute path="/notifications" component={NotificationList} exact />
                <PrivateRoute path="/case/logs/:journey_profile_id" component={JourneyLogs} exact/>
                <PrivateRoute path="/list/formfield/images" component={FormSuggestionsList} exact/>
                <PrivateRoute path="/form/images/:form_template_id" component={FormImageSelect} exact />
                <PrivateRoute path="/form/:form_template_id" component={Form} exact/>
                <PrivateRoute path="/leadership" component={LeaderShipDashboard} exact />
                <PrivateRoute path="/form/feedback/:form_id" component={FormSuggestion} exact />
                <PrivateRoute path="/list/data_sources" component={DataSourceList} searchString={searchString} exact />
                <PrivateRoute path="/list/forms" component={FormsList} searchString={searchString} exact />
                <PrivateRoute path="/form/locale/suggestions" component={FormsList} searchString={searchString} exact />
                <PrivateRoute path="/language/labels" component={FormLabelList} searchString={searchString} exact />
                <PrivateRoute path="/login/historys" component={LoginHistory} exact />
                <PrivateRoute path="/user/request_list/:organization_id?" component={UserRequestList} searchString={searchString} exact />
                <PrivateRoute path="/manage/contents" component={ContentManager} exact />
                <PrivateRoute path="/list/user/survey/:organization_id?" component={SurveyTableList} searchString={searchString} exact />
                <PrivateRoute path="/user/registration/leaderboard/:organization_id" component={UserRegReqLeaderboard} searchString={searchString} exact />
                <PrivateRoute path="/list/user/surveys" component={UserSurveyTable} exact/>
                <PublicRoute path="/public/notifications/:journey_profile_id/:locale_sid/:parent_notification_id?" 
                component={PublicNotificationsPage} exact />
                <PublicRoute path="/user/verify_email/:organization_id" component={NonRegUserValidationForm} exact/>
                <PublicRoute path="/examinee/list/:assessment_id" component={ExamineesList} exact/>
              </Suspense>
            </Switch>
            <Switch><HealthJourneyRouter searchString={searchString}/></Switch>
            <Switch><ContactsRouter searchString={searchString}/></Switch>
            <Switch><HelpRouter searchString={searchString} /></Switch>
            <Switch><ForumRouter /></Switch>
            <Switch><PreScheduleEventRouter searchString={searchString}/></Switch>
            <Switch><NutritionCareRouter searchString={searchString}/></Switch>
            <Switch><DigitalContentRouter searchString={searchString} /></Switch>
            <Switch><ScreeningEventRouter searchString={searchString} /></Switch>
            <Switch><Organization searchString={searchString} /></Switch>
            <Switch><SubscriptionsRouter searchString={searchString}/></Switch>
            <Switch><TrainingRouter searchString={searchString}/></Switch>
            <Switch><ResourceManagementRoute searchString={searchString}/></Switch>
            <Switch><FormTestInputRoute searchString={searchString}/></Switch>
            <Switch><GeographyRoute searchString={searchString}/></Switch>
            {isAuthenticated && <Footer openChatBot={openChatBot} setOpenChatBot={setOpenChatBot} />}
            
            <PrivateRoute path="/journey/tour" component={WelcomeTour} exact />
            <PublicRoute path="/about_us/:content_type" component={AboutUs} exact/>
            <PublicRoute path="/organization/consent" component={TermsPrivacyConsent} exact/>
            <PublicRoute path="/push/settings" component={PushNotification} exact/>
            <CTTooltip />
            {localStorage?.is_cookie_accepted === undefined && <CookieNotice />}
          </ErrorBoundary>
        </CacheContext.Provider>
      </LocalizeContext.Provider>
    </>
  )
}

export default Home;