import React, {useContext, useEffect, useState, useMemo} from "react"
import {Link, useHistory} from "react-router-dom"
import {JourneyInlineMenu, PortalToggleMenu} from "../../GenericPortalPage/Common"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../services/CommonService"
import ChildForm from "../../MotherInfantJourney/Child/ChildForm"
import GenericModal from "../../../../components/Modals/GenericModal"
import useModal from "../../../../hooks/useModal"
import {NotesBox} from "../../GenericPortalPage/NotesBox"
import UsersRolesPopup from '../../../DataAccesses/UsersRolesPopup'
import ReactTooltip from "react-tooltip"
import {usePageLocale} from "../../../../hooks/useLocale"
import {DraftBadge} from "../../../Common/NotificationPill"
import {useActivityHook} from "../Activities/List/useActivityHook"
import {MultiplePregnancy, AddMultyPregnancy, Path} from "../../List/Common"
import {LinkFontAwesomeIcon} from "../../../Common/ImageIcons"
import {UserName} from '../../../UserProfile/UserInfoPopup'
import {StatuDropDown} from "../../List/Common"
import { CueTip } from "../../../Common/CueTooltip"

let formProps = null;
const ChildBlock = (props) => {
  let [associatedJourney, setAssociatedJourney] = useState(props.associatedJourney || [])
  let {journey, menuOptions, orgBgImageStyle, dataSource, schedulesMissed, orgMemberInactiveDays, updateStatus} = props
  let {last_measurement:lastMeasurement, created_user:user, member_role:role} = journey
  let [child, setChild] = useState(props.child)
  let {data, data_source} = child
  let history = useHistory()

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)
  const {isOpen: isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal()
  const {isOpen: isNotesOpen, toggleModal:toggleNotesModal } = useModal()
  const {isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()
  const {ageFormat} = useActivityHook(child)
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'childBirthTab')
  const {labelLocale:casePageLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')
  
  const ageAgo = useMemo(() => ageFormat(null, Cs.getCurrentDateTime()), [child.id]);
  const showOrgMemberInactiveDays = currentUser.current_organization.record_id !== 59;

  const whData = (child.child_form_id === 1176) 
    ? {
      "height":child.data?.height,
      "weight":child.data?.weight
    } 
    : {
      "height":child.data_source?.height?.label,
      "weight":child.data_source?.weight?.label
    }

  const lastWhData = (child.child_form_id === 1176) 
    ? {
      "height_baby":lastMeasurement.data?.height_baby,
      "baby_weight":lastMeasurement.data?.baby_weight
    } 
    : {
      "height_baby":lastMeasurement?.data_source?.height_baby?.label,
      "baby_weight":lastMeasurement?.data_source?.baby_weight?.label
    }

  const routeActivityForm = (id, journeyProfile=journey) => {
    formProps = Cs.routeInfantPath(dataSource, {}, journeyProfile, history);
    formProps.match.params.id = id
    formProps.component = ChildForm;
    toggleInfantActivityModal()
  }

  const onFormSubmit = (child_form_data) => {
    if(!child || child?.id === child_form_data.id){
      setChild(child_form_data);
    }else{
      setAssociatedJourney(associatedJourney.filter(j => j.id !== child_form_data.journey_profile_id))
    }
    toggleInfantActivityModal(); 
  }

  const openUserRole = () => {
    toggleUserRole()
  }

  return(
    <>
      <div className="flex coldir fulwid p10">
          <div className="f16">
            <div className="flex justspacebetween">
              <div className="f24 m-r-20 text-blue-2">
                {child.record_id} - {child?.data?.baby_name || 'Baby name pending'}
                <DraftBadge isDraft={child.draft} styleName="m-l-15"/>
              </div>
              <div className="flex-auto">
                <span className="m-r-5">Age{ageAgo}</span>
                <MultiplePregnancy child={child}/>
              </div>
              <div className="flex aligncenter">
                <span className="icon-gray-cirle brd-30 flex aligncenter justifycenter tooltip" 
                  onClick={() => routeActivityForm(child?.id)}>
                    <i className="fas fa-edit" />
                    <CueTip tooltip={casePageLocale(17)} positionCss="bottom"/>
                </span>

                <PortalToggleMenu journey={journey} menuOptions={menuOptions} 
                  journeyType="infant_journey" menuDsId='3530' 
                  permissionKey="infant_portal" child={child}/>
              </div>
            </div>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20">
                {data_source?.gender?.label} 
              </li>
              <li className="m-r-20">
                | &nbsp; {data_source?.delivery_type?.label}
              </li>
              <li className="m-r-20">
                | &nbsp; {data_source?.gestational_week?.label ? (data_source?.gestational_week?.label + 'W') : ''}  
              </li>
              <li className="m-r-20">
                | <AddMultyPregnancy journeyChild={child} associatedJourney={associatedJourney} onSelect={(j)=>routeActivityForm(j.child_id, j)}/>
              </li>
              <li className="m-r-20">
                <StatuDropDown id={journey.id} 
                  status={journey.status} 
                  statusReason={journey.status_reason}
                  currentLocale={currentUser.current_locale}
                  update={updateStatus}
                />
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20">
                <span className="fa fa-location-dot text-blue-2 m-r-5"/>
                {data_source?.delivery_location?.label || 'Location not available'}
              </li>
              <li className="m-r-20">
                <span className="far fa-calendar-alt text-blue-2 m-r-5"/>
                {child.data.dob_baby}
              </li>
                <>
                  {/* {schedulesMissed?.count > 0 ?
                    <li className="m-r-20 d-grid-xss">
                      <LinkFontAwesomeIcon 
                        countCss="badge-2 up bg-lred l-10" 
                        iconCss="far fa-calendar-times white" 
                        count={schedulesMissed?.count}
                        tooltip={labelLocale(4)}/>
                    </li>
                    :
                    null
                  } */}
                  {/*showOrgMemberInactiveDays && orgMemberInactiveDays?.count > 0 ?
                    <li className="m-r-20 d-grid-xss">
                      <LinkFontAwesomeIcon 
                        countCss="badge-2 up bg-lred l-10" 
                        css="fas fa-user-clock white m-l-5" 
                        count={orgMemberInactiveDays?.count}
                        tooltip={labelLocale(3)}/>
                    </li>
                    :
                    null*/
                  }
                </>
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20 d-grid-xss"label="Birth Details">
                <div className="ff-1 f13 mgrey">{labelLocale(1)}</div>
              </li>
              <li className="m-r-20 d-grid-xss" label="Height">
                <div className="ff-1 f13 mgrey">{labelLocale(5)}</div>
                {whData.height} cms
              </li>
              <li className="m-r-20 d-grid-xss" label="Weight">
                <div className="ff-1 f13 mgrey">{labelLocale(6)}</div>
                {whData.weight} kgs
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20 d-grid-xss" label="Last Update">
                <div className="ff-1 f13 mgrey">{labelLocale(2)}&nbsp;</div>
              </li>
              {lastMeasurement ?
                <>
                  <li className="m-r-20 d-grid-xss" label="Height">
                  <div className="ff-1 f13 mgrey">{labelLocale(5)}</div>
                     {lastWhData.height_baby} cms
                  </li>
                  <li className="m-r-20 d-grid-xss" label="Weight">
                  <div className="ff-1 f13 mgrey">{labelLocale(6)}</div>
                    {lastWhData.baby_weight} kgs
                  </li>
                </>
                :
                null
              }
            </ul>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20 d-grid-xss" label="Username">
                <div className="ff-1 f13 mgrey">{labelLocale(7)}</div>
                <UserName id={child?.created_by} role={child?.member_role}>{child?.created_user?.name}</UserName>
              </li>
              {child?.member_role?.role ?
                <li className="m-r-20 d-grid-xss" label="Role">
                   <div className="ff-1 f13 mgrey">{labelLocale(8)}</div>
                  {child?.member_role?.role}
                </li>
                :
                null
              }
              {lastMeasurement ? 
                <li className="m-r-20 d-grid-xss" label="Updated On">
                  <div className="ff-1 f13 mgrey">{labelLocale(7)}</div>
                  {Cs.formatUpdateDate(lastMeasurement?.updated_at)}
                </li>
                :
                null
              }
            </ul>
        </div>
      </div>

      {isInfantActivityFormOpen && 
        <GenericModal toggleModal={toggleInfantActivityModal} 
          isPopupMode="true" onFormSubmit={onFormSubmit} 
          closeConfirmPopup={true} 
          {...formProps}/>
      }

      {isNotesOpen && 
        <GenericModal title="Notes" component={NotesBox} 
          toggleModal={toggleNotesModal} journeyProfileId={journey.id}
          noteableId={journey.id}
          currentUser={currentUser} />
      }

      {isUserRoleOpen && 
        <GenericModal component={UsersRolesPopup} 
          isOpen={isUserRoleOpen} toggleModal={toggleUserRole}
          itemType="journey_profile" itemId={journey.id} 
          showRoles="true" showOrgMembers="true" 
          title="Assign this case to Roles and/or Users"/>
      }
    </>
  )
}  

export default ChildBlock;