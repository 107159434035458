import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import {JourneyInlineMenu, PortalToggleMenu} from "../../HealthJourney/GenericPortalPage/Common";
import Cs from "../../../services/CommonService";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {DraftBadge} from "../../Common/NotificationPill";
import {UserName} from '../../UserProfile/UserInfoPopup';
import {useSubscriptionAccess} from '../../Subscriptions/useSubscription';
import {FormFieldCard, RenderData} from "../../Common/FormFieldsView";
import OrganizationMemberService from "./OrganizationMemberService";
import FormTemplateService from "../../../services/FormTemplateService";

const OrgMemberBlock = (props) => {
  const { state: { user: currentUser }} = useContext(AuthContext)

  const [loading, setLoading] = useState(true);
  const [formFields, setFormFields] = useState([]);
  const [orgMember, setOrgMember] = useState({});

  let {journey, menuOptions, dataSource} = props;
  let {data, data_source} = journey;
  
  useEffect(()=>{
    getOrgMember();
  }, [])

  let getOrgMember = async () => { 
    const {status, data} = await OrganizationMemberService.get(currentUser.current_member?.id);
    setOrgMember(data.organization_member);
    getFormFields(data.organization_member.form_id);
  }

  let getFormFields = (formId) => { 
    FormTemplateService.getFormFields({
      'ids':[formId], 
    }).then((res)=>{
      if(res.status === 200){
        setFormFields(res.data.form_fields[formId]);
        setLoading(false);
      }
    })
  }

  return (
    <div className="p10">
      <div className="mxh-250 m-t-5 scroll-auto">
        <div className="table card-table p-5">
          <FormFieldCard formFields={formFields} 
            data={orgMember.data} dataSource={orgMember.data_source}/>
        </div>  
      </div> 
    </div>
  ) 
}

export default OrgMemberBlock;