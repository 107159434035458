import React, {useState, useEffect} from 'react'
import Cs from "../../services/CommonService"
import FormSubmissionReviewService from './FormSubmissionReviewService'

const useReviewHook = (itemId, itemType, isReviewEnabled=false, reviewStatus) => {
  const [isReviewLoading, setReviewLoading] = useState(isReviewEnabled)
  const [reviewByClientId, setReviewByClientId] = useState({})

  useEffect(() => {
    if(isReviewEnabled)
      getReviews()
  }, [])

  const getReviews = async () =>{
    if(itemId && itemType){
      const {status, data} = await FormSubmissionReviewService.getAll({
        'item_type':itemType,
        'item_id':itemId,
        'status':reviewStatus
      })
      if(status === 200){
        setReviewByClientId(Cs.listToObj(data.reviews, 'field_client_id'))    
        setReviewLoading(false)
      }
    }else{
      setReviewLoading(false)
    }
  }

  return {
    isReviewLoading,
    reviewByClientId, 
  }
}

export {useReviewHook};