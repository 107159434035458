import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import ChecklistService from '../../services/ChecklistService';
import useStyle from '../../hooks/useStyle';
import useEscape from '../../hooks/useEscape';

// let checklist = {};

const ChecklistsModal = ({ item, type, isOpen, toggleModal }) => {
  useEscape(toggleModal);
  let { current: scope } = useRef({ checklist:{} });
  const { state:currentUser, dispatch } = useContext(AuthContext);

  const [checkListItem, setCheckListItem] = useState([]);
  const [checklistId, setChecklistId] = useState();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(true);

  const getCheckList = () => {
    let req = {
      'item_id' : item.id,
      'item_type': type,
    }
    ChecklistService.getAll(req).then((res)=>{
      if(res.status == 200){
        setLoading(false) 
        setCheckListItem(res.data.checklists)
      }
    })
  }

  useEffect(() => {
    if(isOpen)getCheckList();
  }, [item.id, scope.checklist]);

  if(!isOpen)return null;

  const addCheckList = (e) => {
    setLoading(true)
    e.preventDefault()
    
    scope.checklist.title = text
    scope.checklist.journey_profile_id = item.journey_profile_id

    if(!scope.checklist.title)return;

    if(scope.checklist.id){
      ChecklistService.update(scope.checklist).then((res)=>{
        if(res.status == 204){
          setLoading(false)
          setCheckListItem([...checkListItem])
          scope.checklist = {};
          setText('');
        }
      })
    }else{
      scope.checklist.journey_profile_id = item.journey_profile_id
      scope.checklist.item_id = item.id
      scope.checklist.item_type = type;

      ChecklistService.create(scope.checklist).then((res)=>{
        console.log('checklist',res)
        if(res.status == 201){
          setLoading(false)
          scope.checklist.id = res.data.id;  
          setCheckListItem([...checkListItem, scope.checklist])
          scope.checklist = {};
          setText('');
        }
      })
    }
  }

  const deleteCheckList = (e, checklist) => {
    e.preventDefault();  
    setLoading(true)
    ChecklistService.delete(checklist.id).then((res)=>{
      setLoading(false)
      setCheckListItem(checkListItem.filter(i => i.id !== checklist.id))
    })  
  }

  const toggleStatus = (e, checklist) =>{
    e.preventDefault();  
    checklist.status = !checklist.status;
    checklist.checklist_id = checklist.id
    ChecklistService.status(checklist).then((res)=>{
      setCheckListItem(checkListItem.map(c => (c.id === checklist.id ? {...c, status:checklist.status} : c)))
    }) 
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom brd-10">
      
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p12-10-15">Checklist</h4>
          <button className="btn-close" title="Close Modal" onClick={e=>toggleModal(e)}>×</button>
        </div>

        <form className="ml-container" onSubmit={e=>addCheckList(e)}>
          <div className="ml-section">
            <input onChange={e => setText(e.target.value)} className="ml-input ml-border"
            type="text" placeholder="Add Checklist" name="checklist" required value={text}/>

            <div className="ml-button ml-block ml-section ml-padding bg-highlight-2 white" onClick={e=> addCheckList(e)}>
              <b>{scope.checklist.id?'Update':'Create'}</b>
            </div>
          </div>
        </form>
      
        <div className="ml-container">
          <div className="note-ul note-card-4" style={{ marginBottom: '20px'}}>
            { loading ? 
              <div className="spinner"/> 
              : 
              checkListItem && checkListItem.map((c, key) =>{
                return(
                  <div className={"flex justspacebetween " + (c.status ? "note-bar bg-ltint" :"note-bar")} key={key}>
                    <span className="checkbox-container" onClick={e=>toggleStatus(e, c)}>
                      <span>{c.title}</span>
                      <input type="checkbox" checked={c.status} readOnly/>
                      <span className="checkbox-checkmark"></span>
                    </span>
                    <div className="flex m-t-5">
                      <div className="nbt-white font-16 m-r-5" onClick={() => {scope.checklist = c;setChecklistId(c.id);setText(c.data);}}>
                        <i className="far fa-edit" aria-hidden="true"></i>
                      </div>
                      <div className="nbt-white font-16 m-l-15 m-r-5" onClick={e => {deleteCheckList(e, c)}}>
                        <i className="far fa-trash-alt" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>
    </div>
  </Fragment>, document.body))
};

export default ChecklistsModal;