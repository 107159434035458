import React, { useEffect, useState, useContext, useRef } from "react"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DynamicForm from "../../../../components/FormBuilder/DynamicForm"
import FormTemplateId from "../../../../constants/FormTemplateList"
import WellnessService from "../WellnessService"
import FormContainer from "../../../../components/FormBuilder/FormContainer"

// let formMode = null
// let symptom = {}
// let data = {}
// let formFn = {}

function WellnessForm(props) {
  let { current: scope } = useRef({ formFn: {}});
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  let params = props.match.params

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    WellnessService.get(params.id).then((res) => {
      if (res.status == 200) {
        scope.symptom = res.data.symptom
        scope.data = scope.symptom.scope.data || {}
        params.form_template_id = scope.symptom.activity_form_id

        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function (res) {
      scope.symptom = null
    })
  }

  useEffect(() => {
    scope.symptom = {}
    scope.data = {}
    scope.formMode = "create-form-submissions"
    scope.formFn = {
      form_type: "health.symptom",
      journey_category_id: params.journey_category_id,
    }
    
    if (params.id) {
      get()
      scope.formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (symptom) => {
    symptom.organization_id = currentUser.current_organization_id
    symptom.created_by = currentUser.id
    symptom.updated_by = currentUser.id
    symptom.journey_profile_id = params.journey_profile_id
    symptom.symptom_form_id = params.form_id
    symptom.symptom_type = params.type
    symptom.symptom_id = params.symptom_id

    WellnessService.create(symptom).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect()
      }
    })
  }

  const update = (symptom) => {
    symptom.updated_by = currentUser.id
    WellnessService.update(symptom).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = () => {
    if(props.isPopupMode){
      props.onFormSubmit()
    }else{
      props.history.push(`/health/wellness/${params.journey_profile_id}`) 
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={scope.formMode}
          formId={params.form_id}
          form={scope.symptom}
          data={scope.data}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default WellnessForm;