import React, {useState, Fragment, useContext, useEffect, useMemo, useRef} from "react";
import querystringify from "querystringify";
import Pagination from '../Common/Pagination';
import {Link} from "react-router-dom";
import Cs from "../../services/CommonService";
import CheckAccess from '../Roles/CheckAccess'; 
import { CTTooltip } from "../Common/CueTooltip";
import UserRequestService from './UserRequestService';
import useUserRegRequestHook from './useUserReqRequestHook';

const UserSurveyScoring = ({userRequest}) => {

  const [surveyScore, setSurveyScore] = useState();

  const {
    scope,
    currentUser,
    loading, setLoading
  } = useUserRegRequestHook()

  useEffect(()=>{
    getSurveyReport();
  }, [])

  const getSurveyReport = async () =>{
    const {status, data} = await UserRequestService.surveyReport({
      "report_type":"score",
      "user_request_id":userRequest.id
    });
    if(status === 200){
      scope.learningAbilitiesLevels = data.data_source_list?.levels_learning_abilities;
      setSurveyScore(data.score);
      setLoading(false);
    }
  }

  return (
    <div id="ct">
      <div className="app-content">
        <div className="flex justspacebetween">
          <div className="app-title m-r-20 m-t-10">Survey Score</div>
        </div>

        <div className="app-content-section m-t-10">
          {loading ?
            <div className="spinner"/>
            :
            <>
              <div className="f16">User Score - {surveyScore?.score}</div>
              <table className="table">
                <thead>
                  <tr>
                    {scope.learningAbilitiesLevels?.options?.map((l, i)=>
                      <th key={i} style={{'background': l.bg_color}}>
                        {l.label} 
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {scope.learningAbilitiesLevels?.options?.map((l, i)=>
                      <td key={i} style={{'background': l.bg_color}}>
                        {l.value}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </>
          }
        </div>
      </div>
    </div>
  )

}

export default UserSurveyScoring;