import React, {Fragment, useState, useRef, useEffect} from 'react';
import {CueTip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon';
import {FieldLabel, OptionLabel, Description, RequiredMsg, WarningMsg, FieldReview, FieldPoint} from '../FieldLabel';
import useModal from "../../../hooks/useModal";
import GenericModal from "../../Modals/GenericModal";
import Cs from '../../../services/CommonService';
import DataSourceMapper from "../DataSourceMapper";
import {ImageFlexList} from '../../Common/Image';

const InputSelect = ({labelLocale, renderField, formId, screen, field, formData, readOnly, errors, forceUpdate, formFn, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale, position, fieldReview}) => {
  let {current:scope} = useRef({
    opkv:field.options_key_value || {},
    options:[],
    objList:{}
  });

  const [selectedLabel, setSelectedLabel] = useState('');
  const [showError, setShowError] = useState(false);
  /*const [options, setOptions] = useState([]);
  const [objList, setObjList] = useState({});*/
  const [loading, setLoading] = useState(true);
  const [showOptions, setShowOptions] = useState(false);

  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  const fieldEleRef = useRef();

  useEffect(()=>{
    formFn.ds[field.client_id] = formFn.ds[field.client_id] || {};
    if(renderField){
      setResult();
    }
  }, [currentLocale, renderField])

  if(!renderField){
    return null;
  }
  
  const setOptions = (list) =>{
    if(list){
      scope.options = list;
    }else{
      if(field.auto_gen_option){
        scope.opkv.options = FormHelper.autoGenOptions(field.auto_gen_option, formData);
      }
      scope.options = (scope.opkv.options || field.options);
    }
  }

  const setResult = () =>{
    if(scope.opkv && scope.opkv.options && !field.auto_gen_option){
      const selectedOption = scope.opkv.options.find(o => o.value == formData[field.client_id]);
      const label = selectedOption ? OptionLabel({"option":selectedOption, "currentLocale":currentLocale}) : null;
      setSelectedLabel(label != null ? label : '');
      //scope.objList = scope.opkv.options.reduce((obj, item) => (obj[item.value] = OptionLabel({option:item, currentLocale:currentLocale}), obj), {});
    }else{
      setSelectedLabel(formData[field.client_id] || '');
    }
    setLoading(false);
  }

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': labelLocale(5),
    'disabled':readOnly,
    'required':field.required,
    'className':'dd-button'
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let validation = {
    'required': field.required
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  const handleChange = (item) =>{
    const isObj = (typeof item === 'object');
  	/*const { target } = event;*/
    /*const name = field.client_id*/
    const value = isObj ? item.value : item;
    formData[field.client_id] = (value==null?null:FormHelper.isNumeric(value));
    /*if(value==null){
      formData[name]=null
    }else{
      formData[name] = FormHelper.isNumeric(value)
    }*/
    formFn['on_select_'+field.client_id] && formFn['on_select_'+field.client_id](item, errors[field.client_id]);
    formFn.ds[field.client_id] = item;
    const label = isObj ? OptionLabel({"option":item, "currentLocale":currentLocale}) : item;
    setSelectedLabel(label);
    setError(false);
    FormHelper.setFormTitle(field, formData, value, scope.opkv);
    FormHelper.checkCustomValidation(field, formData, formFn.ds, errors[field.client_id]);
    onOptionSelect(formData);
    forceUpdate();
    hideList();
  }

  const clearSelection = (rerender=false) =>{
    if(formData[field.client_id] != null){
      formData[field.client_id] = null;
      FormHelper.setFormTitle(field, formData, null, scope.opkv);
    }
    setError(rerender);
  }

  formFn["update_"+field.client_id] = (value) => {
    const selectedOption = scope.opkv.options.find(o => o.label == value);
    if(selectedOption) handleChange(selectedOption);
  };

  /*TODO check usage*/  
  const setInputValue = (optionList=[], inputValue, setValue) =>{
    if(formData[field.client_id] == null){
      const option = optionList[0];
      const label = scope.objList ? option?.label : option;
      if(label?.toLowerCase() == inputValue?.toLowerCase()){
        setValue(scope.objList ? option?.label : option);
        handleChange(option);
      }else{
        setValue(null);
        forceUpdate();
      }
    }
    setError();
  }

  const updateByModalName = (targetKey, value) =>{
    formFn["update_"+targetKey](value);
  }

  const onOptionSelect = (d) =>{
    try{
      if(field.function_expression){
        eval(field.function_expression);
      }
    }catch(e){
      console.error(e.message)
    }
  }

  const setError = (rerender) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = (formData[name]==null || errors[name].err_msg)?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name];
      }
      if(rerender){
        setShowError(!showError);
        formFn.refreshFormSubmit();  
      }
    }
  }

  const exists = (item) => {
    return formData[field.client_id] == item;
  }

  const bindWindowClickEvent = () =>{
    if(formFn['on_'+field.client_id+'_open']){
      formFn['on_'+field.client_id+'_open'](
        (field.options_key_value?.options || field.options), 
        setOptions
      )
    }else{
      setOptions();
    }
    
    if(screen.xs == true){
      toggleMenuModal();
    }else{
      //fstListRef.current.classList.remove("hidden");
      setShowOptions(true);
      formFn['is_'+field.client_id+'_open'] = true;
      window.addEventListener('click', eventFunction);  
    }
  }

  const eventFunction = (event) => {
    try{
      if(fieldEleRef.current.contains(event.target)){
        //console.log(field)
      }else{
        hideList();
        forceUpdate();
      }
    }catch(e){
    
    }
  }

  const hideList = () =>{
    if(screen.xs == true){
      toggleMenuModal();
    }else{
      //fstListRef.current.classList.add("hidden");
      setShowOptions(false);
      formFn['is_'+field.client_id+'_open'] = false;
      window.removeEventListener("click", eventFunction);
    }
  }

  //FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <span className="fas fa-exclamation-triangle m-r-5"/>
          {errors[field.client_id].err_msg || <RequiredMsg {...{field, currentLocale}}/>}
        </div>
      )
    }else if(errors[field.client_id]?.warning_msg){
      return(
        <div className="errormsg">
          <WarningMsg {...{field, currentLocale}} lkey='gt_lt_msg'/>
        </div>
      )
    }

    return null
  }

  if(loading)
    return null;
  
  return (
  	<Fragment>
      <div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
      	  <FieldLabel field={field} 
            isFormWizard={isFormWizard}
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openDataSourceModal={openDataSourceModal}
            openFieldLabelModal={openFieldLabelModal}/>
     	    <div ref={fieldEleRef} id={field.client_id+ '_container'} className={readOnly ? 'input-readonly':''} >
            {(scope.opkv && scope.opkv.options) 
              ? <OptionsObjList selectedLabel={selectedLabel} list={scope.options} 
                exists={exists} handleChange={handleChange} currentLocale={currentLocale}
                model={formData[field.client_id]} field={field} 
                bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes} 
                formId={formId} dataTypes={scope.opkv.data_types}
                setError={setError} clearSelection={clearSelection}
                setInputValue={setInputValue} showOptions={showOptions}/> 
              : <OptionsStringList list={scope.options} exists={exists} 
                handleChange={handleChange} model={formData[field.client_id]} field={field}
                bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes} 
                setError={setError} clearSelection={clearSelection}
                setInputValue={setInputValue} showOptions={showOptions}/>
            }   
          </div>
          <FieldPoint userRefId={formFn.userRefId} clientId={field.client_id} 
            point={field.point} currentLocale={currentLocale}  
            negPoint={field.incorrect_point}/>
          <Description description={field.description} />
          <FieldReview review={fieldReview}/>
          <Error/>
   	    </div>
      </div> 

      {isMenuOpen && (
        <GenericModal
          component={SingleSelectPopup}
          inputAttributes={inputAttributes} 
          handleChange={handleChange} 
          setError={setError}
          exists={exists}
          optionObjList={scope.options}
          optionStringList={scope.options}
          dataTypes={scope.opkv.data_types}
          title={field.label}
          isOpen={isMenuOpen}
          toggleModal={toggleMenuModal}
          currentLocale={currentLocale}
          clearSelection={clearSelection}
          setInputValue={setInputValue}/>
      )}

    </Fragment>
  )
}

const OptionsStringList = ({showOptions, list, model, exists, field, handleChange, clearSelection, bindWindowClickEvent, inputAttributes, setError, setInputValue}) => {
  
  let [optionList, setOptionList] = useState();
  const inputEleRef = useRef();

  useEffect(()=>{
    if(showOptions){ 
      setOptionList(list.length > 100 ? list.slice(0, 100) : list );
    }
  }, [list])

  useEffect(()=>{
    /*Popup mode selection change*/
    setSearch(model!=null?model:null);
  }, [model])

  const search = (term) =>{
    clearSelection();
    if(term.length > 0){
      setOptionList(FormHelper.searchList(list, term));  
    }else{
      setOptionList([...list]);
    }
  }

  const setSearch = (term) =>{
    inputEleRef.current.value = term;
  }

  const selectableItems = optionList && optionList.map((o, k) =>{
    return(  
      <li key={k} className={exists(o)?'dd-selected':''} onClick={e => handleChange(o)} title={o}>
        {o}
      </li>
    )
  })
  
  return(
    <Fragment>
      <div className="dropdown" onClick={e=>bindWindowClickEvent(e)}>
        <input {...inputAttributes} defaultValue={model} 
        ref={inputEleRef}
        onChange={e=>search(e.target.value)} 
        onFocus={()=> setOptionList(list)}/>
        <span className="dd-button-caret"/>
        <i className="fas fa-times pos-abs lh-42 r-50" onClick={()=>clearSelection(true)}/>
      </div>
      {showOptions
        ? <ul className="dd-menu scroll-sm">
            {selectableItems}
          </ul>
        : null
      }
    </Fragment>
  )
}

const OptionsObjList = ({showOptions, dataTypes, formId, list, selectedLabel, model, exists, field, handleChange, clearSelection, bindWindowClickEvent, inputAttributes, currentLocale, setError, setInputValue}) => {

  let [optionList, setOptionList] = useState(list);
  const inputEleRef = useRef();
  let itemHeight = dataTypes && dataTypes.image_url?{height: '100px'}:{};

  useEffect(()=>{
    if(showOptions){ 
      setOptionList(list.length > 100 ? list.slice(0, 100) : list );
    }
  }, [list])
  
  useEffect(()=>{
    /*Popup mode selection change*/
    setSearch(model!=null ? selectedLabel : null);
  }, [model])

  //https://javascript.info/regexp-anchors
  const search = (term) =>{
    clearSelection()
    if(term.length > 0){
      setOptionList(FormHelper.searchObjList(list, term));  
    }else{
      setOptionList(list.length > 100 ? list.slice(0, 100) : list );
    }
  }

  const setSearch = (term) =>{
    inputEleRef.current.value = term;
  }

  const selectableItems = optionList && optionList.map((o, k) => { 
    return(
      <Fragment key={k}>  
        <li style={itemHeight} className={exists(o.value)?'dd-selected':''}
         onClick={() => handleChange(o)} title={o.description}>
          <div>
            <OptionLabel option={o} currentLocale={currentLocale}/>
            {itemHeight && o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100" style={{height:"60px"}}/>}
            {o.images ? <ImageFlexList styleName="radio-img" list={o.images}/> : null}
          </div>
        </li>
      </Fragment>
    )
  })

  return(
    <>
      <div className="dropdown" onClick={e=>bindWindowClickEvent(e)} >
        <input {...inputAttributes} defaultValue={selectedLabel} ref={inputEleRef}
        onChange={e=>search(e.target.value)} onFocus={()=> setOptionList(list)}/>
          <span className="dd-button-caret"/>
          <i className="fas fa-times pos-abs lh-42 r-50" onClick={()=>clearSelection(true)}/>
      </div>
      {showOptions
        ? <ul className="dd-menu scroll-sm">
            {selectableItems}
          </ul>
        : null
      }
    </>
  )
}

const SingleSelectPopup = ({inputAttributes, dataTypes, handleChange, clearSelection, exists, optionObjList, optionStringList, currentLocale, setError, setInputValue}) =>{

  let [optionList, setOptionList] = useState(optionObjList ? optionObjList : optionStringList);
  const inputEleRef = useRef();
  let itemHeight = dataTypes && dataTypes.image_url?{height: '100px'}:{};

  const search = (term) =>{
    clearSelection();
    if(term.length > 0){
      setOptionList(OptionsObjList ? FormHelper.searchObjList(optionObjList, term):FormHelper.searchList(optionStringList, term));  
    }else{
      setOptionList(OptionsObjList ? optionObjList: optionStringList);
    }
  }

  const setSearch = (term) =>{
    inputEleRef.current.value = term;
  }

  const optionStringItems = optionList ? optionList.map((o, k) =>  
    <li key={k} className={exists(o)?'dd-selected':''} onClick={() => handleChange(o)} title={o}>
      {o}
    </li>
  ):null

  const optionObjectItems = optionList.map((o, k) =>  
    <li key={k} style={itemHeight} className={exists(o.value)?'dd-selected':''}
      onClick={e => handleChange(o)} title={o.description}>
        <div className="font-16">
          <OptionLabel option={o} currentLocale={currentLocale}/>
          {itemHeight && o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100 pull-right" style={{height:"60px"}}/>}
          {o.images ? <ImageFlexList styleName="radio-img" list={o.images}/> : null}
        </div>
    </li>
  )

  return(
    <div id="ct">
      <div className="dropdown">
        <input {...inputAttributes}  ref={inputEleRef} onChange={e => search(e.target.value)}/>
        <i className="fas fa-times pos-abs lh-42 r-50" onClick={()=>clearSelection(true)}/>
      </div>
      <ul className="dd-menu margin-unset">
        {OptionsObjList? optionObjectItems : optionStringItems}
      </ul>
    </div>
  )

}
export default InputSelect;