import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import moment from '../../../../../../node_modules/moment/min/moment.min.js';
import PreLoadSpinner from "../../../../PreLoadSpinner";
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import ActivityService from "../ActivityService";
import querystringify from "querystringify";
import FormContainer from '../../../../../components/FormBuilder/FormContainer';
import GeoLocation from "../../../../../components/Common/GeoLocation";
import Cs from "../../../../../services/CommonService";
import CheckAccess from '../../../../Roles/CheckAccess';

function FieldGroupActivityForm(props) {
  let {current:scope} = useRef({
    formMode: null,
    activity: {},
    dataList: {},
    data: {},
    formFn: {}
  });
  const {state: { user: currentUser }} = useContext(AuthContext);

  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  
  const params = props.match.params;
  const queryParam = querystringify.parse(props.location.search);
  const childInfo =  props.location.state;

  const permission = useMemo(() => 
    CheckAccess.getMemberPermissionBySid(currentUser, 'infant_process', queryParam.label), [currentUser.id]
  )

  const getAll = () => {
    const req = {
      'journey_profile_id': params.journey_profile_id,
      'activity_form_id': queryParam.activity_form_id,
    }
    ActivityService.getAll(req).then(({status, data}) => {
      if (status === 200) {
        scope.dataList = data.activities.map(a => {
          return {id:a.id, ...(a.data || {})}
        }); //id added with data for db update
        scope.formFn.ds = {};
        scope.formMode = "edit-form-submissions";
        setFormRendered(true);
      }
    }).catch(function (res) {
      setFormRendered(true)
    })
  }

  useEffect(() => {
    getAll();
    scope.formFn = {
      'editPermission': true,
      'form_type': 'infant.activity',
      'journey_profile_id': params.journey_profile_id
    }
    scope.activity = {};
    scope.dataList = [];
    setChildInfo();
  }, [])

  const setChildInfo = () =>{
    if(childInfo){
      const temp = {'child_fk_id': childInfo.child_fk_id}
      scope.formFn.foreignData = temp;
      Object.assign(scope.data, temp); 
    }
  }

  const create = (activity) => {
    activity.organization_id = currentUser.current_organization_id;
    activity.journey_profile_id = params.journey_profile_id;
    activity.activity_form_id = parseInt(queryParam.activity_form_id);
    activity.activity_type = queryParam.label || "Activity";
    activity.schedule_id = params.schedule_id;
    activity.member_role_id = currentUser.current_member?.role_record_id;
    activity.data_collected_by = queryParam.data_collected_by;
    activity.organization_member_id = currentUser.current_member?.id;
    ActivityService.create(activity).then(({status, data}) => {
      if (status === 201) {
        onSubmitPageRedirect(data.activity);
      }
    }).catch((res) => {
      onSubmitPageRedirect();
    })
  }

  const onSubmitPageRedirect = (activity) => {
    if(props.isPopupMode){
      props.onFormSubmit(activity);
    }else{
      props.history.goBack();
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={scope.formMode}
          formId={queryParam.activity_form_id}
          form={scope.activity}
          data={scope.data}
          dataList={scope.dataList}
          formFn={scope.formFn}
          onCreate={create}
          onUpdate={create}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}
          isReviewEnabled={queryParam.show_review}
          permission={permission}/>
        <GeoLocation geoData={scope.activity}/>
      </FormContainer>
    )
  }else {
    return null;
  }
}

export default FieldGroupActivityForm;