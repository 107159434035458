import React, {useState} from "react"
import {NotificationPopup} from "../../../../Common/NotificationPopup"
import ActivityModalLinks from "./ActivityModalLinks"
import useModal from "../../../../../hooks/useModal"
import {getLocale} from "../../../../FormBuilder/FieldLabel"

const MCJScheduleAlert = ({journeyProfile, child, todaySchedules=[], setTodaySchedules, processNodes, reloadActivity, labelLocale, currentLocale}) =>{
  const [activeSchedule, setActiveSchedule] = useState()
  const { isOpen: isScheduleFormOpen, toggleModal:toggleScheduleForm } = useModal()
    
  const openForm = (schedule) =>{
    setActiveSchedule(schedule)
    toggleScheduleForm()
  } 

  const onScheduledFormSubmit = () =>{
    setTodaySchedules(todaySchedules.filter(s => s.id !== activeSchedule.id))
    reloadActivity(activeSchedule.scheduled_form_type)
    toggleScheduleForm()
  }

  if(isScheduleFormOpen){
    return(
      <ActivityModalLinks 
        child={child} journeyProfile={journeyProfile} 
        scheduleId={activeSchedule.id}
        schedule={activeSchedule} autoOpenForm={true} 
        onScheduledFormSubmit={onScheduledFormSubmit}
        onScheduledFormClose={()=>toggleScheduleForm()}/>
    )
  }else if(todaySchedules.length > 0){
    return(
      <div className="bg-white brd-10 flex coldir m-b-20 p10 m-t-20">
        <h2 className="m-r-30 f20 f18-xss" data-label="Task reminder panel">
          {labelLocale(9)}
        </h2>
        <ul className="m-t-15 f16 pointer">
          {todaySchedules.map((s, key) =>
            <li className="m-r-20 m-b-5" key={key} onClick={()=>openForm(s)}>
              <span className="far fa-bell lred m-r-15"/>
              {getLocale(processNodes?.find((p)=> p.sid === s.scheduled_form_type), currentLocale, 'detail')}
            </li>
          )}
        </ul>
      </div>
    )
  }else{
    return null
  }
}

/*
1) NOV 15 2023 removed openForm
 <li className="m-r-20 m-b-5" key={key} onClick={()=>openForm(s)}>

<NotificationPopup 
  title={message} 
  message={`This mother child case needs your attention today. <br/>Fill and submit the necessary measurements now.`}
  onSuccess={onSuccess}  
  iconClass="fas fa-circle-info lred"/>
*/
export default MCJScheduleAlert;