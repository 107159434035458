import React, { useState, Fragment, useEffect, useContext } from "react"
import PreScheduleService from "./../PreScheduleService"
import Cs from '../../../services/CommonService'
import {PillList} from '../../Common/NotificationPill'
import {PlannerVideoPlayer} from '../../Common/VideoPlayer'
import useModal from "../../../hooks/useModal"
import ActivityModalLinks from "../../HealthJourney/MotherInfantJourney/Activities/List/ActivityModalLinks"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import { ParaRichTextView } from "../../Common/RichTextView"

const PreScheduleAlert = ({preScheduleId, journeyProfileId, journeyProfile, child, schedule}) => {
  const {state:{user:currentUser}} = useContext(AuthContext)

  const [preSchedule, setPreSchedule] = useState({})
  const [planners, setPlanners] = useState([])
  const [selectedPlanner, setSelectedPlanner] = useState()
  const [journeyProfileData, setJourneyProfileData] = useState(journeyProfile)
  const [childData, setChildData] = useState(child)

  const { isOpen: isVideoPlayerOpen, toggleModal: toggleVideoPlayer } = useModal()

  useEffect(() => {
    getPreSchedule()
  }, [])

  /*useEffect(() => {
    if(!schedule.scheduled_form_type)
      schedule.scheduled_form_type = preSchedule.data_source?.mcj_forms?.map((f)=>f.data.sid)
      //['growth_measurement', 'assess_bf']
  }, [preSchedule])*/

  const getPreSchedule = () =>{
    PreScheduleService.get(preScheduleId, {
      'journey_profile_id':journeyProfileId, 
      'locale':currentUser.current_locale
    }).then(({status, data})=>{
      setPreSchedule(data.pre_schedule || {})
      setPlanners(data.planners)
      if(data.journey_profile)
        setJourneyProfileData(data.journey_profile)
      if(data.child)
        setChildData(data.child)
    })      
  } 

  const onPlannerSelect = (item) =>{
    if(item.planner_fk_id){
      setSelectedPlanner(item)
      toggleVideoPlayer()
    }
  }

  if(isVideoPlayerOpen){ 
    return <PlannerVideoPlayer 
      toggleModal={toggleVideoPlayer} 
      plannerId={selectedPlanner.planner_fk_id} />
  }
  
  return(
    <div id="ct">
      <div className="bg-white brd-10 card-shadow m-b-15">
       <div className="card-content p20">
        <div className="b-tab-table active scroll-auto ht-400"> 
          <div className="border-dashed-sm p-b-15 m-b-15">
            <h5 className="fw500 m-b-5">Forms</h5>
            {journeyProfileData &&
              <ActivityModalLinks child={childData} 
                journeyProfile={journeyProfileData} 
                scheduleId={schedule.schedule_id}
                schedule={schedule}/>
            }
          </div>
          <div className="border-dashed-sm p-b-15 m-b-15">
            <h5 className="fw500 m-b-5">Videos</h5>
            <div className="flex coldir">
              <PillList list={preSchedule.data?.videos_suggested_array} 
                onSelect={onPlannerSelect} dataKey='name'
                style="badge white bg-highlight m-r-5 m-b-5 pointer"/>
            </div>
          </div>
          <div className="border-dashed-sm p-b-15 m-b-15">
            <h5 className="fw500 m-b-5">Description</h5>
            <ParaRichTextView html={preSchedule.data?.notification_text}></ParaRichTextView>
          </div>
        </div>
        <div className="flex rowrev">
          <span className="lgrey">CM {preSchedule.record_id}</span>
        </div>
       </div>
      </div>
    </div>
  )
}

export default PreScheduleAlert;