import React, {Fragment, useState, useEffect} from "react"
import ReadStatusService from "./ReadStatusService"

const useReadStatus = () => {

  const updateReadStatus = async (idx, req, callback) =>{
  	const {status} = await ReadStatusService.create({...req, 'read_status':true})
  	callback && callback(idx)
  }

  return{
	  updateReadStatus  	
  }

}

export {useReadStatus};