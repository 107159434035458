import React, {useContext} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import env from "../../env"

const EditDataSourceIcon = ({field, open, iconClass}) => {
  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);

  let ele = <span className={(iconClass || 'fas fa-list')+' m-l-15 cursor f18'} onClick={e=>open(field)}/>

  if(iconClass == 'fas fa-edit' && env.admin_emails.includes(currentUser.email)){
  	return ele		
  }else if(field.data_source_editable){
  	return ele
  }else{
  	return null
  }
  
}

export default EditDataSourceIcon;
