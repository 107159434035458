import React, { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { JourneyInlineMenu, PortalToggleMenu } from "../GenericPortalPage/Common"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../services/CommonService"
import Form from "./Form"
import GenericModal from "../../Modals/GenericModal"
import useModal from "../../../hooks/useModal"
import {NotesBox} from "../GenericPortalPage/NotesBox"
import UsersRolesPopup from "../../DataAccesses/UsersRolesPopup"
import FacilityService from "./FacilityService"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import {FormFieldTable, RenderData} from "../../Common/FormFieldsView"
import ReactTooltip from "react-tooltip"
import { CTTooltip } from "../../Common/CueTooltip"
import useSort from '../../../hooks/useSorting'
import DynamicTable from "../../Common/DynamicTable"

let formProps = null
let requestParams = {}
let formFields = []
const MedicalFacilityBlock = (props) => {

  let history = useHistory()

  let { journey, dataSource:component, componentName, menuOptions, orgBgImageStyle, journeyType } = props
  const {child_form:formId} = component
  
  const { state: { user: currentUser }, dispatch} = useContext(AuthContext)
  
  const [medicalFacilityList, setMedicalFacilityList] = useState({})
  const [loading, setLoading] = useState(true)

  const { isOpen: isMedicalFacilityFormOpen, toggleModal: toggleMedicalFacilityFormModal} = useModal()
  const { isOpen: isNotesOpen, toggleModal: toggleNotesModal } = useModal()
  const { isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()

  const routeMedicalFacilityForm = (id) => {
    formProps = Cs.routeCancerPath(component, journey, history, id)
    formProps.component = Form
    toggleMedicalFacilityFormModal()
  }

  const onFormSubmit = () => {
    getMedicalFacilities()
    toggleMedicalFacilityFormModal()
  }

  const openUserRole = () => {
    toggleUserRole()
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      page: 1,
      per_page: 50,
      sort_column: "updated_at",
      sort_direction: "desc",
      form_id: formId,
      journey_profile_id: journey.id,
      form_fields_params: {
        'ids':[formId]
      }     
    }
    getMedicalFacilities()
  }, [formId])

  let getMedicalFacilities = () => {
    setLoading(true)
    FacilityService.getAll(requestParams).then(({status, data}) => {
      if (status == 200) {
        formFields = data.form_fields[formId] || []
        setMedicalFacilityList(data.facilities)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getMedicalFacilities)

  const OptionsEle = ({item}) => (
    <td className="text-center">
      <span id="record_edit" onClick={e=>routeMedicalFacilityForm(item.id)} className="fas fa-edit m-r-15 font-18 text-muted"/>  
    </td>
  )

  return (
    <>
      {loading ? 
        <div className="spinner"/>
        :
        <div className="row">
          <span id="add_measure_growth" data-tip="Record details in a new entry" 
            className="bg-highlight round-btn white font-20 m-b-5 pull-right m-r-15" 
            onClick={()=>routeMedicalFacilityForm()}>
              <i className="fas fa-plus" />
          </span>

          <div className="table-responsive">
            <DynamicTable requestParams={requestParams} onSort={sort} 
              formFields={formFields} formDataList={medicalFacilityList} 
              tClass="table shadow-small"
              OptionsEle={OptionsEle}/>
          </div>
        </div>
      }
        
      <CTTooltip />

      <PortalToggleMenu
        journey={journey} 
        menuOptions={menuOptions} 
        journeyType={props.journeyType}
        permissionKey="infant_portal"
      />

      {isMedicalFacilityFormOpen && (
        <GenericModal
          toggleModal={toggleMedicalFacilityFormModal}
          isPopupMode="true"
          onFormSubmit={onFormSubmit}
          closeConfirmPopup={true} {...formProps}
        />
      )}

      {isNotesOpen && (
        <GenericModal
          title="Notes"
          component={NotesBox}
          toggleModal={toggleNotesModal}
          journeyProfileId={journey.id}
          noteableId={journey.id}
          currentUser={currentUser}
        />
      )}

      {isUserRoleOpen && (
        <GenericModal
          component={UsersRolesPopup}
          isOpen={isUserRoleOpen}
          toggleModal={toggleUserRole}
          itemType="journey_profile"
          itemId={journey.id}
          showRoles="true"
          showOrgMembers="true"
          title="Assign this case to Roles and/or Users"
        />
      )}
    </>
  );
};

export default MedicalFacilityBlock;